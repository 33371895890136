import React, { useState } from "react";
import {
    Col,
    Form,
    Row,
    Card,
    Tooltip,
    OverlayTrigger,
    Button,
    Modal
} from "react-bootstrap";
import {
    faExclamationCircle
} from "@fortawesome/free-solid-svg-icons";
import {
    HelpTooltip
} from "../../assets/functions.js";
import { Formik } from "formik"
import * as Yup from "yup";
import DropzoneComponent from "../Dropzone.js";
import { domainConfig } from "../../config.js";
import PopoverPicker from "../PopoverPicker.js";

const defaultSegment = {
    "prize": "",
    "possibility": 15,
    "quantity": 15,
    "new": true,
}

const PrizeDraw = ({
    handleChange,
    values,
    setFieldValue,
    errors,
    touched,
    handlePreviewSuccess,
    handlePreviewError,
}) => {
    const [showModal, setShowModal] = useState(false)
    const [modalData, setModalData] = useState(false)
    const [prizes, setPrizes] = useState(values.json.prizes)
    const [props, setProps] = useState({
        backgroundColor: values.json.apresentation?.textSide?.backgroundColor,
        textColor: values.json.apresentation?.textSide?.textColor
    });

    function openSegment(value) {
        setShowModal(true);
        setModalData(value)
    }

    function removeSegment(values) {
        let updatedItems = [...prizes];
        updatedItems = updatedItems.filter(item => item.id !== values.id);
        setPrizes(updatedItems)
        setFieldValue("json.prizes", updatedItems);
        setShowModal(false);
    }

    function registerData(values, actions) {
        let updatedItems = [...prizes];
        if (values.new) {
            values.id = 'id-' + Date.now().toString(36) + Math.random().toString(36).substr(2)
            const { new: newData, ...dataWithoutNew } = values;
            updatedItems.push(dataWithoutNew)
        } else {
            const index = updatedItems.findIndex(value => value.id === values.id);
            updatedItems[index] = values
        }
        setPrizes(updatedItems)
        setFieldValue("json.prizes", updatedItems);
        setShowModal(false);
    }

    const [draggedIndex, setDraggedIndex] = useState(null);

    const handleDragStart = (index) => {
        setDraggedIndex(index);
    };

    const handleDragOver = (index) => {
        if (draggedIndex === index) return;
        const updatedItems = [...prizes];
        const [draggedItem] = updatedItems.splice(draggedIndex, 1);
        updatedItems.splice(index, 0, draggedItem);
        setDraggedIndex(index);
        setPrizes(updatedItems);
        setFieldValue("json.prizes", updatedItems);
    };

    const handleDragEnd = () => {
        setDraggedIndex(null);
    };

    return (
        <>
            <Form.Group as={Col} className="textHeaderAplicativo mb-3">
                <h5 className="card-title mb-0">Definições</h5>
            </Form.Group>
            <Row className={`mx-0 mb-3`}>
                <Form.Group
                    className="mb-3"
                    as={Col}
                    sm="12"
                    controlId="validationFormik01"
                >
                    <Form.Label>Título</Form.Label>
                    <Form.Control
                        type="text"
                        name="titulo"
                        value={values.titulo}
                        onChange={handleChange}
                        isInvalid={!!errors.titulo}
                        isValid={touched.titulo && !errors.titulo}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors.titulo}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                    className="mb-3"
                    as={Col}
                    sm="12"
                    controlId="validationFormik01"
                >
                    <Form.Check
                        className="w-auto"
                        checked={values.json.prizeDrawType === 2 ? true : false}
                        type="checkbox"
                        name="json.prizeDrawType"
                        label={<>
                            Participação precisa ser ativada via QRCode
                            <OverlayTrigger overlay={<Tooltip>O suário precisa ativar sua conta pelo leitor de QRCode do modo apresentador para aparecer na lista de participantes</Tooltip>}>
                                <HelpTooltip icon={faExclamationCircle} />
                            </OverlayTrigger>
                        </>}
                        onChange={(e) => {
                            setFieldValue(
                                "json.prizeDrawType",
                                values.json.prizeDrawType === 1 ? 2 : 1
                            );
                        }}
                    />
                </Form.Group>
                <Form.Group
                    className="mb-3"
                    as={Col}
                    md="6"
                    controlId="validationFormik03"
                >
                    {console.log(values.json.drawMode)}
                    <Form.Label>Tipo de sorteio</Form.Label>
                    <Form.Select
                        onChange={(e) => {
                            console.log(e.target.value)
                            setFieldValue(
                                "json.drawMode", e.target.value == 1 ? 1 : 2
                            );
                        }}
                        value={values.json.drawMode}>
                        <option value={1}>
                            Sorteios gerados pelo administrador
                        </option>
                        <option value={2}>
                            Revelação de prêmio após cadastro
                        </option>
                    </Form.Select>
                </Form.Group>
                {values.json.drawMode === 2 && <Form.Group
                    as={Col}
                    md={3}
                    controlId="validationFormik01">
                    <Form.Label>Total de tentativas</Form.Label>
                    <Form.Control
                        type="number"
                        name="json.maxDraws"
                        step={"1"}
                        value={values.json.maxDraws}
                        onChange={handleChange}
                        isInvalid={!!errors.json?.maxDraws}
                        isValid={touched.json?.maxDraws && !errors.json?.maxDraws}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors.json?.maxDraws}
                    </Form.Control.Feedback>
                </Form.Group>}
            </Row>
            {values.json.drawMode === 2 && <Row className="mb-3 mx-0">
                <Form.Label>Composição do sorteio</Form.Label>
                <Row className="mx-0 mb-3">
                    <Card className={`formCard ${!!errors?.json?.prizes ? "is-invalid" : ""} col-md-12 px-0 mb-0`}>
                        <Card.Body>
                            <Col className="text-center mb-2">
                                {prizes.length > 0 && <small className="text-center opacity-75">(Clique e arraste para ordenar)</small>}
                            </Col>
                            {prizes.length > 0 ? (
                                <Row>
                                    {prizes.map((valueX, index) => (
                                        <Col
                                            key={'prizes' + index}
                                            className="mb-3"
                                            md={3}
                                            sm={12}>
                                            <Button
                                                style={{ height: "50px" }}
                                                key={valueX.id}
                                                variant="secondary"
                                                className={`w-100 b-15`}
                                                draggable
                                                onDragStart={() => handleDragStart(index)}
                                                onDragOver={(e) => {
                                                    e.preventDefault();
                                                    handleDragOver(index);
                                                }}
                                                onClick={() => {
                                                    setModalData(valueX);
                                                    setShowModal(true);
                                                }}
                                                onDragEnd={handleDragEnd}
                                            >
                                                {valueX.prize}
                                            </Button>
                                        </Col>
                                    ))
                                    }
                                </Row>
                            ) : (
                                <>
                                    <p className="text-center opacity-75">Nenhum prêmio encontrado</p>
                                </>

                            )}
                        </Card.Body>
                        <Col className="mb-3 text-center">
                            <Button
                                variant="primary"
                                className="btn-sm"
                                onClick={() => openSegment(defaultSegment)}
                            >
                                Adicionar prêmio
                            </Button>
                        </Col>
                    </Card>
                    {errors.json?.prizes && <p className='invalid-feedback d-block'>asdasdas</p>}
                </Row>
            </Row>}

            <Form.Group as={Col} className="textHeaderAplicativo mb-3">
                <h5 className="card-title mb-0">
                    Participante
                </h5>
            </Form.Group>
            <Row className="mx-0 mb-3">
                <p className="text-start fw-bold mb-2">Tela de resultado</p>
                <Form.Group
                    className="mb-3"
                    as={Col}
                    sm="12"
                    controlId="validationFormik01"
                >
                    <Form.Label>Título</Form.Label>
                    <Form.Control
                        as="textarea"
                        name="json.title.value"
                        value={values.json.title.value}
                        onChange={(e) => {
                            if (e.target.value === '') {
                                setFieldValue("json.title.status", false);
                            } else {
                                setFieldValue("json.title.status", true);
                            }
                            setFieldValue("json.title.value", e.target.value);
                        }}
                        isInvalid={!!errors?.json?.title?.value}
                        isValid={touched?.json?.title?.value && !errors.json?.title?.value}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors?.json?.title?.value}
                    </Form.Control.Feedback>
                </Form.Group>
                {console.log(errors)}
                {console.log(values)}
                <Form.Group
                    className="mb-3"
                    as={Col}
                    sm="12"
                    controlId="validationFormik01"
                >
                    <Form.Label>Descrição</Form.Label>
                    <Form.Control
                        as="textarea"
                        name="json.description.value"
                        value={values.json.description.value}
                        onChange={(e) => {
                            if (e.target.value === '') {
                                setFieldValue("json.description.status", false);
                            } else {
                                setFieldValue("json.description.status", true);
                            }
                            setFieldValue("json.description.value", e.target.value);
                        }}
                        isInvalid={!!errors?.json?.description?.value}
                        isValid={touched?.json?.description?.value && !errors.json?.description?.value}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors?.json?.description?.value}
                    </Form.Control.Feedback>
                </Form.Group>
            </Row>
            <Row className="mx-0">
                <p className="text-start fw-bold mb-2">Mensagem (Sorteado)</p>
                <Form.Group
                    className="mb-3"
                    as={Col}
                    sm="12"
                    controlId="validationFormik01"
                >
                    <Form.Label>Título</Form.Label>
                    <Form.Control
                        as="textarea"
                        name="json.showResult.winnerText"
                        value={values.json.showResult.winnerText}
                        onChange={(e) => {
                            if (e.target.value === '') {
                                setFieldValue("json.showResult.winnerText", false);
                            } else {
                                setFieldValue("json.showResult.winnerText", true);
                            }
                            setFieldValue("json.showResult.winnerText", e.target.value);
                        }}
                        isInvalid={!!errors?.json?.showResult?.winnerText}
                        isValid={touched?.json?.showResult?.winnerText && !errors?.json?.showResult?.winnerText}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors.json?.showResult?.winnerText}
                    </Form.Control.Feedback>
                </Form.Group>
            </Row>
            <Row className="mx-0">
                <Form.Group
                    as={Col}
                    md="6">
                    <Card className="mb-3">
                        <Card.Body>
                            <div className="text-center mb-3">
                                <div className="d-flex justify-content-center mb-3">
                                    <Form.Label>Imagem <OverlayTrigger overlay={<Tooltip>É possível utilizar gifs para adicionar uma animação personalizada</Tooltip>}>
                                        <HelpTooltip icon={faExclamationCircle} />
                                    </OverlayTrigger></Form.Label>
                                </div>
                            </div>
                            <Form.Control
                                type="hidden"
                                name="json.showResult.winnerImage"
                                value={values.json.showResult.winnerImage}
                                onChange={handleChange}
                                isInvalid={!!errors?.json?.showResult?.winnerImage}
                                isValid={touched?.json?.showResult?.winnerImage && !errors?.json?.showResult?.winnerImage}
                            />
                            <div className="imagesUpload">
                                <img
                                    className={`h-100 img-thumbnail ${!!errors?.json?.showResult?.winnerImage ? "is-invalid" : ""}`}
                                    src={
                                        values.json.showResult.winnerImage !== "" && values.json.showResult.winnerImage !== null
                                            ? domainConfig.imageServer +
                                            "/customizacao/" +
                                            values.customizacao_id +
                                            "/" +
                                            values.json.showResult.winnerImage
                                            : domainConfig.imageServer + "/empty-background.png"
                                    }
                                    alt="Imagem footer aplicativo"
                                />
                                <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors?.json?.showResult?.winnerImage}
                                </Form.Control.Feedback>
                            </div>
                            <DropzoneComponent
                                className="mt-3 dropzoneComp"
                                type="image"
                                handlePreviewSuccess={handlePreviewSuccess}
                                handlePreviewError={handlePreviewError}
                                setFieldValue={setFieldValue}
                                folder="customizacao"
                                id={values.customizacao_id}
                                field="json.showResult.winnerImage"
                            />
                        </Card.Body>
                    </Card>
                </Form.Group>
            </Row>

            <Row className="mx-0">
                <p className="text-start fw-bold mb-2 mt-3">Mensagem (Não sorteado)</p>
                <Form.Group
                    className="mb-3"
                    as={Col}
                    sm="12"
                    controlId="validationFormik01"
                >
                    <Form.Label>Título</Form.Label>
                    <Form.Control
                        as="textarea"
                        name="json.showResult.loserText"
                        value={values.json.showResult.loserText}
                        onChange={(e) => {
                            if (e.target.value === '') {
                                setFieldValue("json.showResult.loserText", false);
                            } else {
                                setFieldValue("json.showResult.loserText", true);
                            }
                            setFieldValue("json.showResult.loserText", e.target.value);
                        }}
                        isInvalid={!!errors?.json?.showResult?.loserText}
                        isValid={touched?.json?.showResult?.loserText && !errors?.json?.showResult?.loserText}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors.json?.showResult?.loserText}
                    </Form.Control.Feedback>
                </Form.Group>
            </Row>
            <Row className="mx-0 mb-3">
                <Form.Group
                    as={Col}
                    md="6">
                    <Card className="mb-3">
                        <Card.Body>
                            <div className="text-center mb-3">
                                <div className="d-flex justify-content-center mb-3">
                                    <Form.Label>Imagem<OverlayTrigger overlay={<Tooltip>É possível utilizar gifs para adicionar uma animação personalizada</Tooltip>}>
                                        <HelpTooltip icon={faExclamationCircle} />
                                    </OverlayTrigger></Form.Label>
                                </div>
                            </div>
                            <Form.Control
                                type="hidden"
                                name="json.showResult.loserImage"
                                value={values.json.showResult.loserImage}
                                onChange={handleChange}
                                isInvalid={!!errors?.json?.showResult?.loserImage}
                                isValid={touched?.json?.showResult?.loserImage && !errors?.json?.showResult?.loserImage}
                            />
                            <div className="imagesUpload">
                                <img
                                    className={`h-100 img-thumbnail ${!!errors?.json?.showResult?.loserImage ? "is-invalid" : ""}`}
                                    src={
                                        values.json.showResult.loserImage !== "" && values.json.showResult.loserImage !== null
                                            ? domainConfig.imageServer +
                                            "/customizacao/" +
                                            values.customizacao_id +
                                            "/" +
                                            values.json.showResult.loserImage
                                            : domainConfig.imageServer + "/empty-background.png"
                                    }
                                    alt="Imagem footer aplicativo"
                                />
                                <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors?.json?.showResult?.loserImage}
                                </Form.Control.Feedback>
                            </div>
                            <DropzoneComponent
                                className="mt-3 dropzoneComp"
                                type="image"
                                handlePreviewSuccess={handlePreviewSuccess}
                                handlePreviewError={handlePreviewError}
                                setFieldValue={setFieldValue}
                                folder="customizacao"
                                id={values.customizacao_id}
                                field="json.showResult.loserImage"
                            />
                        </Card.Body>
                    </Card>
                </Form.Group>
            </Row>

            {values.json.drawMode === 1 ?
                (
                    <>

                        <Row className="mx-0">
                            <p className="text-start fw-bold mb-2 mt-3">Mensagem (Esperando sorteio)</p>
                            <Form.Group
                                className="mb-3"
                                as={Col}
                                sm="12"
                                controlId="validationFormik01"
                            >
                                <Form.Label>Título</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="json.showResult.waitingText"
                                    value={values.json.showResult.waitingText}
                                    onChange={(e) => {
                                        if (e.target.value === '') {
                                            setFieldValue("json.showResult.waitingText", false);
                                        } else {
                                            setFieldValue("json.showResult.waitingText", true);
                                        }
                                        setFieldValue("json.showResult.waitingText", e.target.value);
                                    }}
                                    isInvalid={!!errors?.json?.showResult?.waitingText}
                                    isValid={touched?.json?.showResult?.waitingText && !errors?.json?.showResult?.waitingText}
                                />
                                <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.json?.showResult?.waitingText}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row className="mx-0">
                            <Form.Group
                                as={Col}
                                md="6"
                                className="mb-3">
                                <Card className="mb-3">
                                    <Card.Body>
                                        <div className="text-center mb-3">
                                            <div className="d-flex justify-content-center mb-3">
                                                <Form.Label>Imagem<OverlayTrigger overlay={<Tooltip>É possível utilizar gifs para adicionar uma animação personalizada</Tooltip>}>
                                                    <HelpTooltip icon={faExclamationCircle} />
                                                </OverlayTrigger></Form.Label>
                                            </div>
                                        </div>
                                        <Form.Control
                                            type="hidden"
                                            name="json.showResult.waitingImage"
                                            value={values.json.showResult.waitingImage}
                                            onChange={handleChange}
                                            isInvalid={!!errors?.json?.showResult?.waitingImage}
                                            isValid={touched?.json?.showResult?.waitingImage && !errors?.json?.showResult?.waitingImage}
                                        />
                                        <div className="imagesUpload">
                                            <img
                                                className={`h-100 img-thumbnail ${!!errors?.json?.showResult?.waitingImage ? "is-invalid" : ""}`}
                                                src={
                                                    values.json.showResult.waitingImage !== "" && values.json.showResult.waitingImage !== null
                                                        ? domainConfig.imageServer +
                                                        "/customizacao/" +
                                                        values.customizacao_id +
                                                        "/" +
                                                        values.json.showResult.waitingImage
                                                        : domainConfig.imageServer + "/empty-background.png"
                                                }
                                                alt="Imagem footer aplicativo"
                                            />
                                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                {errors?.json?.showResult?.waitingImage}
                                            </Form.Control.Feedback>
                                        </div>
                                        <DropzoneComponent
                                            className="mt-3 dropzoneComp"
                                            type="image"
                                            handlePreviewSuccess={handlePreviewSuccess}
                                            handlePreviewError={handlePreviewError}
                                            setFieldValue={setFieldValue}
                                            folder="customizacao"
                                            id={values.customizacao_id}
                                            field="json.showResult.waitingImage"
                                        />
                                    </Card.Body>
                                </Card>
                            </Form.Group>
                        </Row>
                    </>
                ) : (
                    <>
                        <Row className="mx-0">
                            <p className="text-start fw-bold mb-2">Animação para o prêmio</p>
                            <Form.Group as={Col} sm={6} className='mb-3'>
                                <Form.Label>Duração da animação (milesimos)</Form.Label>
                                <Form.Control
                                    type="number"
                                    step={1000}
                                    name="json.showResult.drawImageTimeout"
                                    value={values.json.showResult.drawImageTimeout}
                                    onChange={(e) => {
                                        handleChange(e);
                                    }}
                                    isInvalid={!!errors.json?.showResult?.drawImageTimeout}
                                />
                                <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.json?.showResult?.drawImageTimeout}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row className="mx-0">
                            <Form.Group
                                as={Col}
                                md="6">
                                <Card className="mb-3">
                                    <Card.Body>
                                        <div className="text-center mb-3">
                                            <div className="d-flex justify-content-center mb-3">
                                                <Form.Label>Imagem <OverlayTrigger overlay={<Tooltip>É possível utilizar gifs para adicionar uma animação personalizada</Tooltip>}>
                                                    <HelpTooltip icon={faExclamationCircle} />
                                                </OverlayTrigger></Form.Label>
                                            </div>
                                        </div>
                                        <Form.Control
                                            type="hidden"
                                            name="json.showResult.drawImage"
                                            value={values.json.showResult.drawImage}
                                            onChange={handleChange}
                                            isInvalid={!!errors?.json?.showResult?.drawImage}
                                            isValid={touched?.json?.showResult?.drawImage && !errors?.json?.showResult?.drawImage}
                                        />
                                        <div className="imagesUpload">
                                            <img
                                                className={`h-100 img-thumbnail ${!!errors?.json?.showResult?.drawImage ? "is-invalid" : ""}`}
                                                src={
                                                    values.json.showResult.drawImage !== "" && values.json.showResult.drawImage !== null
                                                        ? domainConfig.imageServer +
                                                        "/customizacao/" +
                                                        values.customizacao_id +
                                                        "/" +
                                                        values.json.showResult.drawImage
                                                        : domainConfig.imageServer + "/empty-background.png"
                                                }
                                                alt="Imagem footer aplicativo"
                                            />
                                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                {errors?.json?.showResult?.drawImage}
                                            </Form.Control.Feedback>
                                        </div>
                                        <DropzoneComponent
                                            className="mt-3 dropzoneComp"
                                            type="image"
                                            handlePreviewSuccess={handlePreviewSuccess}
                                            handlePreviewError={handlePreviewError}
                                            setFieldValue={setFieldValue}
                                            folder="customizacao"
                                            id={values.customizacao_id}
                                            field="json.showResult.drawImage"
                                        />
                                    </Card.Body>
                                </Card>
                            </Form.Group>
                        </Row>
                    </>
                )}
            <Row className="mx-0">
                <Form.Group
                    as={Col}
                    className="mb-3"
                    md="3"
                    controlId="validationFormik01">
                    <Form.Label>Largura (px) <OverlayTrigger overlay={<Tooltip>Essa medida vale para todas as imagens da tela de resultado do participante</Tooltip>}>
                        <HelpTooltip icon={faExclamationCircle} />
                    </OverlayTrigger></Form.Label>
                    <Form.Control
                        type="number"
                        name="json.showResult.imageWidth"
                        value={values.json.showResult.imageWidth}
                        onChange={(e) => {
                            setFieldValue("json.showResult.imageWidth", e.target.value);
                        }}
                        isInvalid={!!errors.json?.showResult?.imageWidth}
                        isValid={touched.json?.showResult?.imageWidth && !errors.json?.showResult?.imageWidth}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors.json?.showResult?.imageWidth}
                    </Form.Control.Feedback>
                </Form.Group>
            </Row>

            <Form.Group as={Col} className="textHeaderAplicativo mb-3">
                <h5 className="card-title mb-0">Modo apresentação</h5>
            </Form.Group>
            <Row className="mx-0">
                <Form.Group
                    className="mb-3"
                    as={Col}
                    sm="12"
                    controlId="validationFormik01"
                >
                    <Form.Label>Título</Form.Label>
                    <Form.Control
                        as="textarea"
                        name="json.apresentation.title.value"
                        value={values.json.apresentation?.title?.value}
                        onChange={(e) => {
                            if (e.target.value === '') {
                                setFieldValue("json.apresentation.title.status", false);
                            } else {
                                setFieldValue("json.apresentation.title.status", true);
                            }
                            setFieldValue("json.apresentation.title.value", e.target.value);
                        }}
                        isInvalid={!!errors?.json?.apresentation?.title?.value}
                        isValid={touched?.json?.apresentation?.title?.value && !errors?.json?.apresentation?.title?.value}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors.json?.apresentation?.title?.value}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                    as={Col}
                    md={3}
                    className="mb-3"
                    controlId="validationFormik01">
                    <Form.Label>Tamanho fonte (M) (rem)</Form.Label>
                    <Form.Control
                        type="number"
                        name="json.apresentation.textSide.h5"
                        step={"0.01"}
                        value={values.json.apresentation?.textSide?.h5}
                        onChange={handleChange}
                        isInvalid={!!errors.json?.apresentation?.textSide?.h5}
                        isValid={touched.json?.apresentation?.textSide?.h5 && !errors.json?.apresentation?.textSide?.h5}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors.json?.apresentation?.textSide?.h5}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                    as={Col}
                    md={3}
                    className="mb-3"
                    controlId="validationFormik01">
                    <Form.Label>Tamanho fonte (P) (rem)</Form.Label>
                    <Form.Control
                        type="number"
                        name="json.apresentation.textSide.p"
                        step={"0.01"}
                        value={values.json.apresentation?.textSide?.p}
                        onChange={handleChange}
                        isInvalid={!!errors.json?.apresentation?.textSide?.p}
                        isValid={touched.json?.apresentation?.textSide?.p && !errors.json?.apresentation?.textSide?.p}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors.json?.apresentation?.textSide?.p}
                    </Form.Control.Feedback>
                </Form.Group>
                <div className="col-md-3 mb-3">
                    <Form.Label>Cor de fundo e texto</Form.Label>
                    <div className={`d-flex justify-content-start align-items-center ${errors?.json?.apresentation?.textSide?.textColor || errors?.json?.apresentation?.textSide?.backgroundColor ? 'is-invalid' : ''}`}>
                        <Form.Control
                            type="hidden"
                            name="json.apresentation.textSide.backgroundColor"
                            value={values.json?.apresentation?.textSide?.backgroundColor}
                            isInvalid={!!errors?.json?.apresentation?.textSide?.backgroundColor}
                            isValid={touched?.json?.apresentation?.textSide?.backgroundColor && !errors?.json?.apresentation?.textSide?.backgroundColor}
                        />
                        <Form.Control
                            type="hidden"
                            name="json.apresentation.textSide.textColor"
                            value={values.json?.apresentation?.textSide?.textColor}
                            isInvalid={!!errors?.json?.apresentation?.textSide?.textColor}
                            isValid={touched?.json?.apresentation?.textSide?.textColor && !errors?.json?.apresentation?.textSide?.textColor}
                        />
                        <PopoverPicker
                            color={props.backgroundColor}
                            onChangeX={(e) => {
                                setFieldValue("json.apresentation.textSide.backgroundColor", e);
                                setProps(prevState => ({
                                    ...prevState,
                                    backgroundColor: e
                                }));
                            }}
                            classes={`me-1 picker ${errors?.json?.apresentation?.textSide?.backgroundColor ? 'is-invalid' : ''}`}
                        />
                        <PopoverPicker
                            color={props.textColor}
                            onChangeX={(e) => {
                                setFieldValue("json.apresentation.textSide.textColor", e);
                                setProps(prevState => ({
                                    ...prevState,
                                    textColor: e
                                }));
                            }}
                            classes={`me-1 picker ${errors?.json?.apresentation?.textSide?.textColor ? 'is-invalid' : ''}`}
                        />
                    </div>
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors?.json?.apresentation?.textSide?.textColor ? errors?.json?.apresentation?.textSide?.textColor : errors?.json?.apresentation?.textSide?.backgroundColor ? errors?.json?.apresentation?.textSide?.backgroundColor : ''}
                    </Form.Control.Feedback>
                </div>
            </Row>
            <Row className="mx-0">
                <p className="text-start fw-bold mb-2 mt-3">Tela de resultado</p>
                <Form.Group
                    className="mb-3"
                    as={Col}
                    sm="12"
                    controlId="validationFormik01"
                >
                    <Form.Label>Título</Form.Label>
                    <Form.Control
                        as="textarea"
                        name="json.apresentation.prize.title.value"
                        value={values.json.apresentation?.prize?.title?.value}
                        onChange={(e) => {
                            if (e.target.value === '') {
                                setFieldValue("json.apresentation.prize.title.status", false);
                            } else {
                                setFieldValue("json.apresentation.prize.title.status", true);
                            }
                            setFieldValue("json.apresentation.prize.title.value", e.target.value);
                        }}
                        isInvalid={!!errors?.json?.apresentation?.prize?.title?.value}
                        isValid={touched?.json?.apresentation?.prize?.title?.value && !errors?.json?.apresentation?.prize?.title?.value}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors.json?.apresentation?.prize?.title?.value}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                    as={Col}
                    className="mb-3"
                    md="3"
                    controlId="validationFormik01">
                    <Form.Label>Largura (px)</Form.Label>
                    <Form.Control
                        type="number"
                        name="json.apresentation.prize.width"
                        value={values.json.apresentation.prize.width}
                        onChange={(e) => {
                            setFieldValue("json.apresentation.prize.width", e.target.value);
                        }}
                        isInvalid={!!errors.json?.apresentation?.prize?.width}
                        isValid={touched.json?.apresentation?.prize?.width && !errors.json?.apresentation?.prize?.width}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors.json?.apresentation?.prize?.width}
                    </Form.Control.Feedback>
                </Form.Group>
            </Row>

            <Row className="mx-0">
                <Form.Group
                    as={Col}
                    md="6">
                    <Card className="mb-3">
                        <Card.Body>
                            <div className="text-center mb-3">
                                <div className="d-flex justify-content-center mb-3">
                                    <Form.Label>Imagem vencedor<OverlayTrigger overlay={<Tooltip>É possível utilizar gifs para adicionar uma animação personalizada</Tooltip>}>
                                        <HelpTooltip icon={faExclamationCircle} />
                                    </OverlayTrigger></Form.Label>
                                </div>
                            </div>
                            <Form.Control
                                type="hidden"
                                name="json.apresentation.prize.image"
                                value={values.json.apresentation.prize.image}
                                onChange={handleChange}
                                isInvalid={!!errors?.json?.apresentation?.prize?.image}
                                isValid={touched?.json?.apresentation?.prize?.image && !errors?.json?.apresentation?.prize?.image}
                            />
                            <div className="imagesUpload">
                                <img
                                    className={`h-100 img-thumbnail ${!!errors?.json?.apresentation?.prize?.image ? "is-invalid" : ""}`}
                                    src={
                                        values.json.apresentation.prize.image !== "" && values.json.apresentation.prize.image !== null
                                            ? domainConfig.imageServer +
                                            "/customizacao/" +
                                            values.customizacao_id +
                                            "/" +
                                            values.json.apresentation.prize.image
                                            : domainConfig.imageServer + "/empty-background.png"
                                    }
                                    alt="Imagem footer aplicativo"
                                />
                                <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors?.json?.apresentation?.prize?.image}
                                </Form.Control.Feedback>
                            </div>
                            <DropzoneComponent
                                className="mt-3 dropzoneComp"
                                type="image"
                                handlePreviewSuccess={handlePreviewSuccess}
                                handlePreviewError={handlePreviewError}
                                setFieldValue={setFieldValue}
                                folder="customizacao"
                                id={values.customizacao_id}
                                field="json.apresentation.prize.image"
                            />
                        </Card.Body>
                    </Card>
                </Form.Group>
            </Row>
            <Row className="mx-0">
                <Form.Group
                    className="my-3"
                    as={Col}
                    md="6"
                    controlId="validationFormik03"
                >
                    <Form.Label>Tipo de layout</Form.Label>
                    <Form.Select
                        onChange={(e) => {
                            if (e.target.value == 2) {
                                setFieldValue(
                                    "json.apresentation.imageSide.value", 40
                                );
                                setFieldValue(
                                    "json.apresentation.textSide.value", 60
                                );
                            } else {
                                setFieldValue(
                                    "json.apresentation.imageSide.value", 0
                                );
                                setFieldValue(
                                    "json.apresentation.textSide.value", 100
                                );
                            }
                        }}
                        value={values.json.apresentation?.imageSide?.value && values.json.apresentation?.imageSide?.value > 0 ? 2 : 1}>
                        <option value={1}>
                            Simples
                        </option>
                        <option value={2}>
                            Com banner
                        </option>
                    </Form.Select>
                </Form.Group>
            </Row>
            {
                values.json.apresentation.imageSide?.value > 0 &&
                <>
                    <Row className="mx-0">
                        <Form.Group
                            as={Col}
                            md="3"
                            className="mb-3"
                            controlId="validationFormik01">
                            <Form.Label>Tamanho do banner (%)</Form.Label>
                            <Form.Control
                                type="number"
                                name="json.apresentation.imageSide.value"
                                value={values.json.apresentation?.imageSide?.value}
                                onChange={(e) => {
                                    if (e.target.value === undefined || e.target.value === '') {
                                        e.target.value = 0
                                    }
                                    let textSideValue = 100 - e.target.value
                                    setFieldValue("json.apresentation.imageSide.value", e.target.value);
                                    setFieldValue("json.apresentation.textSide.value", textSideValue);
                                }}
                                isInvalid={!!errors.json?.apresentation?.imageSide?.value}
                                isValid={touched.json?.apresentation?.imageSide?.value && !errors.json?.apresentation?.imageSide?.value}
                            />
                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                                {errors.json?.apresentation?.imageSide?.value}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                            as={Col}
                            md="3"
                            className="mb-3"
                            controlId="validationFormik03"
                        >
                            <Form.Label>Posição</Form.Label>
                            <Form.Select
                                onChange={handleChange}
                                name="json.apresentation.imageSide.position"
                                value={values.json.apresentation?.imageSide?.position}>
                                <option value={'start'}>
                                    Esquerda
                                </option>
                                <option value={'end'}>
                                    Direita
                                </option>
                            </Form.Select>
                        </Form.Group>
                    </Row>
                    <Row className="mx-0 mb-3">
                        <Form.Group
                            as={Col}
                            md="6">
                            <Card className="mb-3">
                                <Card.Body>
                                    <div className="text-center mb-3">
                                        <div className="d-flex justify-content-center mb-3">
                                            <Form.Label>Banner</Form.Label>
                                        </div>
                                    </div>
                                    <Form.Control
                                        type="hidden"
                                        name="json.apresentation.imageSide.background"
                                        value={values.json.apresentation?.imageSide?.background}
                                        onChange={handleChange}
                                        isInvalid={!!errors?.json?.apresentation?.imageSide?.background}
                                        isValid={touched?.json?.apresentation?.imageSide?.background && !errors?.json?.apresentation?.imageSide?.background}
                                    />
                                    <div className="imagesUpload">
                                        <img
                                            className={`h-100 img-thumbnail ${!!errors?.json?.apresentation?.imageSide?.background ? "is-invalid" : ""}`}
                                            src={
                                                values.json.apresentation?.imageSide?.background !== "" && values.json.apresentation?.imageSide?.background !== null
                                                    ? domainConfig.imageServer +
                                                    "/customizacao/" +
                                                    values.customizacao_id +
                                                    "/" +
                                                    values.json.apresentation?.imageSide?.background
                                                    : domainConfig.imageServer + "/empty-background.png"
                                            }
                                            alt="Imagem footer aplicativo"
                                        />
                                        <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                            {errors?.json?.apresentation?.imageSide?.background}
                                        </Form.Control.Feedback>
                                    </div>
                                    <DropzoneComponent
                                        className="mt-3 dropzoneComp"
                                        type="image"
                                        handlePreviewSuccess={handlePreviewSuccess}
                                        handlePreviewError={handlePreviewError}
                                        setFieldValue={setFieldValue}
                                        folder="customizacao"
                                        id={values.customizacao_id}
                                        field="json.apresentation.imageSide.background"
                                    />
                                </Card.Body>
                            </Card>
                        </Form.Group>
                    </Row>
                </>
            }
            <Row className="mx-0">
                <Form.Group
                    as={Col}
                    sm="12"
                    controlId="validationFormik01"
                >
                    <Form.Check
                        checked={values.json.apresentation?.showQRCode.status === true ? true : false}
                        type="checkbox"
                        name="json.apresentation.showQRCode.status"
                        label="Mostrar QRCode"
                        onChange={(e) => {
                            setFieldValue(
                                "json.apresentation.showQRCode.status",
                                values.json.apresentation.showQRCode?.status === true ? false : true
                            );
                        }}
                    />
                    {values.json.apresentation?.showQRCode?.status === true ? (
                        <Row>
                            <Form.Group
                                className="mb-3"
                                as={Col}
                                sm="4"
                                controlId="validationFormik06">
                                <Form.Label>Texto</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="json.apresentation.showQRCode.text"
                                    value={values.json.apresentation.showQRCode.text}
                                    onChange={handleChange}
                                    isInvalid={!!errors.apresentation?.showQRCode?.text}
                                    isValid={
                                        touched.json?.apresentation?.showQRCode?.text &&
                                        !errors.json?.apresentation?.showQRCode?.text
                                    }
                                />
                                <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.json?.apresentation?.showQRCode?.text}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group
                                className="mb-3"
                                as={Col}
                                sm="4"
                                controlId="validationFormik06"
                            >
                                <Form.Label>URL<OverlayTrigger overlay={<Tooltip>Caso nenhuma URL seja fornecida o QRCode irá redirecionar o usuário ao evento</Tooltip>}>
                                    <HelpTooltip icon={faExclamationCircle} />
                                </OverlayTrigger></Form.Label>
                                <Form.Control
                                    type="text"
                                    name="json.apresentation.showQRCode.link"
                                    value={values.json.apresentation.showQRCode.link}
                                    onChange={handleChange}
                                    isInvalid={!!errors.apresentation?.showQRCode?.link}
                                    isValid={
                                        touched.json?.apresentation?.showQRCode?.link &&
                                        !errors.json?.apresentation?.showQRCode?.link
                                    }
                                />
                                <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.json?.apresentation?.showQRCode?.link}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group
                                className="mb-3"
                                as={Col}
                                sm="4"
                                controlId="validationFormik06"
                            >
                                <Form.Label>Posição Y</Form.Label>
                                <Form.Select
                                    onChange={(e) => {
                                        setFieldValue("json.apresentation.showQRCode.position.y", e.target.value);
                                    }}
                                    value={values.json.apresentation.showQRCode.position.y}>
                                    <option value={'top'}>
                                        Topo
                                    </option>
                                    <option value={'bottom'}>
                                        Fundo
                                    </option>
                                </Form.Select>
                                <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.json?.apresentation?.showQRCode?.position?.y}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group
                                className="mb-3"
                                as={Col}
                                sm="4"
                                controlId="validationFormik06"
                            >
                                <Form.Label>Posição X</Form.Label>
                                <Form.Select
                                    onChange={(e) => {
                                        setFieldValue("json.apresentation.showQRCode.position.x", e.target.value);
                                    }}
                                    value={values.json.apresentation.showQRCode.position.x}>
                                    <option value={'left'}>
                                        Esquerda
                                    </option>
                                    <option value={'right'}>
                                        Direita
                                    </option>
                                </Form.Select>
                                <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.json?.apresentation?.showQRCode?.position?.x}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                    ) : (
                        ""
                    )}
                </Form.Group>
            </Row>
            {modalData && <Modal
                size={"sm"}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop
                show={showModal}
                onHide={() => setShowModal(false)}>
                <Formik
                    onSubmit={registerData}
                    validateOnChange={false}
                    validateOnBlur={true}
                    validationSchema={Yup.object().shape({
                        possibility: Yup.number()
                            .positive('Número maior que 0')
                            .min(0, `Mínimo de 0`)
                            .max(100, `Máximo de 100`),
                        prize: Yup.string()
                            .max(100, `Máximo de 100 caracteres`),
                        quantity: Yup.number()
                            .min(0, `Mínimo de 0`)

                    })}
                    initialValues={{
                        id: modalData.id,
                        possibility: modalData.possibility,
                        prize: modalData.prize,
                        quantity: modalData.quantity,
                        new: modalData.new ? true : false
                    }}
                >
                    {({
                        submitForm,
                        handleSubmit,
                        handleChange,
                        values,
                        touched,
                        isValid,
                        errors,
                        dirty,
                        isSubmitting,
                        setSubmitting,
                        setFieldValue,
                    }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Modal.Header closeButton>{modalData.new ? 'Adicionar segmento' : 'Editar segmento'}</Modal.Header>
                            <Modal.Body>
                                <Row className="mx-0">
                                    <Form.Group as={Col} sm={12} className='mb-3'>
                                        <Form.Label>Prêmio</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name={'prize'}
                                            value={values.prize}
                                            onChange={handleChange}
                                            isInvalid={!!errors.prize}
                                            isValid={touched.prize && !errors.prize}
                                        />
                                        <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.prize}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} sm={6} className='mb-3'>
                                        <Form.Label>Probabilidade</Form.Label>
                                        <Form.Control
                                            type="number"
                                            name={'possibility'}
                                            value={values.possibility}
                                            onChange={handleChange}
                                            min={0}
                                            max={100}
                                            isInvalid={!!errors.possibility}
                                            isValid={touched.possibility && !errors.possibility}
                                        />
                                        <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.possibility}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} sm={6} className='mb-3'>
                                        <Form.Label>Quantidade</Form.Label>
                                        <Form.Control
                                            type="number"
                                            name={'quantity'}
                                            value={values.quantity}
                                            onChange={handleChange}
                                            isInvalid={!!errors.quantity}
                                            isValid={touched.quantity && !errors.quantity}
                                        />
                                        <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.quantity}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                {!modalData.new && <Button type="button" className="me-2" onClick={() => removeSegment(values)}>
                                    Deletar
                                </Button>}
                                <Button type="submit" disabled={!dirty || isSubmitting}>
                                    Salvar
                                </Button>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>}
        </>
    );
};

export default PrizeDraw