import React from "react";

import PerfectScrollbar from "react-perfect-scrollbar";

import useSidebar from "../../hooks/useSidebar";
import SidebarNav from "./SidebarNav";
import Logo from "../../assets/img/logoFlex.svg";
import { useNavigate } from "react-router-dom";

const Sidebar = ({ items }) => {
  const navigate = useNavigate();
  const { isOpen } = useSidebar();

  function goToHomepage() {
    navigate("/");
  }

  return (
    <nav className={`sidebar ${!isOpen ? "collapsed" : ""}`}>
      <div className="sidebar-content">
        <PerfectScrollbar>
          <img
            src={Logo}
            alt="Flex Interativa"
            className="img-fluid p-3 cursor-pointer"
            onClick={() => {
              goToHomepage();
            }}
          />
          <SidebarNav items={items} />
        </PerfectScrollbar>
      </div>
    </nav>
  );
};

export default Sidebar;
