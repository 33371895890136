import * as Yup from "yup";
import { domainConfig } from "../config.js";
import {
  defaultCreationDate,
  defaultEndDate,
  isColor,
  validateDate,
  isDateAfterToday,
  convertDateToDisplay
} from "../assets/functions.js";


export const jsonCustomizacao = (aplicativoID) => {
  let data = "";
  switch (aplicativoID) {
    case 1:
      data = {
        "sound": false,
        "showScore": true,
        "showRanking": true,
        "timer": {
          "status": true,
          "value": 60
        },
        "reset": {
          "status": false,
          "text": "Finalizar"
        },
        "retry": {
          "status": true,
          "text": "Jogar novamente"
        },
        "endText": {
          "title": {
            "status": true,
            "value": "Parabéns! Você completou o Desafio"
          },
          "description": {
            "status": false,
            "value": ""
          }
        },
        "apresentation": {
          "rankingPage": {
            "title": {
              "status": true,
              "value": "Ranking"
            }
          },
          "imageSide": {
            "value": 0,
            "position": "start",
            "background": ""
          },
          "textSide": {
            "value": 100,
            "backgroundColor": "rgba(255,255,255,1)",
            "textColor": "rgba(0,0,0,1)",
            "h5": 1.25,
            "p": 1
          },
          "showQRCode": {
            "text": "Leia o QRCode para participar",
            "link": '',
            "status": true,
            "position": {
              "x": "left",
              "y": "bottom"
            }
          },
        },
        "memoryGame": {
          "cards": {
            "value": [{
              "pair": 1,
              "url": ""
            }, {
              "pair": 1,
              "url": ""
            }],
            "front": "",
            "borderRadius": 0,
            "width": 225,
            "cardsPerRow": 3,
            "padding": 10,
            "firstPlayerColor": "rgba(255,255,255,1)",
            "firstPlayerBackgroundColor": "darkgray",
            "secondPlayerColor": "rgba(255,255,255,1)",
            "secondPlayerBackgroundColor": "black"
          },
          "allowTwoPlayers": {
            "status": true,
            "description": "Selecione um modo de jogo"
          }
        }
      }
      return data;
    case 2:
      data = {
        "sound": false,
        "showRanking": true,
        "showScore": true,
        "showChart": false,
        "shuffleQuestions": false,
        "shuffleQuestionsArray": '',
        "correctFeedback": true,
        "answerFeedback": true,
        "correctAnswer": "rgba(0, 153, 68,1)",
        "incorrectAnswer": "rgba(242, 58, 58,1)",
        "selectQuestions": {
          "status": false,
          "value": 5,
        },
        "retry": {
          "status": true,
          "text": "Jogar novamente"
        },
        "reset": {
          "status": false,
          "text": "Finalizar"
        },
        "timer": {
          "status": true,
          "value": 30,
        },
        "endText": {
          "title": {
            "status": true,
            "value": "Parabéns! Você completou o Desafio"
          },
          "description": {
            "status": false,
            "value": ""
          }
        },
        "result": [],
        "apresentation": {
          "rankingPage": {
            "title": {
              "status": true,
              "value": "Ranking"
            }
          },
          "imageSide": {
            "value": 0,
            "position": "start",
            "background": "",
          },
          "textSide": {
            "value": 100,
            "backgroundColor": "rgba(255,255,255,1)",
            "textColor": "rgba(0,0,0,1)",
            "h5": 1.25,
            "p": 1
          },
          "showQRCode": {
            "text": "Leia o QRCode para participar",
            "link": '',
            "status": true,
            "position": {
              "x": "left",
              "y": "bottom"
            }
          },
        },
        "questions": [
          {
            "question_id": 1,
            "exercise": 1,
            "type": 1,
            "col": 6,
            "question": "",
            "url": "",
            "value": 50,
            "shuffleAnswers": true,
            "answers": [
              {
                "text": "",
                "isCorrect": true,
                "order": 0,
              },
              {
                "text": "",
                "isCorrect": false,
                "order": 1,
              },
              {
                "text": "",
                "isCorrect": false,
                "order": 2,
              },
              {
                "text": "",
                "isCorrect": false,
                "order": 3,
              },
            ],
          },
        ]
      };
      return data;
    case 3:
      data = {
        "deleteHistory": true,
        "showMessageHistory": true,
        "maxlength": 255,
        "userFeedback": false,
        "autoApproval": false,
        "messageLimit": 3,
        "minlength": 0,
        "title": {
          "status": true,
          "value": "Como Funciona?"
        },
        "description": {
          "status": true,
          "value": "1. Escreva Sua Pergunta: No campo abaixo, digite sua pergunta. Pode ser sobre o tema do evento, comentários específicos do apresentador, ou qualquer curiosidade que você tenha.\n\n 2.Envie com um Clique: Após redigir sua pergunta, clique em 'Enviar'. Nossa equipe cuidará da revisão e encaminhamento para o apresentador.\n\n3. Aguarde a Resposta: Se sua pergunta for selecionada, será abordada pelo apresentador durante o evento"
        },
        "apresentation": {
          "title": {
            "status": true,
            "value": "Me faça qualquer pergunta!"
          },
          "imageSide": {
            "value": 0,
            "position": "start",
            "background": "",
          },
          "textSide": {
            "value": 100,
            "backgroundColor": "rgba(255,255,255,1)",
            "textColor": "rgba(0,0,0,1)",
            "fontFamily": 'Roboto',
            "messageSlider": 2,
            "h5": 1.25,
            "p": 1
          },
          "showIsAnswered": true,
          "showAuthor": true,
          "showQRCode": {
            "text": "Leia o QRCode para participar",
            "link": '',
            "status": true,
            "position": {
              "x": "left",
              "y": "bottom"
            }
          },
        },
      }
      return data;
    case 4:
      data = {
        "deleteHistory": true,
        "showMessageHistory": true,
        "maxlength": 255,
        "autoApproval": false,
        "userFeedback": false,
        "messageLimit": 3,
        "minlength": 25,
        "title": {
          "status": true,
          "value": "Como Fun1ciona?"
        },
        "description": {
          "status": true,
          "value": "Escreva sua mensagem para aprecer na tela!"
        },
        "apresentation": {
          "title": {
            "status": false,
            "value": ""
          },
          "imageSide": {
            "value": 0,
            "position": "start",
            "background": "",
          },
          "textSide": {
            "value": 100,
            "backgroundColor": "rgba(255,255,255,1)",
            "textColor": "rgba(0,0,0,1)",
            "messageSlider": 2,
            "fontFamily": 'Roboto',
            "h5": 1.25,
            "p": 1
          },
          "intervalTime": 15000,
          "showIsAnswered": true,
          "showAuthor": true,
          "showQRCode": {
            "text": "Leia o QRCode para participar",
            "link": '',
            "status": true,
            "position": {
              "x": "left",
              "y": "bottom"
            }
          },
        },
      }
      return data;
    case 5:
      data = {
        "deleteHistory": true,
        "showMessageHistory": true,
        "maxlength": 50,
        "userFeedback": false,
        "autoApproval": false,
        "messageLimit": 1,
        "maxMessagePerLimit": 3,
        "minlength": 0,
        "title": {
          "status": true,
          "value": "Como Funciona?"
        },
        "description": {
          "status": true,
          "value": "1. Escreva Sua Pergunta: No campo abaixo, digite sua pergunta. Pode ser sobre o tema do evento, comentários específicos do apresentador, ou qualquer curiosidade que você tenha.\n\n 2.Envie com um Clique: Após redigir sua pergunta, clique em 'Enviar'. Nossa equipe cuidará da revisão e encaminhamento para o apresentador.\n\n3. Aguarde a Resposta: Se sua pergunta for selecionada, será abordada pelo apresentador durante o evento"
        },
        "apresentation": {
          "title": {
            "status": true,
            "value": "Me faça qualquer pergunta!"
          },
          "imageSide": {
            "value": 0,
            "position": "start",
            "background": "",
          },
          "textSide": {
            "value": 100,
            "backgroundColor": "rgba(255,255,255,1)",
            "textColor": "rgba(0,0,0,1)",
            "fontFamily": 'Roboto',
            "fontSizeMin": 30,
            "fontSizeMax": 80,
            "h5": 1.25,
            "p": 1
          },
          "showQRCode": {
            "text": "Leia o QRCode para participar",
            "link": '',
            "status": true,
            "position": {
              "x": "left",
              "y": "bottom"
            }
          },
        },
      }
      return data;
    case 6:
      data = {
        "title": {
          "status": false,
          "value": ""
        },
        "description": {
          "status": false,
          "value": ""
        },
        "prizeDrawType": 1,
        "drawMode": 1,
        "maxDraws": 3,
        "showResult": {
          "imageWidth": 250,
          "drawImage": "",
          "drawImageTimeout": 5000,
          "winnerImage": "",
          "winnerText": "Parabéns você foi o ganhador!\n\nSiga até o estande no palco principar para retirar seu prêmio!",
          "loserImage": "",
          "loserText": "Que pena, você não ganhou!\n\nBoa sorte na próxima",
          "waitingImage": "",
          "waitingText": "Esperando sorteio..."
        },
        "prizes": [],
        "apresentation": {
          "showQRCode": {
            "status": true,
            "position": {
              "x": "right",
              "y": "top"
            },
            "text": "Leia o QRCode para participar",
            "link": ""
          },
          "title": {
            "status": true,
            "value": "Leitor de QRCode"
          },
          "prize": {
            "title": {
              "status": true,
              "value": "Sorteio das premiações"
            },
            "image": "",
            "width": 300
          },
          "imageSide": {
            "value": 0,
            "position": "start",
            "background": ""
          },
          "textSide": {
            "value": 100,
            "backgroundColor": "rgba(255,255,255,1)",
            "textColor": "rgba(0,0,0,1)",
            "h5": 1.25,
            "p": 1,
            "fontFamily": "Roboto"
          }
        }
      }
      return data
    case 7:
      data = {
        "roulette": {
          "isInteractive": false,
          "activatePrizes": false,
          "nextResult": false,
          "spinToCenter": false,
          "duration": 3000,
          "numberOfRevolutions": 2,
          "borderColor": "rgba(0,0,0,1)",
          "borderWidth": 1,
          "debug": false,
          "image": "",
          "itemLabelAlign": "right",
          "itemLabelBaselineOffset": 0,
          "itemLabelFont": "sans-serif",
          "itemLabelFontSizeMax": 40,
          "itemLabelRadius": 0.85,
          "itemLabelRadiusMax": 0,
          "itemLabelRotation": 0,
          "itemLabelStrokeColor": "rgba(255,255,255,1)",
          "itemLabelStrokeWidth": 0,
          "lineColor": "rgba(0,0,0,1)",
          "lineWidth": 1,
          "overlayImage": "",
          "pixelRatio": 0,
          "pointerAngle": 0,
          "radius": 1,
          "rotationResistance": -35,
          "rotationSpeedMax": 300,
          "items": [
            {
              "id": "id-fatia01",
              "label": "Fatia01",
              "possibility": 25,
              "prize": "",
              "quantity": 10,
              "backgroundColor": "red",
              "image": null,
              "imageOpacity": 1,
              "imageRadius": 0.5,
              "imageRotation": 1,
              "imageScale": 1,
              "labelColor": "rgba(255, 255, 255, 1)",
              "value": "id-fatia01",
              "weight": 1,
              "new": false
            },
            {
              "id": "id-fatia02",
              "label": "Fatia02",
              "possibility": 25,
              "prize": "",
              "quantity": 10,
              "backgroundColor": "blue",
              "image": null,
              "imageOpacity": 1,
              "imageRadius": 0.5,
              "imageRotation": 1,
              "imageScale": 1,
              "labelColor": "rgba(255, 255, 255, 1)",
              "value": "id-fatia01",
              "weight": 1,
              "new": false
            },
            {
              "id": "id-fatia03",
              "label": "Fatia03",
              "possibility": 25,
              "prize": "",
              "quantity": 10,
              "backgroundColor": "green",
              "image": null,
              "imageOpacity": 1,
              "imageRadius": 0.5,
              "imageRotation": 1,
              "imageScale": 1,
              "labelColor": "rgba(255, 255, 255, 1)",
              "value": "id-fatia01",
              "weight": 1,
              "new": false
            },
            {
              "id": "id-fatia04",
              "label": "Fatia04",
              "possibility": 25,
              "prize": "",
              "quantity": 10,
              "backgroundColor": "yellow",
              "image": null,
              "imageOpacity": 1,
              "imageRadius": 0.5,
              "imageRotation": 1,
              "imageScale": 1,
              "labelColor": "rgba(255, 255, 255, 1)",
              "value": "id-fatia01",
              "weight": 1,
              "new": false
            },
          ]
        },
        "apresentation": {
          "textSide": {
            "backgroundColor": "rgba(255,255,255,1)",
            "textColor": "rgba(0,0,0,1)",
            "h5": 1.25,
            "p": 1,
          },
          "register": {
            "title": "Preencha os campos abaixos"
          },
          "configs": {
            "sound": false,
            "form": false,
            "logoSize": 380,
            "buttonPositionEnd": 16,
            "qrcodeEndPage": 30000

          },
          "endPage": {
            "title": "Agradecemos sua participação!",
            "buttonText": "Clique na tela para finalizar"
          }
        }
      };
      return data
    case 10:
      data = {
        "result": {
          "title": "Photobooth",
          "description": "Sua presença no nosso photobooth foi muito apreciada! A foto capturada já está disponível para download. Clique no botão abaixo para acessá-la e guardar como uma lembrança do evento."
        },
        "apresentation": {
          "textSide": {
            "backgroundColor": "rgba(255,255,255,1)",
            "textColor": "rgba(0,0,0,1)",
            "h5": 1.25,
            "p": 1,
          },
          "register": {
            "title": "Preencha os campos abaixos"
          },
          "configs": {
            "buttonSize": 75,
            "buttonPosition": 32,
            "buttonPositionPreview": 40,
            "buttonPositionEnd": 16,
            "logoSize": 380,
            "media": "image",
            "videoDuration": 5,
            "qrcodeEndPage": 30000,
            "printPhoto": false,
            "galleryTransition": 15000,
            "form": false
          },
          "frame": "",
          "frameHorizontal": "",
          "firstPage": {
            "buttonText": "Clique na tela para capturar sua foto"
          },
          "endPage": {
            "title": "Como baixar sua foto:",
            "description": "Utilize a câmera do seu dispositivo para escanear o QR Code abaixo, que dará acesso a sua foto para que você possa baixar e compartilhar.\n\nAgradecemos sua participação",
            "buttonText": "Clique na tela para finalizar"
          }
        }
      };
      return data
    default:
      return ""
  }
};

export const schemaCustomizacao = (aplicativoID) => {
  let schema = {};

  const isColor = (strColor) => {
    const s = new Option().style;
    s.color = strColor;
    return s.color !== "";
  };

  switch (aplicativoID) {
    case 1:
      schema = Yup.object().shape({
        customizacao_id: Yup.number().integer().required("Campo obrigatório"),
        titulo: Yup.string()
          .required("Campo obrigatório")
          .max("150", "Máximo de 150 caracteres"),
        json: Yup.object().shape({
          sound: Yup.boolean().required("Campo obrigatório"),
          showScore: Yup.boolean().required("Campo obrigatório"),
          showRanking: Yup.boolean().required("Campo obrigatório"),
          reset: Yup.object().shape({
            status: Yup.boolean().required("Campo obrigatório"),
            text: Yup.string().test(
              "text",
              "Campo obrigatório",
              function (value) {
                if (this.parent.status) {
                  if (value !== "" && value !== undefined) {
                    return true;
                  } else {
                    return false;
                  }
                } else {
                  return true;
                }
              }
            ),
          }),
          retry: Yup.object().shape({
            status: Yup.boolean().required("Campo obrigatório"),
            text: Yup.string().test(
              "text",
              "Campo obrigatório",
              function (value) {
                if (this.parent.status) {
                  if (value !== "" && value !== undefined) {
                    return true;
                  } else {
                    return false;
                  }
                } else {
                  return true;
                }
              }
            ),
          }),
          timer: Yup.object().shape({
            status: Yup.boolean().required("Campo obrigatório"),
            value: Yup.number().test(
              "value",
              "O tempo precisa ser maior que 15 segundos",
              function (value) {
                if (this.parent.status) {
                  if (value > 14) {
                    return true;
                  } else {
                    return false;
                  }
                } else {
                  return true;
                }
              }
            ),
          }),
          endText: Yup.object().shape({
            title: Yup.object().shape({
              status: Yup.boolean().required("Campo obrigatório"),
              value: Yup.string().test(
                "value",
                "Campo obrigatório",
                function (value) {
                  if (this.parent.status) {
                    if (value !== "" && value !== undefined) {
                      return true;
                    } else {
                      return false;
                    }
                  } else {
                    return true;
                  }
                }
              ),
            }),
            description: Yup.object().shape({
              status: Yup.boolean().required("Campo obrigatório"),
              value: Yup.string().test(
                "value",
                "Campo obrigatório",
                function (value) {
                  if (this.parent.status) {
                    if (value !== "" && value !== undefined) {
                      return true;
                    } else {
                      return false;
                    }
                  } else {
                    return true;
                  }
                }
              ),
            }),
          }),
          apresentation: Yup.object().shape({
            rankingPage: Yup.object().shape({
              title: Yup.object().shape({
                status: Yup.boolean().required("Campo obrigatório"),
                value: Yup.string().test(
                  "value",
                  "Campo obrigatório",
                  function (value) {
                    if (this.parent.status) {
                      if (value !== "" && value !== undefined) {
                        return true;
                      } else {
                        return false;
                      }
                    } else {
                      return true;
                    }
                  }
                ),
              }),
            }),
            imageSide: Yup.object().shape({
              value: Yup.number('Valor inválido')
                .required("Campo obrigatório")
                .max(100, `O Valor precisa ser menor que 100`),
              position: Yup.string().required("Campo obrigatório"),
              background: Yup.string().test(
                "value",
                "Campo obrigatório",
                function (value) {
                  if (this.parent.value > 0) {
                    if (value !== '') {
                      return true;
                    } else {
                      return false;
                    }
                  } else {
                    return true;
                  }
                }
              ),
            }),
            textSide: Yup.object().shape({
              value: Yup.number('Valor inválido')
                .required("Campo obrigatório")
                .max(100, `O Valor precisa ser menor que 100`),
              backgroundColor: Yup.string()
                .required("Campo obrigatório")
                .test("color", "Valor RGB inválido", function (value) {
                  if (isColor(value)) {
                    return true;
                  } else {
                    return false;
                  }
                }),
              textColor: Yup.string()
                .required("Campo obrigatório")
                .test("color", "Valor RGB inválido", function (value) {
                  if (isColor(value)) {
                    return true;
                  } else {
                    return false;
                  }
                }),
              h5: Yup.number('Valor inválido')
                .required("Campo obrigatório")
                .positive('O Valor precisa ser maior que 0'),
              p: Yup.number('Valor inválido')
                .required("Campo obrigatório")
                .positive('O Valor precisa ser maior que 0'),
            }),
            showQRCode: Yup.object().shape({
              text: Yup.string()
                .max("150", "Máximo de 150 caracteres"),
              link: Yup.string(),
              status: Yup.boolean().required("Campo obrigatório"),
              position: Yup.object().shape({
                x: Yup.string().required("Campo obrigatório"),
                y: Yup.string().required("Campo obrigatório"),
              }),
            }),
          }),
          memoryGame: Yup.object().shape({
            cards: Yup.object().shape({
              value: Yup.array(
                Yup.object({
                  pair: Yup.number().required("Campo obrigatório"),
                  url: Yup.string().required("Campo obrigatório"),
                })
              ),
              front: Yup.string().required("Campo obrigatório"),
              borderRadius: Yup.number().required("Campo obrigatório"),
              width: Yup.number().required("Campo obrigatório"),
              cardsPerRow: Yup.number().required("Campo obrigatório"),
              padding: Yup.number().required("Campo obrigatório"),
              firstPlayerColor: Yup.string()
                .required("Campo obrigatório")
                .test("color", "Valor RGB inválido", function (value) {
                  if (isColor(value)) {
                    return true;
                  } else {
                    return false;
                  }
                }),
              firstPlayerBackgroundColor: Yup.string()
                .required("Campo obrigatório")
                .test("color", "Valor RGB inválido", function (value) {
                  if (isColor(value)) {
                    return true;
                  } else {
                    return false;
                  }
                }),
              secondPlayerColor: Yup.string()
                .required("Campo obrigatório")
                .test("color", "Valor RGB inválido", function (value) {
                  if (isColor(value)) {
                    return true;
                  } else {
                    return false;
                  }
                }),
              secondPlayerBackgroundColor: Yup.string()
                .required("Campo obrigatório")
                .test("color", "Valor RGB inválido", function (value) {
                  if (isColor(value)) {
                    return true;
                  } else {
                    return false;
                  }
                }),
            }),
            allowTwoPlayers: Yup.object().shape({
              description: Yup.string().test(
                "value",
                "Campo obrigatório",
                function (value) {
                  if (this.parent.status) {
                    if (value !== "" && value !== undefined) {
                      return true;
                    } else {
                      return false;
                    }
                  } else {
                    return true;
                  }
                }
              ),
            }),
          }),

        }),
      })
      break;
    case 2:
      schema = Yup.object().shape({
        customizacao_id: Yup.number().integer().required("Campo obrigatório"),
        titulo: Yup.string()
          .required("Campo obrigatório")
          .max("150", "Máximo de 150 caracteres"),
        json: Yup.object().shape({
          sound: Yup.boolean().required("Campo obrigatório"),
          showRanking: Yup.boolean().required("Campo obrigatório"),
          showChart: Yup.boolean().required("Campo obrigatório"),
          showScore: Yup.boolean().required("Campo obrigatório"),
          shuffleQuestions: Yup.boolean().required("Campo obrigatório"),
          shuffleQuestionsArray: Yup.string().test(
            "text",
            "Formato inválido",
            function (value) {
              if (!value) {
                return true;
              }
              const isValidFormat = /^(\d+\s*,\s*)*\d+$/.test(value);
              if (isValidFormat) {
                return true;
              } else {
                return false;
              }
            }
          ),
          correctFeedback: Yup.boolean().required("Campo obrigatório"),
          answerFeedback: Yup.boolean().required("Campo obrigatório"),
          selectQuestions: Yup.object().shape({
            status: Yup.boolean().required("Campo obrigatório"),
            value: Yup.number().test(
              "text",
              "Valor precisa ser maior que 0",
              function (value) {
                if (this.parent.status) {
                  if (value > 0) {
                    return true;
                  } else {
                    return false;
                  }
                } else {
                  return true;
                }
              }
            ),
          }),
          reset: Yup.object().shape({
            status: Yup.boolean().required("Campo obrigatório"),
            text: Yup.string().test(
              "text",
              "Campo obrigatório",
              function (value) {
                if (this.parent.status) {
                  if (value !== "" && value !== undefined) {
                    return true;
                  } else {
                    return false;
                  }
                } else {
                  return true;
                }
              }
            ),
          }),
          retry: Yup.object().shape({
            status: Yup.boolean().required("Campo obrigatório"),
            text: Yup.string().test(
              "text",
              "Campo obrigatório",
              function (value) {
                if (this.parent.status) {
                  if (value !== "" && value !== undefined) {
                    return true;
                  } else {
                    return false;
                  }
                } else {
                  return true;
                }
              }
            ),
          }),
          timer: Yup.object().shape({
            status: Yup.boolean().required("Campo obrigatório"),
            value: Yup.number().test(
              "value",
              "O tempo precisa ser maior que 15 segundos",
              function (value) {
                if (this.parent.status) {
                  if (value > 14) {
                    return true;
                  } else {
                    return false;
                  }
                } else {
                  return true;
                }
              }
            ),
          }),
          endText: Yup.object().shape({
            title: Yup.object().shape({
              status: Yup.boolean().required("Campo obrigatório"),
              value: Yup.string().test(
                "value",
                "Campo obrigatório",
                function (value) {
                  if (this.parent.status) {
                    if (value !== "" && value !== undefined) {
                      return true;
                    } else {
                      return false;
                    }
                  } else {
                    return true;
                  }
                }
              ),
            }),
            description: Yup.object().shape({
              status: Yup.boolean().required("Campo obrigatório"),
              value: Yup.string().test(
                "value",
                "Campo obrigatório",
                function (value) {
                  if (this.parent.status) {
                    if (value !== "" && value !== undefined) {
                      return true;
                    } else {
                      return false;
                    }
                  } else {
                    return true;
                  }
                }
              ),
            }),
          }),
          apresentation: Yup.object().shape({
            rankingPage: Yup.object().shape({
              title: Yup.object().shape({
                status: Yup.boolean().required("Campo obrigatório"),
                value: Yup.string().test(
                  "value",
                  "Campo obrigatório",
                  function (value) {
                    if (this.parent.status) {
                      if (value !== "" && value !== undefined) {
                        return true;
                      } else {
                        return false;
                      }
                    } else {
                      return true;
                    }
                  }
                ),
              }),
            }),
            imageSide: Yup.object().shape({
              value: Yup.number('Valor inválido')
                .required("Campo obrigatório")
                .max(100, `O valor precisa ser menor que 100`),
              position: Yup.string().required("Campo obrigatório"),
              background: Yup.string().test(
                "value",
                "Campo obrigatório",
                function (value) {
                  if (this.parent.value > 0) {
                    if (value !== '') {
                      return true;
                    } else {
                      return false;
                    }
                  } else {
                    return true;
                  }
                }
              ),
            }),
            textSide: Yup.object().shape({
              value: Yup.number('Valor inválido')
                .required("Campo obrigatório")
                .max(100, `O valor precisa ser menor que 100`),
              backgroundColor: Yup.string()
                .required("Campo obrigatório")
                .test("color", "Valor RGB inválido", function (value) {
                  if (isColor(value)) {
                    return true;
                  } else {
                    return false;
                  }
                }),
              textColor: Yup.string()
                .required("Campo obrigatório")
                .test("color", "Valor RGB inválido", function (value) {
                  if (isColor(value)) {
                    return true;
                  } else {
                    return false;
                  }
                }),
              h5: Yup.number('Valor inválido')
                .required("Campo obrigatório")
                .positive('O valor precisa ser maior que 0'),
              p: Yup.number('Valor inválido')
                .required("Campo obrigatório")
                .positive('O valor precisa ser maior que 0'),
            }),
            showQRCode: Yup.object().shape({
              text: Yup.string()
                .max("150", "Máximo de 150 caracteres"),
              link: Yup.string(),
              status: Yup.boolean().required("Campo obrigatório"),
              position: Yup.object().shape({
                x: Yup.string().required("Campo obrigatório"),
                y: Yup.string().required("Campo obrigatório"),
              }),
            }),
          }),
          result: Yup.array(),
          questions: Yup.array(
            Yup.object({
              question_id: Yup.number().required("Campo obrigatório"),
              exercise: Yup.number().required("Campo obrigatório"),
              type: Yup.number().required("Campo obrigatório"),
              col: Yup.number().required("Campo obrigatório"),
              url: Yup.string().test("url", "Link inválido", function (value) {
                if (this.parent.type === 3) {
                  // Regular expression pattern for YouTube URL
                  let regex =
                    /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/gm;
                  let id = regex.exec(value);
                  if (id && id[3]) {
                    return true;
                  } else {
                    return false;
                  }
                } else if (this.parent.type === 2) {
                  if (value !== "" && value !== undefined) {
                    return true;
                  } else {
                    return false;
                  }
                } else {
                  return true;
                }
              }),
              question: Yup.string()
                .required("Campo obrigatório")
                .max("500", "Máximo de 500 caracteres"),
              value: Yup.number(),
              shuffleAnswers: Yup.boolean().required("Campo obrigatório"),
              answers: Yup.array(
                Yup.object({
                  text: Yup.string().required("Campo obrigatório"),
                  isCorrect: Yup.boolean().required("Campo obrigatório"),
                  order: Yup.number().required("Campo obrigatório"),
                })
              ),
            })
          ),
        }),
      });
      break;
    case 3:
      schema = Yup.object().shape({
        customizacao_id: Yup.number().integer().required("Campo obrigatório"),
        titulo: Yup.string()
          .required("Campo obrigatório")
          .max("100", "Máximo de 100 caracteres"),
        json: Yup.object().shape({
          deleteHistory: Yup.boolean().required("Campo obrigatório"),
          showMessageHistory: Yup.boolean().required("Campo obrigatório"),
          maxlength: Yup.number().integer().max("1000", "Valor máximo de 100").required("Campo obrigatório"),
          minlength: Yup.number().integer().max("100", "Valor máximo de 100").required("Campo obrigatório"),
          messageLimit: Yup.number().integer().required("Campo obrigatório"),
          userFeedback: Yup.boolean().required("Campo obrigatório"),
          autoApproval: Yup.boolean().required("Campo obrigatório"),
          title: Yup.object().shape({
            status: Yup.boolean().required("Campo obrigatório"),
            value: Yup.string().test(
              "value",
              "Campo obrigatório",
              function (value) {
                if (this.parent.status) {
                  if (value !== "" && value !== undefined) {
                    return true;
                  } else {
                    return false;
                  }
                } else {
                  return true;
                }
              }
            ),
          }),
          description: Yup.object().shape({
            status: Yup.boolean().required("Campo obrigatório"),
            value: Yup.string().test(
              "value",
              "Campo obrigatório",
              function (value) {
                if (this.parent.status) {
                  if (value !== "" && value !== undefined) {
                    return true;
                  } else {
                    return false;
                  }
                } else {
                  return true;
                }
              }
            ),
          }),
          apresentation: Yup.object().shape({
            title: Yup.object().shape({
              status: Yup.boolean().required("Campo obrigatório"),
              value: Yup.string().test(
                "value",
                "Campo obrigatório",
                function (value) {
                  if (this.parent.status) {
                    if (value !== "" && value !== undefined) {
                      return true;
                    } else {
                      return false;
                    }
                  } else {
                    return true;
                  }
                }
              ),
            }),
            showIsAnswered: Yup.boolean().required("Campo obrigatório"),
            showAuthor: Yup.boolean().required("Campo obrigatório"),
            imageSide: Yup.object().shape({
              value: Yup.number('Valor inválido')
                .required("Campo obrigatório")
                .max(100, `O valor precisa ser menor que 100`),
              position: Yup.string().required("Campo obrigatório"),
              background: Yup.string().test(
                "value",
                "Campo obrigatório",
                function (value) {
                  if (this.parent.value > 0) {
                    if (value !== '') {
                      return true;
                    } else {
                      return false;
                    }
                  } else {
                    return true;
                  }
                }
              ),
            }),
            textSide: Yup.object().shape({
              value: Yup.number('Valor inválido')
                .required("Campo obrigatório")
                .max(100, `O valor precisa ser menor que 100`),
              backgroundColor: Yup.string()
                .required("Campo obrigatório")
                .test("color", "Valor RGB inválido", function (value) {
                  if (isColor(value)) {
                    return true;
                  } else {
                    return false;
                  }
                }),
              fontFamily: Yup.string().required("Campo obrigatório"),
              textColor: Yup.string()
                .required("Campo obrigatório")
                .test("color", "Valor RGB inválido", function (value) {
                  if (isColor(value)) {
                    return true;
                  } else {
                    return false;
                  }
                }),
              messageSlider: Yup.number('Valor inválido')
                .required("Campo obrigatório")
                .positive('O valor precisa ser maior que 0'),
              h5: Yup.number('Valor inválido')
                .required("Campo obrigatório")
                .positive('O valor precisa ser maior que 0'),
              p: Yup.number('Valor inválido')
                .required("Campo obrigatório")
                .positive('O valor precisa ser maior que 0'),
            }),
            showQRCode: Yup.object().shape({
              text: Yup.string()
                .max("150", "Máximo de 150 caracteres"),
              link: Yup.string(),
              status: Yup.boolean().required("Campo obrigatório"),
              position: Yup.object().shape({
                x: Yup.string().required("Campo obrigatório"),
                y: Yup.string().required("Campo obrigatório"),
              }),
            }),
          }),
        }),
      });
      break;
    case 4:
      schema = Yup.object().shape({
        customizacao_id: Yup.number().integer().required("Campo obrigatório"),
        titulo: Yup.string()
          .required("Campo obrigatório")
          .max("100", "Máximo de 100 caracteres"),
        json: Yup.object().shape({
          deleteHistory: Yup.boolean().required("Campo obrigatório"),
          showMessageHistory: Yup.boolean().required("Campo obrigatório"),
          maxlength: Yup.number().integer().max("1000", "Valor máximo de 100").required("Campo obrigatório"),
          minlength: Yup.number().integer().max("100", "Valor máximo de 100").required("Campo obrigatório"),
          messageLimit: Yup.number().integer().required("Campo obrigatório"),
          autoApproval: Yup.boolean().required("Campo obrigatório"),
          userFeedback: Yup.boolean().required("Campo obrigatório"),
          title: Yup.object().shape({
            status: Yup.boolean().required("Campo obrigatório"),
            value: Yup.string().test(
              "value",
              "Campo obrigatório",
              function (value) {
                if (this.parent.status) {
                  if (value !== "" && value !== undefined) {
                    return true;
                  } else {
                    return false;
                  }
                } else {
                  return true;
                }
              }
            ),
          }),
          description: Yup.object().shape({
            status: Yup.boolean().required("Campo obrigatório"),
            value: Yup.string().test(
              "value",
              "Campo obrigatório",
              function (value) {
                if (this.parent.status) {
                  if (value !== "" && value !== undefined) {
                    return true;
                  } else {
                    return false;
                  }
                } else {
                  return true;
                }
              }
            ),
          }),
          apresentation: Yup.object().shape({
            title: Yup.object().shape({
              status: Yup.boolean().required("Campo obrigatório"),
              value: Yup.string().test(
                "value",
                "Campo obrigatório",
                function (value) {
                  if (this.parent.status) {
                    if (value !== "" && value !== undefined) {
                      return true;
                    } else {
                      return false;
                    }
                  } else {
                    return true;
                  }
                }
              ),
            }),
            intervalTime: Yup.number().integer().required("Campo obrigatório"),
            showIsAnswered: Yup.boolean().required("Campo obrigatório"),
            showAuthor: Yup.boolean().required("Campo obrigatório"),
            imageSide: Yup.object().shape({
              value: Yup.number('Valor inválido')
                .required("Campo obrigatório")
                .max(100, `O valor precisa ser menor que 100`),
              position: Yup.string().required("Campo obrigatório"),
              background: Yup.string().test(
                "value",
                "Campo obrigatório",
                function (value) {
                  if (this.parent.value > 0) {
                    if (value !== '') {
                      return true;
                    } else {
                      return false;
                    }
                  } else {
                    return true;
                  }
                }
              ),
            }),
            textSide: Yup.object().shape({
              value: Yup.number('Valor inválido')
                .required("Campo obrigatório")
                .max(100, `O valor precisa ser menor que 100`),
              backgroundColor: Yup.string()
                .required("Campo obrigatório")
                .test("color", "Valor RGB inválido", function (value) {
                  if (isColor(value)) {
                    return true;
                  } else {
                    return false;
                  }
                }),
              textColor: Yup.string()
                .required("Campo obrigatório")
                .test("color", "Valor RGB inválido", function (value) {
                  if (isColor(value)) {
                    return true;
                  } else {
                    return false;
                  }
                }),
              fontFamily: Yup.string().required("Campo obrigatório"),
              messageSlider: Yup.number('Valor inválido')
                .required("Campo obrigatório")
                .positive('O valor precisa ser maior que 0'),
              h5: Yup.number('Valor inválido')
                .required("Campo obrigatório")
                .positive('O valor precisa ser maior que 0'),
              p: Yup.number('Valor inválido')
                .required("Campo obrigatório")
                .positive('O valor precisa ser maior que 0'),
            }),
            showQRCode: Yup.object().shape({
              text: Yup.string()
                .max("150", "Máximo de 150 caracteres"),
              link: Yup.string(),
              status: Yup.boolean().required("Campo obrigatório"),
              position: Yup.object().shape({
                x: Yup.string().required("Campo obrigatório"),
                y: Yup.string().required("Campo obrigatório"),
              }),
            }),
          }),
        }),
      });
      break;
    case 5:
      schema = Yup.object().shape({
        customizacao_id: Yup.number().integer().required("Campo obrigatório"),
        titulo: Yup.string()
          .required("Campo obrigatório")
          .max("100", "Máximo de 100 caracteres"),
        json: Yup.object().shape({
          deleteHistory: Yup.boolean().required("Campo obrigatório"),
          showMessageHistory: Yup.boolean().required("Campo obrigatório"),
          maxlength: Yup.number().integer().max("1000", "Valor máximo de 100").required("Campo obrigatório"),
          minlength: Yup.number().integer().max("100", "Valor máximo de 100").required("Campo obrigatório"),
          messageLimit: Yup.number().integer().required("Campo obrigatório"),
          maxMessagePerLimit: Yup.number().integer().required("Campo obrigatório"),
          autoApproval: Yup.boolean().required("Campo obrigatório"),
          userFeedback: Yup.boolean().required("Campo obrigatório"),
          title: Yup.object().shape({
            status: Yup.boolean().required("Campo obrigatório"),
            value: Yup.string().test(
              "value",
              "Campo obrigatório",
              function (value) {
                if (this.parent.status) {
                  if (value !== "" && value !== undefined) {
                    return true;
                  } else {
                    return false;
                  }
                } else {
                  return true;
                }
              }
            ),
          }),
          description: Yup.object().shape({
            status: Yup.boolean().required("Campo obrigatório"),
            value: Yup.string().test(
              "value",
              "Campo obrigatório",
              function (value) {
                if (this.parent.status) {
                  if (value !== "" && value !== undefined) {
                    return true;
                  } else {
                    return false;
                  }
                } else {
                  return true;
                }
              }
            ),
          }),
          apresentation: Yup.object().shape({
            title: Yup.object().shape({
              status: Yup.boolean().required("Campo obrigatório"),
              value: Yup.string().test(
                "value",
                "Campo obrigatório",
                function (value) {
                  if (this.parent.status) {
                    if (value !== "" && value !== undefined) {
                      return true;
                    } else {
                      return false;
                    }
                  } else {
                    return true;
                  }
                }
              ),
            }),
            imageSide: Yup.object().shape({
              value: Yup.number('Valor inválido')
                .required("Campo obrigatório")
                .max(100, `O valor precisa ser menor que 100`),
              position: Yup.string().required("Campo obrigatório"),
              background: Yup.string().test(
                "value",
                "Campo obrigatório",
                function (value) {
                  if (this.parent.value > 0) {
                    if (value !== '') {
                      return true;
                    } else {
                      return false;
                    }
                  } else {
                    return true;
                  }
                }
              ),
            }),
            textSide: Yup.object().shape({
              value: Yup.number('Valor inválido')
                .required("Campo obrigatório")
                .max(100, `O valor precisa ser menor que 100`),
              backgroundColor: Yup.string()
                .required("Campo obrigatório")
                .test("color", "Valor RGB inválido", function (value) {
                  if (isColor(value)) {
                    return true;
                  } else {
                    return false;
                  }
                }),
              textColor: Yup.string()
                .required("Campo obrigatório")
                .test("color", "Valor RGB inválido", function (value) {
                  if (isColor(value)) {
                    return true;
                  } else {
                    return false;
                  }
                }),
              fontFamily: Yup.string().required("Campo obrigatório"),
              fontSizeMin: Yup.number('Valor inválido')
                .required("Campo obrigatório")
                .positive('O valor precisa ser maior que 0'),
              fontSizeMax: Yup.number('Valor inválido')
                .required("Campo obrigatório")
                .positive('O valor precisa ser maior que 0'),
              h5: Yup.number('Valor inválido')
                .required("Campo obrigatório")
                .positive('O valor precisa ser maior que 0'),
              p: Yup.number('Valor inválido')
                .required("Campo obrigatório")
                .positive('O valor precisa ser maior que 0'),
            }),
            showQRCode: Yup.object().shape({
              text: Yup.string()
                .max("150", "Máximo de 150 caracteres"),
              link: Yup.string(),
              status: Yup.boolean().required("Campo obrigatório"),
              position: Yup.object().shape({
                x: Yup.string().required("Campo obrigatório"),
                y: Yup.string().required("Campo obrigatório"),
              }),
            }),
          }),
        }),
      });
      break;
    case 6:
      schema = Yup.object().shape({
        customizacao_id: Yup.number().integer().required("Campo obrigatório"),
        titulo: Yup.string()
          .required("Campo obrigatório")
          .max("100", "Máximo de 100 caracteres"),
        json: Yup.object().shape({
          title: Yup.object().shape({
            status: Yup.boolean().required("Campo obrigatório"),
            value: Yup.string().test(
              "value",
              "Campo obrigatório",
              function (value) {
                if (this.parent.status) {
                  if (value !== "" && value !== undefined) {
                    return true;
                  } else {
                    return false;
                  }
                } else {
                  return true;
                }
              }
            ),
          }),
          description: Yup.object().shape({
            status: Yup.boolean().required("Campo obrigatório"),
            value: Yup.string().test(
              "value",
              "Campo obrigatório",
              function (value) {
                if (this.parent.status) {
                  if (value !== "" && value !== undefined) {
                    return true;
                  } else {
                    return false;
                  }
                } else {
                  return true;
                }
              }
            ),
          }),
          prizeDrawType: Yup.number().integer().required("Campo obrigatório"),
          drawMode: Yup.number().integer().required("Campo obrigatório"),
          maxDraws: Yup.number().integer().required("Campo obrigatório"),
          showResult: Yup.object().shape({
            imageWidth: Yup.number().typeError("Apenas números").required("Campo obrigatório"),
            drawImageTimeout: Yup.number().integer().test(
              'drawImageTimeout',
              'Campo obrigatório',
              function (value) {
                const drawMode = this.options.from[1].value.drawMode
                if (drawMode == 2) {
                  return value !== undefined && value !== null;
                }
                return true;
              }
            ),
            drawImage: Yup.string().test(
              'drawImage',
              'Campo obrigatório',
              function (value) {
                const drawMode = this.options.from[1].value.drawMode
                if (drawMode == 2) {
                  return value !== undefined && value !== null;
                }
                return true;
              }
            ),
            winnerImage: Yup.string().required("Campo obrigatório"),
            winnerText: Yup.string().required("Campo obrigatório"),
            loserImage: Yup.string().required("Campo obrigatório"),
            loserText: Yup.string().required("Campo obrigatório"),
            waitingImage: Yup.string().test(
              'waitingText',
              'Campo obrigatório',
              function (value) {
                const drawMode = this.options.from[1].value.drawMode
                if (drawMode == 1) {
                  return value !== undefined && value !== null;
                }
                return true;
              }
            ),
            waitingText: Yup.string().test(
              'waitingText',
              'Campo obrigatório',
              function (value) {
                const drawMode = this.options.from[1].value.drawMode
                if (drawMode == 1) {
                  return value !== undefined && value !== null;
                }
                return true;
              }
            ),
          }),
          prizes: Yup.array().when('json.drawMode', {
            is: 2,
            then: Yup.array().test('total-possibility', 'A soma das possibilidades não podem ser maior que 100', (prizes) => {
              const totalPossibility = prizes.reduce((acc, curr) => acc + curr.possibility, 0);
              if (totalPossibility > 100) {
                return false;
              } else {
                return true;
              }
            }),
            otherwise: Yup.array(), // if drawMode is not 2, no requirement
          }),
          apresentation: Yup.object().shape({
            title: Yup.object().shape({
              status: Yup.boolean().required("Campo obrigatório"),
              value: Yup.string().test(
                "value",
                "Campo obrigatório",
                function (value) {
                  if (this.parent.status) {
                    if (value !== "" && value !== undefined) {
                      return true;
                    } else {
                      return false;
                    }
                  } else {
                    return true;
                  }
                }
              ),
            }),
            prize: Yup.object().shape({
              title: Yup.object().shape({
                status: Yup.boolean().required("Campo obrigatório"),
                value: Yup.string().test(
                  "value",
                  "Campo obrigatório",
                  function (value) {
                    if (this.parent.status) {
                      if (value !== "" && value !== undefined) {
                        return true;
                      } else {
                        return false;
                      }
                    } else {
                      return true;
                    }
                  }
                ),
              }),
              image: Yup.string().test(
                'drawImage',
                'Campo obrigatório',
                function (value) {
                  const drawMode = this.options.from[2].value.drawMode
                  if (drawMode == 1) {
                    return value !== undefined && value !== null;
                  }
                  return true;
                }
              ),
              width: Yup.number().typeError("Apenas números").required("Campo obrigatório"),
            }),
            imageSide: Yup.object().shape({
              value: Yup.number('Valor inválido')
                .required("Campo obrigatório")
                .max(100, `O valor precisa ser menor que 100`),
              position: Yup.string().required("Campo obrigatório"),
              background: Yup.string().test(
                "value",
                "Campo obrigatório",
                function (value) {
                  if (this.parent.value > 0) {
                    if (value !== '') {
                      return true;
                    } else {
                      return false;
                    }
                  } else {
                    return true;
                  }
                }
              ),
            }),
            textSide: Yup.object().shape({
              value: Yup.number('Valor inválido')
                .required("Campo obrigatório")
                .max(100, `O valor precisa ser menor que 100`),
              backgroundColor: Yup.string()
                .required("Campo obrigatório")
                .test("color", "Valor RGB inválido", function (value) {
                  if (isColor(value)) {
                    return true;
                  } else {
                    return false;
                  }
                }),
              textColor: Yup.string()
                .required("Campo obrigatório")
                .test("color", "Valor RGB inválido", function (value) {
                  if (isColor(value)) {
                    return true;
                  } else {
                    return false;
                  }
                }),
              h5: Yup.number('Valor inválido')
                .required("Campo obrigatório")
                .positive('O valor precisa ser maior que 0'),
              p: Yup.number('Valor inválido')
                .required("Campo obrigatório")
                .positive('O valor precisa ser maior que 0'),
            }),
            showQRCode: Yup.object().shape({
              text: Yup.string()
                .max("150", "Máximo de 150 caracteres"),
              link: Yup.string(),
              status: Yup.boolean().required("Campo obrigatório"),
              position: Yup.object().shape({
                x: Yup.string().required("Campo obrigatório"),
                y: Yup.string().required("Campo obrigatório"),
              }),
            }),
          }),
        }),
      });
      break;
    case 7:
      schema = Yup.object().shape({
        customizacao_id: Yup.number().integer().required("Campo obrigatório"),
        titulo: Yup.string()
          .required("Campo obrigatório")
          .max("150", "Máximo de 150 caracteres"),
        json: Yup.object().shape({
          result: Yup.object().shape({
            title: Yup.string(),
            description: Yup.string(),
          }),
          apresentation: Yup.object().shape({
            register: Yup.object().shape({
              title: Yup.string()
            }),
            configs: Yup.object().shape({
              sound: Yup.boolean().required("Campo obrigatório"),
              buttonPositionEnd: Yup.number('Valor inválido')
                .positive('O Valor precisa ser maior que 0')
                .max(100, `O Valor precisa ser menor que 100`),
              logoSize: Yup.number('Valor inválido')
                .positive('O Valor precisa ser maior que 0'),
              qrcodeEndPage: Yup.number('Valor inválido')
                .positive('O Valor precisa ser maior que 0')
                .min(10000, 'O Valor precisa ser maior que 10000'),
            }),
            textSide: Yup.object().shape({
              backgroundColor: Yup.string()
                .required("Campo obrigatório")
                .test("color", "Valor RGB inválido", function (value) {
                  if (isColor(value)) {
                    return true;
                  } else {
                    return false;
                  }
                }),
              textColor: Yup.string()
                .required("Campo obrigatório")
                .test("color", "Valor RGB inválido", function (value) {
                  if (isColor(value)) {
                    return true;
                  } else {
                    return false;
                  }
                }),
              h5: Yup.number('Valor inválido')
                .required("Campo obrigatório")
                .positive('O Valor precisa ser maior que 0'),
              p: Yup.number('Valor inválido')
                .required("Campo obrigatório")
                .positive('O Valor precisa ser maior que 0'),
            }),
            endPage: Yup.object().shape({
              title: Yup.string(),
              buttonText: Yup.string().required("Campo obrigatório"),
            }),
          }),
        }),
      })
      break;
    case 10:
      schema = Yup.object().shape({
        customizacao_id: Yup.number().integer().required("Campo obrigatório"),
        titulo: Yup.string()
          .required("Campo obrigatório")
          .max("150", "Máximo de 150 caracteres"),
        json: Yup.object().shape({
          result: Yup.object().shape({
            title: Yup.string(),
            description: Yup.string(),
          }),
          apresentation: Yup.object().shape({
            register: Yup.object().shape({
              title: Yup.string(),
            }),
            configs: Yup.object().shape({
              buttonSize: Yup.number('Valor inválido')
                .required("Campo obrigatório")
                .positive('O Valor precisa ser maior que 0'),
              buttonPosition: Yup.number('Valor inválido')
                .positive('O Valor precisa ser maior que 0')
                .max(100, `O Valor precisa ser menor que 100`),
              buttonPositionPreview: Yup.number('Valor inválido')
                .positive('O Valor precisa ser maior que 0')
                .max(100, `O Valor precisa ser menor que 100`),
              buttonPositionEnd: Yup.number('Valor inválido')
                .positive('O Valor precisa ser maior que 0')
                .max(100, `O Valor precisa ser menor que 100`),
              logoSize: Yup.number('Valor inválido')
                .positive('O Valor precisa ser maior que 0'),
              videoDuration: Yup.number('Valor inválido')
                .positive('O Valor precisa ser maior que 0'),
              qrcodeEndPage: Yup.number('Valor inválido')
                .positive('O Valor precisa ser maior que 0')
                .min(10000, 'O Valor precisa ser maior que 10000'),
              printPhoto: Yup.boolean(),
              galleryTransition: Yup.number('Valor inválido')
                .positive('O Valor precisa ser maior que 0')
                .min(2000, 'O Valor precisa ser maior que 2000'),
            }),
            textSide: Yup.object().shape({
              backgroundColor: Yup.string()
                .required("Campo obrigatório")
                .test("color", "Valor RGB inválido", function (value) {
                  if (isColor(value)) {
                    return true;
                  } else {
                    return false;
                  }
                }),
              textColor: Yup.string()
                .required("Campo obrigatório")
                .test("color", "Valor RGB inválido", function (value) {
                  if (isColor(value)) {
                    return true;
                  } else {
                    return false;
                  }
                }),
              h5: Yup.number('Valor inválido')
                .required("Campo obrigatório")
                .positive('O Valor precisa ser maior que 0'),
              p: Yup.number('Valor inválido')
                .required("Campo obrigatório")
                .positive('O Valor precisa ser maior que 0'),
            }),
            frame: Yup.string().required("Campo obrigatório"),
            frameHorizontal: Yup.string().required("Campo obrigatório"),
            firstPage: Yup.object().shape({
              buttonText: Yup.string().required("Campo obrigatório"),
            }),
            endPage: Yup.object().shape({
              title: Yup.string(),
              buttonText: Yup.string().required("Campo obrigatório"),
            }),
          }),
        }),
      })
      return schema;
    default:
      break;
  };

  return schema
}

export const schemaEvent = (platform) => {
  let schema = platform == 1 ? schemaEventMode01() : schemaEventMode02();
  return schema;
};

export const schemaEventMode01 = () => {
  let schema = Yup.object().shape({
    user_id: Yup.number().integer().required("Campo obrigatório"),
    evento_id: Yup.number().integer().required("Campo obrigatório"),
    titulo: Yup.string()
      .required("Campo obrigatório")
      .max("150", "Máximo de 150 caracteres"),
    descricao: Yup.string()
      .max("255", "Máximo de 255 caracteres")
      .nullable(),
    status: Yup.number().required("Campo obrigatório").integer(),
    data_criacao: Yup.string()
      .required("Campo obrigatório")
      .max("255", "Máximo de 255 caracteres")
      .test("date", "Data inválida", function (valueX) {
        if (validateDate(valueX)) {
          return true
        } else {
          return false
        }
      }),
    data_termino: Yup.string()
      .required("Campo obrigatório")
      .max("255", "Máximo de 255 caracteres")
      .test("date", "Data inválida", function (valueX) {
        if (validateDate(valueX) && isDateAfterToday(valueX)) {
          return true
        } else {
          return false
        }
      }),
    json: Yup.object().shape({
      background: Yup.object().shape({
        type: Yup.string().required("Campo obrigatório"),
        color: Yup.string().required("Campo obrigatório")
          .test("color", "Valor RGB inválido", function (value) {
            if (isColor(value)) {
              return true;
            } else {
              return false;
            }
          }),
        url: Yup.string().test("url", "Campo obrigatório", function (value) {
          if (this.parent.type === "image") {
            if (value !== "" && value !== undefined) {
              return true;
            } else {
              return false;
            }
          } else {
            return true;
          }
        }),
      }),
      layout: Yup.object().shape({
        type: Yup.number().required("Campo obrigatório"),
        footer: Yup.object().shape({
          type: Yup.string().required("Campo obrigatório"),
          url: Yup.string().when('type', {
            is: 'image',
            then: Yup.string().required("Campo obrigatório"),
            otherwise: Yup.string()
          }),
          width: Yup.string().when('type', {
            is: 'image',
            then: Yup.string().required("Campo obrigatório"),
            otherwise: Yup.string()
          }),
          socialMedia: Yup.array(),
        }),
      }),
      removeLogoFlex: Yup.boolean(),
      noCache: Yup.boolean(),
      logo: Yup.string().required("Campo obrigatório"),
      logoWidth: Yup.number().typeError("Apenas números").required("Campo obrigatório"),
      fontFamily: Yup.string().required("Campo obrigatório"),
      h5Size: Yup.number().typeError("Apenas números").required("Campo obrigatório").test(
        "is-decimal",
        "Apenas valores decimais são permitidos",
        value => (value + "").match(/^\d+(\.\d{1,2})?$/)
      ),
      pSize: Yup.number().typeError("Apenas números").required("Campo obrigatório").test(
        "is-decimal",
        "Apenas valores decimais são permitidos",
        value => (value + "").match(/^\d+(\.\d{1,2})?$/)
      ),
      paddingBox: Yup.number().typeError("Apenas números").required("Campo obrigatório").test(
        "is-decimal",
        "Apenas valores decimais são permitidos",
        value => (value + "").match(/^\d+(\.\d{1,2})?$/)
      ),
      transparency: Yup.string()
        .required("Campo obrigatório")
        .test("transparency", "Valor RGB inválido", function (value) {
          if (isColor(value)) {
            return true;
          } else {
            return false;
          }
        }),
      primaryColor: Yup.string()
        .required("Campo obrigatório")
        .test("primaryColor", "Valor RGB inválido", function (value) {
          if (isColor(value)) {
            return true;
          } else {
            return false;
          }
        }),
      primaryColorText: Yup.string()
        .required("Campo obrigatório")
        .test("primaryColorText", "Valor RGB inválido", function (value) {
          if (isColor(value)) {
            return true;
          } else {
            return false;
          }
        }),
      baseColorText: Yup.string()
        .required("Campo obrigatório")
        .test("baseColorText", "Valor RGB inválido", function (value) {
          if (isColor(value)) {
            return true;
          } else {
            return false;
          }
        }),
      boxShadow: Yup.string()
        .required("Campo obrigatório")
        .test("boxShadow", "Valor RGB inválido", function (value) {
          if (isColor(value)) {
            return true;
          } else {
            return false;
          }
        }),
      bsBorderColor: Yup.string()
        .required("Campo obrigatório")
        .test("bsBorderColor", "Valor RGB inválido", function (value) {
          if (isColor(value)) {
            return true;
          } else {
            return false;
          }
        }),
      end: Yup.object().shape({
        title: Yup.object().shape({
          status: Yup.boolean(),
          value: Yup.string()
        }),
        description: Yup.object().shape({
          status: Yup.boolean(),
          value: Yup.string()
        }),
        showUserData: Yup.boolean(),
        showQRCode: Yup.boolean(),
      }),
      applications: Yup.object().shape({
        title: Yup.object().shape({
          status: Yup.boolean(),
          value: Yup.string(),
        }),
        description: Yup.object().shape({
          status: Yup.boolean(),
          value: Yup.string(),
        }),
      }),
      form: Yup.object().shape({
        title: Yup.object().shape({
          status: Yup.boolean(),
          value: Yup.string(),
        }),
        description: Yup.object().shape({
          status: Yup.boolean(),
          value: Yup.string(),
        }),
        reset: Yup.boolean(),
        status: Yup.boolean(),
        buttonText: Yup.string()
          .required("Campo obrigatório")
          .max("50", "Máximo de 50 caracteres"),
        authorizationMessage: Yup.mixed().nullable().when([], {
          is: (value) => typeof value === 'object' && value !== null,
          then: Yup.object().shape({
            status: Yup.boolean().required("Campo obrigatório"),
            value: Yup.string().test(
              "value",
              "Campo obrigatório",
              function (value) {
                if (this.parent.type) {
                  if (value !== "" && value !== undefined) {
                    return true;
                  } else {
                    return false;
                  }
                } else {
                  return true;
                }
              }
            ),
          }),
          otherwise: Yup.mixed().notRequired(),
        }),
        authorizationLink: Yup.string(),
        disableFormTitle: Yup.string(),
        disableDescription: Yup.string(),
        fields: Yup.array(),
      }),
    }),
    platform: Yup.number().integer(),
  })
  return schema
};

export const schemaEventMode02 = () => {
  let schema = Yup.object().shape({
    user_id: Yup.number().integer().required("Campo obrigatório"),
    event_id: Yup.number().integer().required("Campo obrigatório"),
    title: Yup.string()
      .required("Campo obrigatório")
      .max("150", "Máximo de 150 caracteres"),
    description: Yup.string()
      .max("255", "Máximo de 255 caracteres").nullable(),
    status: Yup.number().required("Campo obrigatório").positive().integer(),
    creation_date: Yup.string().required("Campo obrigatório"),
    end_date: Yup.string().required("Campo obrigatório"),
    external_event_id: Yup.string().max("45", "Máximo de 45 caracteres").nullable(),
    external_form_id: Yup.string().max("45", "Máximo de 45 caracteres").nullable(),
    // json: Yup.object().shape({
    //   saibamais: Yup.object().shape({
    //     status: Yup.boolean().required("Campo obrigatório"),
    //     value: Yup.string().test(
    //       "value",
    //       "Campo obrigatório",
    //       function (value) {
    //         if (this.parent.type) {
    //           if (value !== "" && value !== undefined) {
    //             return true;
    //           } else {
    //             return false;
    //           }
    //         } else {
    //           return true;
    //         }
    //       }
    //     ),
    //   }),
    //   form: Yup.object().shape({
    //     reset: Yup.boolean().required("Campo obrigatório"),
    //     status: Yup.boolean().required("Campo obrigatório"),
    //   }),
    // })
    //   background: Yup.object().shape({
    //     type: Yup.string().required("Campo obrigatório"),
    //     color: Yup.string()
    //       .required("Campo obrigatório")
    //       .test("color", "Valor RGB inválido", function (value) {
    //         if (isColor(value)) {
    //           return true;
    //         } else {
    //           return false;
    //         }
    //       }),
    //     url: Yup.string().test("url", "Campo obrigatório", function (value) {
    //       if (this.parent.type === "image") {
    //         if (value !== "" && value !== undefined) {
    //           return true;
    //         } else {
    //           return false;
    //         }
    //       } else {
    //         return true;
    //       }
    //     }),
    //   }),
    //   logo: Yup.string().required("Campo obrigatório"),
    //   fontFamily: Yup.string().required("Campo obrigatório"),
    //   transparency: Yup.number().required("Campo obrigatório"),
    //   primaryColor: Yup.string()
    //     .required("Campo obrigatório")
    //     .test("primaryColor", "Valor RGB inválido", function (value) {
    //       if (isColor(value)) {
    //         return true;
    //       } else {
    //         return false;
    //       }
    //     }),
    //   primaryColorText: Yup.string()
    //     .required("Campo obrigatório")
    //     .test("primaryColorText", "Valor RGB inválido", function (value) {
    //       if (isColor(value)) {
    //         return true;
    //       } else {
    //         return false;
    //       }
    //     }),

    //   baseColorText: Yup.string()
    //     .required("Campo obrigatório")
    //     .test("baseColorText", "Valor RGB inválido", function (value) {
    //       if (isColor(value)) {
    //         return true;
    //       } else {
    //         return false;
    //       }
    //     }),
    // }),
    // mode: Yup.number().integer(),
  })
  return schema
};

export const schemaAddCustomization = () => {
  let schema = {};

  schema = Yup.object().shape({
    evento_id: Yup.number().integer().required("Campo obrigatório"),
    customizacao_id: Yup.number().integer().required("Campo obrigatório"),
    titulo: Yup.string()
      .required("Campo obrigatório")
      .max("150", "Máximo de 150 caracteres"),
    json: Yup.object().required("Campo obrigatório"),
    data_inicio: Yup.string().required("Campo obrigatório"),
    data_termino: Yup.string().required("Campo obrigatório"),
    status: Yup.number().required("Campo obrigatório").positive().integer(),
  });

  return schema;
};

export const schemaAddForm = () => {
  let schema = {};
  schema = Yup.object().shape({
    inputID: Yup.number().integer().required("Campo obrigatório"),
    label: Yup.string().required("Campo obrigatório"),
    subLabel: Yup.string(),
    type: Yup.string().required("Campo obrigatório"),
    validation: Yup.string().required("Campo obrigatório"),
    defaultValue: Yup.string(),
    placeholder: Yup.string(),
    col: Yup.number().required("Campo obrigatório"),
    primary: Yup.boolean().required("Campo obrigatório"),
    required: Yup.boolean().required("Campo obrigatório"),
    allowMultipleChoice: Yup.boolean(),
    allowMultipleChoiceMax: Yup.number(),
    allowMultipleChoiceMin: Yup.number(),
    displayField: Yup.boolean(),
    options: Yup.array().of(Yup.string()).required("Campo obrigatório"),
    dependencies: Yup.object().shape({
      inputID: Yup.string(),
      value: Yup.string().when('inputID', {
        is: (inputID) => !!inputID,  // Check if inputID is truthy
        then: Yup.string().required("Campo obrigatório").typeError("Campo obrigatório"),
        otherwise: Yup.string()
      }),
    })
  });

  return schema;
};

export const schemaAddSocialMedia = () => {
  let schema = {};

  schema = Yup.object().shape({
    name: Yup.string().required("Campo obrigatório"),
    url: Yup.string().required("Campo obrigatório"),
  });

  return schema;
};

export const jsonEvent = (platform) => {
  let data = "";

  switch (platform) {
    case 1:
      data = {
        background: {
          type: "color",
          url: "",
          color: "rgba(255,255,255,1)"
        },
        layout: {
          type: 1, //1 or 2
          footer: {
            type: "icons", //icons or image
            url: "",
            width: 275,
            socialMedia: [] //facebook,instagram,youtube,linkedin,twitter,whatsapp,website 
          }
        },
        noCache: false,
        removeLogoFlex: false,
        logo: "",
        logoWidth: 225,
        fontFamily: "Open Sans",
        h5Size: 1.25,
        pSize: 1,
        paddingBox: 0,
        transparency: "rgba(255,255,255,0)",
        boxShadow: "rgba(0, 0, 0, 0)",
        primaryColor: "rgba(75, 167, 0,1)",
        primaryColorText: "rgba(255,255,255,1)",
        baseColorText: "rgba(0, 0, 0, 1)",
        secondaryColorText: "rgba(0,0,0,1)",
        boxShadowSecondary: "rgba(0, 0, 0, .15)",
        transparencySecondary: "rgba(255,255,255,1)",
        bsBorderColor: "rgb(206, 212, 218)",
        saibamais: {
          status: false,
          value: ""
        },
        applications: {
          title: {
            status: true,
            value: "Central de aplicativos"
          },
          description: {
            status: true,
            value: "Selecione um aplicativo para continuar"
          },
        },
        end: {
          showQRCode: false,
          showUserData: false,
          title: {
            status: true,
            value: "Confirmação de Inscrição"
          },
          description: {
            status: true,
            value: `Parabéns! Sua inscrição foi realizada com sucesso!`
          }
        },
        form: {
          title: {
            status: true,
            value: "Formulário de inscrição"
          },
          description: {
            status: true,
            value: "Preencha os campos para continuar"
          },
          buttonText: "Continuar",
          authorizationMessage: {
            status: true,
            value: "Autorizo a coleta e o processamento dos meus dados pessoais de acordo com os termos da Política de Privacidade"
          },
          authorizationLink: "https://flexinterativa.com.br/politica-privacidade/",
          reset: false,
          status: true,
          disableFormTitle: "Evento finalizado!",
          disableDescription: `O período de inscrição foi finalizado, agradecemos pelo seu interesse!`,
          fieldsToShow: [],
          fields: []
        }
      };
      return data
    case 2:
      data = {
        server: {
          eventosSocketAPI: "wss://websocket-dev.flexinterativa.com.br:4001",
          eventosAPI: "https://api-dev.flexinterativa.com.br:8081",
          eventosApp: "https://eventos-dev.flexinterativa.com.br",
        },
        background: {
          type: "image",
          url: "",
          color: "rgba(246,248,251,255)"
        },
        removeLogoFlex: false,
        "login": {
          "imageSide": {
            "value": 0,
            "position": "start",
            "url": ""
          },
          "textSide": {
            "value": 100,
            "backgroundColor": "rgba(0,0,0,1)",
            "color": "rgba(255,255,255,1)"
          },
          "loading": {
            "type": "image",
            "url": "",
            "backgroundColor": "rgba(0,0,0,1)",
            "loaderTime": 2000
          },
          "usePassword": true,
          "type": "image",
          "url": "",
          "color": "rgba(255,255,255,1)",
          "loaderTime": 2000,
          "imageSideUrl": "",
          "direction": "start"
        },
        "leadCollection": {
          "showNotRegistered": false
        },
        "home": {
          "status": false,
          "title": "",
          "content": ""
        },
        "email": {
          "supportEmailLink": "",
          "links": {
            "facebook": {
              "status": false,
              "link": ""
            },
            "instagram": {
              "status": false,
              "link": ""
            },
            "youtube": {
              "status": false,
              "link": ""
            },
            "linkedin": {
              "status": false,
              "link": ""
            },
            "twitter": {
              "status": false,
              "link": ""
            },
            "website": {
              "status": false,
              "link": ""
            },
            "whatsapp": {
              "status": false,
              "link": ""
            }
          }
        },
        banner: [],
        extraSections: [],
        sections: [],
        webpushIcon: "",
        logo: "",
        logoWidth: 200,
        loadingLogoWidth: 250,
        logoNav: "",
        primaryColor: "rgba(235,0,51,255)",
        primaryColorText: "rgba(255,255,255,1)",
        navColorText: "rgba(255,255,255,1)",
        navBackgroundColor: "rgba(235,0,51,255)",
        bodyColorText: "rgba(109,108,108,255)",
        borderColor: "rgb(206, 212, 218)",
        bodyBackgroundColor: "rgba(255,255,255,1)",
        fontFamily: "Roboto",
        fontFamilySize: 1,
        saibamais: {
          status: false,
          value: ""
        },
        layout: {
          type: 1,
          footer: {
            status: false,
            type: "icons",
            url: "",
            width: 275,
            socialMedia: []
          }
        },
        qrcodeOnly: false,
        form: {
          authorizationMessage: {
            status: true,
            value: "Autorizo a coleta e o processamento dos meus dados pessoais de acordo com os termos da Política de Privacidade"
          },
          authorizationLink: "https://flexinterativa.com.br/politica-privacidade/",
          fieldsToShow: [],
          fields: []
        }
      };
      return data
    default:
      return ""
  }

};
