
import React, { useState, useEffect } from "react";
import GoogleFontLoader from "react-google-font-loader";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import {
  Container
} from "react-bootstrap";
import {
  FormType, AplicativosType,
  FormComponentMode01, FormComponentMode02, SocialMediaType
} from "../../components/Events.js";
import { EventHistory01, EventHistory02 } from './EventHistory'

export const EventEdit = ({
  dataTable,
  fonts,
  aplicativos, }) => {
  const userState = useAuth();
  const [showHistory, setShowHistory] = useState(dataTable.platform === 1 ? userState.plataforma_aplicativos === 1 ? false : true : userState.plataforma_eventos === 1 ? false : true);
  console.log(showHistory)

  if (dataTable.platform === 1) {
    if (showHistory) {
      return (
        <EventHistory01
          dataTable={dataTable}
          fonts={fonts}
          aplicativos={aplicativos}
          setShowHistory={setShowHistory}>
        </EventHistory01>
      );
    } else {
      return (
        <EventEditMode01
          dataTable={dataTable}
          fonts={fonts}
          aplicativos={aplicativos}
          setShowHistory={setShowHistory}>
        </EventEditMode01>
      );
    }
  } else if (dataTable.platform === 2) {
    if (showHistory) {
      return (
        <EventHistory02
          dataTable={dataTable}
          fonts={fonts}
          aplicativos={aplicativos}
          setShowHistory={setShowHistory}>
        </EventHistory02>
      );
    } else {
      return (
        <EventEditMode02
          dataTable={dataTable}
          fonts={fonts}
          setShowHistory={setShowHistory}>
        </EventEditMode02>
      );
    }
  }
}

const EventEditMode01 = ({
  dataTable,
  fonts,
  aplicativos,
  setShowHistory
}) => {
  const [applications, setApplications] = useState(dataTable.aplicativos);
  const [dataAplicativo, setDataAplicativo] = useState(false);
  const [dataAddForm, setDataAddForm] = useState({ fields: dataTable.json.form.fields, fieldsToShow: dataTable.json.form.fieldsToShow ? dataTable.json.form.fieldsToShow : [], socialMedia: dataTable.json.layout.footer.socialMedia ? dataTable.json.layout.footer.socialMedia : [] });
  const [showModalAplicativos, setShowModalAplicativos] = useState(false);
  const [showModalForm, setShowModalForm] = useState(false);
  const [showSocialMediaForm, setShowSocialMediaForm] = useState(false);
  const [modalForm, setModalForm] = useState(false);

  return (
    <React.Fragment>
      <Container className="p-0">
        <GoogleFontLoader fonts={fonts} />
        <FormComponentMode01
          fonts={fonts}
          dataTable={dataTable}
          dataAddForm={dataAddForm}
          setShowHistory={setShowHistory}
          setDataAddForm={setDataAddForm}
          applications={applications}
          setApplications={setApplications}
          setDataAplicativo={setDataAplicativo}
          setModalForm={setModalForm}
          setShowModalAplicativos={setShowModalAplicativos}
          setShowModalForm={setShowModalForm}
          setShowSocialMediaForm={setShowSocialMediaForm}>
        </FormComponentMode01>
      </Container >
      {
        showModalAplicativos && (
          <AplicativosType
            dataTable={dataTable}
            setApplications={setApplications}
            dataAplicativo={dataAplicativo}
            setDataAplicativo={setDataAplicativo}
            showModalAplicativos={showModalAplicativos}
            setShowModalAplicativos={setShowModalAplicativos}
            aplicativos={aplicativos}
          ></AplicativosType>
        )
      }
      {
        showModalForm && (
          <FormType
            platform={dataTable.platform}
            dataAddForm={dataAddForm}
            setDataAddForm={setDataAddForm}
            modalForm={modalForm}
            setModalForm={setModalForm}
            showModalForm={showModalForm}
            setShowModalForm={setShowModalForm}
          />
        )
      }
      {
        showSocialMediaForm && (
          <SocialMediaType
            platform={dataTable.platform}
            dataAddForm={dataAddForm}
            setDataAddForm={setDataAddForm}
            modalForm={modalForm}
            setModalForm={setModalForm}
            showSocialMediaForm={showSocialMediaForm}
            setShowSocialMediaForm={setShowSocialMediaForm}
          />
        )
      }
    </React.Fragment >
  );
};

const EventEditMode02 = ({
  dataTable,
  fonts,
  setShowHistory
}) => {
  const [dataAplicativo, setDataAplicativo] = useState(false);
  const [dataAddForm, setDataAddForm] = useState({ fields: dataTable.json.form.fields, fieldsToShow: dataTable.json.form.fieldsToShow ? dataTable.json.form.fieldsToShow : [], socialMedia: dataTable.json.layout.footer.socialMedia });
  const [showModalForm, setShowModalForm] = useState(false);
  const [showSocialMediaForm, setShowSocialMediaForm] = useState(false);
  const [modalForm, setModalForm] = useState(false);

  return (
    <React.Fragment>
      <Container className="p-0">
        <GoogleFontLoader fonts={fonts} />
        <FormComponentMode02
          fonts={fonts}
          dataTable={dataTable}
          setShowHistory={setShowHistory}
          dataAddForm={dataAddForm}
          setDataAddForm={setDataAddForm}
          setModalForm={setModalForm}
          setShowModalForm={setShowModalForm}
          setShowSocialMediaForm={setShowSocialMediaForm}>
        </FormComponentMode02>
      </Container >
      {
        showModalForm && (
          <FormType
            platform={dataTable.platform}
            dataAddForm={dataAddForm}
            setDataAddForm={setDataAddForm}
            modalForm={modalForm}
            setModalForm={setModalForm}
            showModalForm={showModalForm}
            setShowModalForm={setShowModalForm}
          />
        )
      }
      {
        showSocialMediaForm && (
          <SocialMediaType
            platform={dataTable.platform}
            dataAddForm={dataAddForm}
            setDataAddForm={setDataAddForm}
            modalForm={modalForm}
            setModalForm={setModalForm}
            showModalForm={showSocialMediaForm}
            setShowModalForm={setShowSocialMediaForm}
          />
        )
      }
    </React.Fragment >
  );
};
