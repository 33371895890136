
import React, { useAppState } from "react";
import { Tooltip, OverlayTrigger } from 'react-bootstrap'
import axios from 'axios'
import { formatInTimeZone } from "date-fns-tz";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faSquareCheck,
    faThumbsDown,
    faThumbsUp,
    faSquareXmark
} from "@fortawesome/free-solid-svg-icons";
import {
    convertDateToDisplay,
    convertMilliseconds,
    NumberRangeColumnFilter,
    SelectColumnFilter,
    returnDrawType
} from "../assets/functions.js";

export function processData01(dataTable, data, appData, type, setFields, setFieldsResult) {
    let newParticipants = JSON.parse(JSON.stringify(data.participants))
    let newHistory = JSON.parse(JSON.stringify(data.history))
    let newResults = JSON.parse(JSON.stringify(data.results))
    let newValidations = JSON.parse(JSON.stringify(data.validations))

    switch (type) {
        case 1:
            let finalHistory = []
            newParticipants.forEach(participant => {
                participant.participantDetails = JSON.parse(participant.json);
                const transformedDetails = {};
                participant.participantDetails.data_visitacao_formatted = { 'label': 'data_visitacao_formatted', 'value': convertDateToDisplay(participant.data_visitacao) }
                for (const key in participant.participantDetails) {
                    const detail = participant.participantDetails[key];
                    if (detail.value === false) {
                        detail.value = 'Não assinalado'
                    } else if (detail.value === true) {
                        detail.value = 'Assinalado'
                    }
                    transformedDetails[detail.label] = detail.value;
                }
                Object.assign(participant, transformedDetails);
                newHistory.forEach(historyItem => {
                    if (participant.visitante_id === historyItem.visitante_id) {
                        if (historyItem.json) {
                            Object.assign(historyItem, JSON.parse(historyItem.json));
                            participant.timeX = historyItem.time
                            let convertedTime = convertMilliseconds(historyItem.time);
                            convertedTime = convertedTime['minutes'] + ':' + convertedTime['seconds'] + ':' + convertedTime['milliseconds']
                            participant.time = convertedTime
                            participant.score = historyItem.player1.value + historyItem.player2.value
                        }
                    }
                });
                finalHistory.push(participant)
            });
            finalHistory = sortRanking(finalHistory)
            setFields((prevState) => {
                const position = prevState.length - 2; // Calculate the position to insert the new object
                const newArray = [
                    ...prevState.slice(0, position), // Elements before the insertion point
                    {
                        Header: "Pontuação",
                        accessor: "score",
                        Filter: NumberRangeColumnFilter,
                        filter: "between",
                    },
                    {
                        Header: "Tempo", accessor: "time"
                    },
                    ...prevState.slice(position) // Elements after the insertion point
                ];
                return newArray;
            });
            return { 'participants': newParticipants, 'history': finalHistory, 'results': newResults };

        case 2:
            let finalHistory2 = []
            newParticipants.forEach(participant => {
                participant.participantDetails = JSON.parse(participant.json);
                const transformedDetails = {};
                participant.participantDetails.data_visitacao_formatted = { 'label': 'data_visitacao_formatted', 'value': convertDateToDisplay(participant.data_visitacao) }
                for (const key in participant.participantDetails) {
                    const detail = participant.participantDetails[key];
                    if (detail.value === false) {
                        detail.value = 'Não assinalado'
                    } else if (detail.value === true) {
                        detail.value = 'Assinalado'
                    }
                    transformedDetails[detail.label] = detail.value;
                }
                Object.assign(participant, transformedDetails);
                newHistory.forEach(historyItem => {
                    if (participant.visitante_id === historyItem.visitante_id) {
                        if (historyItem.json) {
                            Object.assign(historyItem, JSON.parse(historyItem.json));
                            participant.timeX = historyItem.time
                            let convertedTime = convertMilliseconds(historyItem.time);
                            convertedTime = convertedTime['minutes'] + ':' + convertedTime['seconds'] + ':' + convertedTime['milliseconds']
                            participant.time = convertedTime
                            participant.score = historyItem.value
                            let totalQuestions = 0
                            if (historyItem.selectQuestions && historyItem.selectQuestions.status) {
                                totalQuestions = historyItem.selectQuestions.status
                            } else {
                                totalQuestions = appData.questions?.length ? appData.questions?.length : 0
                            }
                            participant.currentIndex = historyItem.answers.length
                            participant.currentQuestion = historyItem.answers.length + '/' + totalQuestions
                        }
                    }
                });
                finalHistory2.push(participant)
            });
            finalHistory2 = sortRanking(finalHistory2)
            setFields((prevState) => {
                const position = prevState.length - 2; // Calculate the position to insert the new object
                const newArray = [
                    ...prevState.slice(0, position), // Elements before the insertion point
                    {
                        Header: "Pontuação",
                        accessor: "score",
                        Filter: NumberRangeColumnFilter,
                        filter: "between",
                    },
                    {
                        Header: "Tempo", accessor: "time"
                    },
                    {
                        Header: "Questão",
                        accessor: "currentQuestion",
                        Filter: SelectColumnFilter,
                    },
                    ...prevState.slice(position) // Elements after the insertion point
                ];
                return newArray;
            });
            return { 'participants': newParticipants, 'history': finalHistory2, 'results': newResults };
        case 3:
            let finalHistory3 = []
            newParticipants.forEach(participant => {
                participant.history = []
                participant.participantDetails = JSON.parse(participant.json);
                const transformedDetails = {};
                participant.participantDetails.data_visitacao_formatted = { 'label': 'data_visitacao_formatted', 'value': convertDateToDisplay(participant.data_visitacao) }
                for (const key in participant.participantDetails) {
                    const detail = participant.participantDetails[key];
                    if (detail.value === false) {
                        detail.value = 'Não assinalado'
                    } else if (detail.value === true) {
                        detail.value = 'Assinalado'
                    }
                    transformedDetails[detail.label] = detail.value;
                }
                Object.assign(participant, transformedDetails);
                let index = 0
                newHistory.forEach(historyItem => {
                    if (participant.visitante_id === historyItem.visitante_id) {
                        index++
                        Object.assign(historyItem, transformedDetails);
                        switch (historyItem.aprovado) {
                            case 1:
                                participant.aprovado = 'Aprovado'
                                break;
                            case 2:
                                participant.aprovado = 'Reprovado'
                                break;
                            default:
                                participant.aprovado = 'Em análise'
                                break;
                        }
                        participant.lido = historyItem.lido === 0 ? 'Não' : 'Sim'
                        participant.favorito = historyItem.favorito === 0 ? 'Não' : 'Sim'
                        participant.data_lido = historyItem.data_lido ? convertDateToDisplay(historyItem.data_lido) : ''
                        participant['Pergunta0' + index] = historyItem.message
                    }
                });
                finalHistory3.push(participant)
            });
            finalHistory3 = sortMessages(finalHistory3)
            setFields((prevState) => {
                const position = prevState.length - 2; // Calculate the position to insert the new object
                const newObjects = [];
                // Create the new objects to insert
                for (let index = 1; index <= appData.messageLimit; index++) {
                    newObjects.push({
                        Header: `Pergunta0${index}`,
                        accessor: `Pergunta0${index}`,
                        Cell: (cell) => {
                            return cell?.row?.original['Pergunta0' + index] || null
                        }
                    });
                }

                // Create the new array by concatenating slices and new objects
                const newArray = [
                    ...prevState.slice(0, position),
                    {
                        Header: "Status",
                        accessor: "aprovado",
                        Filter: SelectColumnFilter,
                    },
                    {
                        Header: "Lido",
                        accessor: "lido",
                        Filter: SelectColumnFilter,
                    },
                    {
                        Header: "Data da leitura", accessor: "data_lido"
                    },
                    {
                        Header: "Favorito",
                        accessor: "favorito",
                        Filter: SelectColumnFilter,
                    },
                    ...newObjects,
                    ...prevState.slice(position) // Elements after the insertion point
                ];
                return newArray;
            });
            return { 'participants': newParticipants, 'history': finalHistory3, 'results': newResults };
        case 4:
            let finalHistory4 = []
            newParticipants.forEach(participant => {
                participant.history = []
                participant.participantDetails = JSON.parse(participant.json);
                const transformedDetails = {};
                participant.participantDetails.data_visitacao_formatted = { 'label': 'data_visitacao_formatted', 'value': convertDateToDisplay(participant.data_visitacao) }
                for (const key in participant.participantDetails) {
                    const detail = participant.participantDetails[key];
                    if (detail.value === false) {
                        detail.value = 'Não assinalado'
                    } else if (detail.value === true) {
                        detail.value = 'Assinalado'
                    }
                    transformedDetails[detail.label] = detail.value;
                }
                Object.assign(participant, transformedDetails);
                let index = 0
                newHistory.forEach(historyItem => {
                    if (participant.visitante_id === historyItem.visitante_id) {
                        index++
                        Object.assign(historyItem, transformedDetails);
                        switch (historyItem.aprovado) {
                            case 1:
                                participant.aprovado = 'Aprovado'
                                break;
                            case 2:
                                participant.aprovado = 'Reprovado'
                                break;
                            default:
                                participant.aprovado = 'Em análise'
                                break;
                        }
                        participant.lido = historyItem.lido === 0 ? 'Não' : 'Sim'
                        participant.favorito = historyItem.favorito === 0 ? 'Não' : 'Sim'
                        participant.data_lido = historyItem.data_lido ? convertDateToDisplay(historyItem.data_lido) : ''
                        participant['Mensagem0' + index] = historyItem.message
                    }
                });
                finalHistory4.push(participant)
            });
            finalHistory4 = sortMessages(finalHistory4)
            setFields((prevState) => {
                const position = prevState.length - 2; // Calculate the position to insert the new object
                const newObjects = [];
                // Create the new objects to insert
                for (let index = 1; index <= appData.messageLimit; index++) {
                    newObjects.push({
                        Header: `Mensagem0${index}`,
                        accessor: `Mensagem0${index}`,
                        Cell: (cell) => {
                            return cell?.row?.original['Mensagem0' + index] || null
                        }
                    });
                }
                // Create the new array by concatenating slices and new objects
                const newArray = [
                    ...prevState.slice(0, position),
                    {
                        Header: "Status",
                        accessor: "aprovado",
                        Filter: SelectColumnFilter,
                    },
                    {
                        Header: "Lido",
                        accessor: "lido",
                        Filter: SelectColumnFilter,
                    },
                    {
                        Header: "Data da leitura", accessor: "data_lido"
                    },
                    {
                        Header: "Favorito",
                        accessor: "favorito",
                        Filter: SelectColumnFilter,
                    },
                    ...newObjects,
                    ...prevState.slice(position) // Elements after the insertion point
                ];
                return newArray;
            });
            return { 'participants': newParticipants, 'history': finalHistory4, 'results': newResults };
        case 5:
            let finalHistory5 = []
            newParticipants.forEach(participant => {
                participant.history = []
                participant.participantDetails = JSON.parse(participant.json);
                const transformedDetails = {};
                participant.participantDetails.data_visitacao_formatted = { 'label': 'data_visitacao_formatted', 'value': convertDateToDisplay(participant.data_visitacao) }
                for (const key in participant.participantDetails) {
                    const detail = participant.participantDetails[key];
                    if (detail.value === false) {
                        detail.value = 'Não assinalado'
                    } else if (detail.value === true) {
                        detail.value = 'Assinalado'
                    }
                    transformedDetails[detail.label] = detail.value;
                }
                Object.assign(participant, transformedDetails);
                let index = 0
                newHistory.forEach(historyItem => {
                    if (participant.visitante_id === historyItem.visitante_id) {
                        index++
                        Object.assign(historyItem, transformedDetails);
                        switch (historyItem.aprovado) {
                            case 1:
                                participant.aprovado = 'Aprovado'
                                break;
                            case 2:
                                participant.aprovado = 'Reprovado'
                                break;
                            default:
                                participant.aprovado = 'Em análise'
                                break;
                        }
                        let words = JSON.parse(historyItem.message);
                        participant['Conjunto0' + index] = words.join(", ");
                    }
                });
                finalHistory5.push(participant)
            });
            finalHistory5 = sortMessages(finalHistory5)
            setFields((prevState) => {
                const position = prevState.length - 2; // Calculate the position to insert the new object
                const newObjects = [];
                for (let index = 1; index <= appData.maxMessagePerLimit; index++) {
                    newObjects.push({
                        Header: `Conjunto0${index}`,
                        accessor: `Conjunto0${index}`,
                        Cell: (cell) => {
                            return cell?.row?.original['Conjunto0' + index] || null
                        }
                    });
                }

                // Create the new array by concatenating slices and new objects
                const newArray = [
                    ...prevState.slice(0, position),
                    {
                        Header: "Status",
                        accessor: "aprovado",
                        Filter: SelectColumnFilter,
                    },
                    ...newObjects,
                    ...prevState.slice(position) // Elements after the insertion point
                ];
                return newArray;
            });
            return { 'participants': newParticipants, 'history': finalHistory5, 'results': newResults };
        case 6:
            const indexedData = {};
            let labelX = null
            let finalHistory6 = []
            dataTable.json.form.fieldsToShow.forEach(inputID => {
                const field = dataTable.json.form.fields.find(field => field.inputID === inputID);
                if (field && labelX === null) {
                    labelX = field.label
                }
            });
            newParticipants.forEach(participant => {
                participant.history = []
                participant.participantDetails = JSON.parse(participant.json);
                const transformedDetails = {};
                participant.participantDetails.data_visitacao_formatted = { 'label': 'data_visitacao_formatted', 'value': convertDateToDisplay(participant.data_visitacao) }
                for (const key in participant.participantDetails) {
                    const detail = participant.participantDetails[key];
                    if (detail.value === false) {
                        detail.value = 'Não assinalado'
                    } else if (detail.value === true) {
                        detail.value = 'Assinalado'
                    }
                    transformedDetails[detail.label] = detail.value;
                }
                Object.assign(participant, transformedDetails);
                participant.idDraw = []
                participant.prize = []
                participant.validadoString = 'Não'
                indexedData[participant.visitante_id] = participant; // Use the value of 'id' as the key
                newHistory.forEach(historyItem => {
                    if (participant.visitante_id === historyItem.visitante_id) {
                        Object.assign(historyItem, transformedDetails);
                        if (historyItem.json) {
                            let item = JSON.parse(historyItem.json)
                            let index = 0
                            item.forEach(element => {
                                index++
                                participant['Premio0' + index] = element.prize
                            });
                        }
                    }
                });
                finalHistory6.push(participant)
            });
            finalHistory6 = sortMessages(finalHistory6)

            let finalResultList = []
            //adicionar id de identificaçao??
            Object.values(newResults).forEach(historyItem => {
                Object.assign(historyItem, JSON.parse(historyItem.json));
                historyItem.data = formatInTimeZone(historyItem.data, "America/Sao_Paulo", " dd/MM/yyyy HH:mm");
                historyItem.typeString = returnDrawType(historyItem.type)

                historyItem.result.forEach(element => {
                    if (historyItem.type === 'DrawDefault') {
                        let resultID = null
                        if (indexedData.hasOwnProperty(element.visitante_id)) {
                            resultID = element.visitante_id
                            indexedData[element.visitante_id]['idDraw'].push(historyItem.id)
                            if (historyItem.prize !== '' && historyItem.prize !== null) {
                                indexedData[element.visitante_id]['prize'].push(historyItem.prize)
                            }
                            const validado = newValidations.find(item => item.visitante_id === element.visitante_id);
                            const validadoString = validado ? validado.validado === 1 ? 'Sim' : 'Não' : 'Não';
                            indexedData[element.visitante_id]['validadoString'] = validadoString
                        }
                        finalResultList.push({
                            "resultado_id": historyItem.resultado_id,
                            "id": historyItem.id,
                            "title": historyItem.title,
                            "typeString": historyItem.typeString,
                            "prize": historyItem.prize,
                            "result": indexedData[element.visitante_id] && indexedData[element.visitante_id][labelX] ? indexedData[element.visitante_id][labelX] : null,
                            "resultID": resultID,
                            "data": historyItem.data
                        })
                    } else {
                        finalResultList.push({
                            "resultado_id": historyItem.resultado_id,
                            "id": historyItem.id,
                            "title": historyItem.title,
                            "typeString": historyItem.typeString,
                            "prize": historyItem.prize,
                            "result": element,
                            "resultID": null,
                            "data": historyItem.data
                        })
                    }
                })
            });
            setFields((prevState) => {
                let position = prevState.length - 2; // Calculate the position to insert the new object
                let newArray = []
                if (appData.drawMode === 2) {
                    const newObjects = [];
                    for (let index = 1; index <= appData.maxDraws; index++) {
                        newObjects.push({
                            Header: `Premio0${index}`,
                            accessor: `Premio0${index}`,
                            Cell: (cell) => {
                                return cell?.row?.original['Premio0' + index] || null
                            }
                        });
                    }

                    newArray = [
                        {
                            Header: "id",
                            accessor: "visitante_id"
                        },
                        ...prevState.slice(0, position), // Elements before the insertion point
                        {
                            Header: "Validado",
                            accessor: "validadoString"
                        },
                        ...newObjects,
                        ...prevState.slice(position) // Elements after the insertion point
                    ];
                    return newArray;
                } else {
                    newArray = [
                        {
                            Header: "id",
                            accessor: "visitante_id"
                        },
                        ...prevState.slice(0, position), // Elements before the insertion point
                        {
                            Header: "Validado",
                            accessor: "validadoString"
                        },
                        {
                            Header: "Sorteios ganhos",
                            accessor: "idDraw"
                        },
                        {
                            Header: "Prêmios ganhos",
                            accessor: "prize"
                        },
                        ...prevState.slice(position) // Elements after the insertion point
                    ];
                }
                return newArray;
            });
            setFieldsResult((prevState) => {
                const position = prevState.length - 1; // Calculate the position to insert the new object
                const newArray = [
                    {
                        Header: 'id',
                        accessor: "id"
                    },
                    {
                        Header: "Título",
                        accessor: "title"
                    }, {
                        Header: "Tipo de sorteio",
                        accessor: "typeString"
                    },
                    {
                        Header: "Premiação",
                        accessor: "prize"
                    },
                    {
                        Header: "Sorteados",
                        accessor: "result"
                    },
                    {
                        Header: "Participante ID",
                        accessor: "resultID",
                        Filter: NumberRangeColumnFilter,
                        filter: "between",
                    },
                    {
                        Header: "Data",
                        accessor: "data"
                    },
                    ...prevState.slice(position) // Elements after the insertion point
                ];
                return newArray;
            });
            return { 'participants': newParticipants, 'history': finalHistory6, 'results': finalResultList };
        case 7:
            let finalHistory7 = []
            newParticipants.forEach(participant => {
                participant.participantDetails = JSON.parse(participant.json);
                const transformedDetails = {};
                participant.participantDetails.data_visitacao_formatted = { 'label': 'data_visitacao_formatted', 'value': convertDateToDisplay(participant.data_visitacao) }
                for (const key in participant.participantDetails) {
                    const detail = participant.participantDetails[key];
                    if (detail.value === false) {
                        detail.value = 'Não assinalado'
                    } else if (detail.value === true) {
                        detail.value = 'Assinalado'
                    }
                    transformedDetails[detail.label] = detail.value;
                }
                Object.assign(participant, transformedDetails);
                newHistory.forEach(historyItem => {
                    if (participant.visitante_id === historyItem.visitante_id) {
                        if (historyItem.json) {
                            Object.assign(historyItem, JSON.parse(historyItem.json));
                            participant.label = historyItem.label
                            participant.prize = historyItem.prize
                        }
                    }
                });
                finalHistory7.push(participant)
            });
            finalHistory7 = sortRanking(finalHistory7)
            setFields((prevState) => {
                const position = prevState.length - 2; // Calculate the position to insert the new object
                const newArray = [
                    ...prevState.slice(0, position), // Elements before the insertion point
                    {
                        Header: "Segmento",
                        accessor: "label"
                    },
                    {
                        Header: "Premiação",
                        accessor: "prize"
                    },
                    ...prevState.slice(position) // Elements after the insertion point
                ];
                return newArray;
            });

            //results
            newResults.forEach(resultItem => {
                if (resultItem.json) {
                    resultItem.data = formatInTimeZone(resultItem.data, "America/Sao_Paulo", " dd/MM/yyyy HH:mm");
                    Object.assign(resultItem, JSON.parse(resultItem.json));
                }
            });
            setFieldsResult([
                {
                    Header: "Segmento",
                    accessor: "label"
                },
                {
                    Header: "Premiação",
                    accessor: "prize"
                },
                {
                    Header: "Data",
                    accessor: "data"
                },
            ])

            return { 'participants': newParticipants, 'history': finalHistory7, 'results': newResults };
        case 10:
            newHistory = newHistory.map(historyItem => {
                let parsedItem = JSON.parse(historyItem.json);
                parsedItem.data = historyItem.data;
                parsedItem.visitante_id = historyItem.visitante_id;
                return parsedItem;
            })

            newParticipants.forEach(participant => {
                participant.participantDetails = JSON.parse(participant.json);
                const transformedDetails = {};
                participant.participantDetails.data_visitacao_formatted = { 'label': 'data_visitacao_formatted', 'value': convertDateToDisplay(participant.data_visitacao) }
                for (const key in participant.participantDetails) {
                    const detail = participant.participantDetails[key];
                    if (detail.value === false) {
                        detail.value = 'Não assinalado'
                    } else if (detail.value === true) {
                        detail.value = 'Assinalado'
                    }
                    transformedDetails[detail.label] = detail.value;
                }
                Object.assign(participant, transformedDetails);

                newHistory.forEach(historyItem => {
                    if (historyItem.visitante_id === participant.visitante_id) {
                        participant.media = historyItem.media;
                        Object.keys(participant.participantDetails).forEach(key => {
                            let element = participant.participantDetails[key]
                            let newElements = { [element.label]: element.value }
                            Object.assign(historyItem, newElements);
                        });
                    }
                });
            });
            let newField = {
                Header: "Mídia", accessor: "media", Cell: (cell) => {
                    return (
                        <a target="_blank" href={cell.row.original.media} rel="noreferrer">Visualizar</a>
                    );
                },
            }
            setFields((prevState) => {
                const position = prevState.length - 2; // Calculate the position to insert the new object
                const newArray = [
                    ...prevState.slice(0, position), // Elements before the insertion point
                    newField, // New object
                    ...prevState.slice(position) // Elements after the insertion point
                ];
                return newArray;
            });
            return { 'participants': newParticipants, 'history': newHistory, 'results': newResults };

        default:
            newParticipants.forEach(participant => {
                participant.participantDetails = JSON.parse(participant.json);
                const transformedDetails = {};
                participant.participantDetails.data_visitacao_formatted = { 'label': 'data_visitacao_formatted', 'value': convertDateToDisplay(participant.data_visitacao) }
                for (const key in participant.participantDetails) {
                    const detail = participant.participantDetails[key];
                    if (detail.value === false) {
                        detail.value = 'Não assinalado'
                    } else if (detail.value === true) {
                        detail.value = 'Assinalado'
                    }
                    transformedDetails[detail.label] = detail.value;
                }
                Object.assign(participant, transformedDetails);
            });

            return { 'participants': newParticipants, 'history': newParticipants, 'results': [] };
    }
}

export function processData02(data) {
    let newParticipants = JSON.parse(JSON.stringify(data.participants));

    // Arrays to hold participants based on type
    const commonParticipants = [];
    const specialParticipants = [];

    newParticipants.forEach(participant => {
        participant.participantDetails = JSON.parse(participant.json);
        const transformedDetails = {};

        // Adding formatted fields
        participant.participantDetails.creation_date_formatted = {
            'label': 'creation_date_formatted',
            'value': participant.creation_date ? convertDateToDisplay(participant.creation_date) : ''
        };
        participant.participantDetails.register_date_formatted = {
            'label': 'register_date_formatted',
            'value': participant.register_date ? convertDateToDisplay(participant.register_date) : ''
        };
        participant.participantDetails.last_access_formatted = {
            'label': 'last_access_formatted',
            'value': participant.last_access ? convertDateToDisplay(participant.last_access) : ''
        };
        participant.participantDetails.status_formatted = {
            'label': 'status_formatted',
            'value': participant.status === 0 ? 'Inativo' : 'Ativo'
        };
        participant.participantDetails.type_formatted = {
            'label': 'type_formatted',
            'value': participant.type === 0 ? 'Comum' : 'Coletor de lead'
        };

        // Transforming details
        for (const key in participant.participantDetails) {
            const detail = participant.participantDetails[key];
            if (detail.value === false) {
                detail.value = 'Não assinalado';
            } else if (detail.value === true) {
                detail.value = 'Assinalado';
            }
            transformedDetails[detail.label] = detail.value;
        }

        // Assign transformed details back to participant
        Object.assign(participant, transformedDetails);

        // Splitting participants based on `type`
        if (participant.type === 0) {
            commonParticipants.push(participant); // type === 0
        } else {
            specialParticipants.push(participant); // type > 0
        }
    });

    return {
        'participants': commonParticipants,
        'users': specialParticipants,
        'history': newParticipants, // Preserving the full participants list for history
        'results': []
    };
}

export function generateAccessData(entries) {
    if (!entries) {
        return ""
    }
    const today = new Date();
    const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const daysInMonth = endOfMonth.getDate();
    const dataCounts = Array(daysInMonth).fill(0);

    // Generate dates for 7 days before and after today
    const dateRange = [];
    for (let i = 1; i <= daysInMonth; i++) {
        const newDate = new Date(today.getFullYear(), today.getMonth(), i);
        dateRange.push(newDate);
    }

    // Count the number of accesses for each day in the ranges
    entries.forEach(entry => {
        for (let i = 0; i < dateRange.length; i++) {
            let newDateEntry
            if (entry.data_visitacao) {
                newDateEntry = new Date(entry.data_visitacao);
            } else {
                newDateEntry = new Date(entry.creation_date);
            }
            if (newDateEntry.toDateString() === dateRange[i].toDateString()) {
                dataCounts[i]++;
                break;
            }
        }
    });
    const series = [
        {
            name: 'Total de registros',
            data: dataCounts
        }
    ];
    return series
}

export function filterHistoryQuiz(participants) {
    participants.forEach(item => {
        const parts = item.time.split(':');
        const minutes = parseInt(parts[0], 10);
        const seconds = parseInt(parts[1], 10);
        const milliseconds = parseInt(parts[2], 10);
        item.timeX = (minutes * 60 * 1000) + (seconds * 1000) + milliseconds;
    });
    participants.sort((a, b) => {
        const scoreA = a.value || 0;
        const scoreB = b.value || 0;
        const timeA = a.timeX || 0; // Set default to Infinity instead of 0
        const timeB = b.timeX || 0; // Set default to Infinity instead of 0
        if (a.currentIndex !== b.currentIndex) {
            return b.currentIndex - a.currentIndex;
        }
        if (scoreA !== scoreB) {
            return scoreB - scoreA;
        }
        if (timeA !== timeB) {
            return timeA - timeB;
        }
        return 0
    });

    // Assign ranking after sorting
    participants.forEach((participant, index) => {
        participant.ranking = index + 1;
    });

    return participants
}

export const getQuizResult = (answers, appData) => {
    let result = []
    answers.forEach(element => {
        appData.questions.forEach(ans => {
            if (element.question_id === ans.question_id) {
                ans.answers.forEach(value => {
                    if (element.order === value.order) {
                        if (!result[value.resultId]) {
                            result[value.resultId] = 0
                        }
                    }
                });
            }
        });
    });

    const resultId = result.reduce((maxIndex, currentValue, currentIndex, array) => {
        if (currentValue !== null && (maxIndex === -1 || currentValue > array[maxIndex])) {
            return currentIndex;
        }
        return maxIndex;
    }, -1);

    const finalResult = appData.result.find(r => r.resultId === resultId);
    return finalResult
}

export const sortRanking = (data) => {
    data.sort((a, b) => {
        const currentIndexA = a.currentIndex || 0;
        const currentIndexB = b.currentIndex || 0;
        const scoreA = a.score || 0;
        const scoreB = b.score || 0;
        const timeA = a.timeX || Infinity; // Set default to Infinity instead of 0
        const timeB = b.timeX || Infinity; // Set default to Infinity instead of 0
        if (currentIndexA !== currentIndexB) {
            return currentIndexB - currentIndexA;
        }

        if (scoreA !== scoreB) {
            return scoreB - scoreA;
        }
        if (timeA !== timeB) {
            return timeA - timeB;
        }
        return 0
    });
    return data
}

export const sortMessages = (data) => {
    data.sort((a, b) => {
        if (a.favorito > b.favorito) return -1;
        if (a.favorito < b.favorito) return 1;
        if (a.lido < b.lido) return -1;
        if (a.lido > b.lido) return 1;
        if (a.data < b.data) return -1;
        if (a.data > b.data) return 1;
        return 0;
    });
    return data
}

export const ParticipantHistoryModal = ({ modalData, setModalData }) => {
    const { appState, setUpdateMessages } = useAppState();

    function removeMessageDisplay(mensagem_id) {
        axios.delete(`/api/eventos/messagesDisplay/${mensagem_id}`)
            .then(function (response) {
                setModalData(currentData => ({
                    ...currentData,
                    history: currentData.history.filter(history => history.mensagem_id !== mensagem_id)
                }));
                setUpdateMessages((prevState) => prevState + 1)
            }).catch(function (error) {
                console.log(error)
            })
    }

    const ItemList = ({ index }) => {
        const dateX = new Date(modalData.history[index].data);
        const date = formatInTimeZone(dateX, "America/Sao_Paulo", " dd/MM HH:mm");

        return (
            <li className="list-group-item" key={'listGroup' + index}>
                <div className="row">
                    <div className="col">
                        <div className="horizontally-centered">
                            <span className="me-2 fs-12">
                                {date}
                            </span>
                            <div>
                                {modalData.history[index].lido === 1 &&
                                    <OverlayTrigger
                                        placement={"bottom"}
                                        overlay={<Tooltip>Lido</Tooltip>}
                                    >
                                        <FontAwesomeIcon className="vertically-centered px-1 primaryColorT" size={"lg"} icon={faSquareCheck} />
                                    </OverlayTrigger>
                                }
                                {modalData.history[index].aprovado > 0 &&
                                    <OverlayTrigger
                                        placement={"bottom"}
                                        overlay={<Tooltip>{modalData.history[index].aprovado === 1 ? 'Aprovado' : 'Reprovado'}</Tooltip>}
                                    >
                                        <FontAwesomeIcon className="vertically-centered px-1 primaryColorT" size={"lg"} icon={modalData.history[index].aprovado === 1 ? faThumbsUp : faThumbsDown} />
                                    </OverlayTrigger>
                                }
                                {appState.deleteHistory &&
                                    <OverlayTrigger
                                        placement={"bottom"}
                                        overlay={<Tooltip>Excluir</Tooltip>}
                                    >
                                        <FontAwesomeIcon className="vertically-centered cursor-pointer ps-1 primaryColorT" onClick={() => removeMessageDisplay(modalData.history[index].mensagem_id)} size={"lg"} icon={faSquareXmark} />
                                    </OverlayTrigger>
                                }
                            </div>
                        </div>
                        <p className="mb-0">Mensagem: <span className="opacity-50">{modalData.history[index].message}</span></p>
                    </div>
                </div>
            </li>
        )
    }

    return (
        <ul className="list-group text-start my-3">
            {modalData.history && modalData.history.length > 0 ? (
                <div>
                    {modalData.history && Object.keys(modalData.history).map(key => (
                        <ItemList key={'itemList' + key} index={key}></ItemList>
                    ))}
                </div>
            ) : (
                <p className="mt-5 text-center">Não foi encontrado nenhum resultado</p>
            )}
        </ul>
    );
}

export const toggleMessageStatus = (e, type, value, mensagem_id, setLoading) => {
    if (setLoading) {
        setLoading(true)
    }
    e.preventDefault();
    e.stopPropagation();
    let updateDataJson = {};
    updateDataJson[type] = value
    if (type === 'lido') {
        if (value === 1) {
            const dateX = new Date();
            const now = formatInTimeZone(dateX, "UTC", "yyyy-MM-dd HH:mm:ss");
            updateDataJson['data_lido'] = now
        } else {
            updateDataJson['data_lido'] = null
        }

    }
    axios.put(`/api/eventos/messagesDisplay/${mensagem_id}`, updateDataJson)
        .then(function (response) {
            // updateData()
        })
        .catch(function (error) {
            console.log(error);
        });
};