/* eslint-disable eqeqeq */
import React, {
    useEffect,
    useState,
    useContext,
    useRef
} from "react";
import {
    Modal,
    Button,
    Col,
    Breadcrumb,
    Card,
    Badge,
    Row,
    Form,
    Dropdown,
    Tooltip,
    OverlayTrigger,
    Tab,
    Nav
} from "react-bootstrap";
import { Formik } from "formik";
import axios from "axios";
import Loader from "../components/Loader.js";
import "react-confirm-alert/src/react-confirm-alert.css";
import { confirmAlert } from "react-confirm-alert";
import { ShowConfirm } from "../components/Alerts.js";
import InputMask from "react-input-mask";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFilter,
    faExclamationCircle,
    faPlus,
    faChartSimple,
    faSync
} from "@fortawesome/free-solid-svg-icons";
import PopoverPicker from "../components/PopoverPicker.js";
import DropzoneComponent from "../components/Dropzone.js";
import { domainConfig } from "../config.js";
import NotyfContext from "../contexts/NotyfContext";
import { ColumnSortingTable } from "../components/Table.js";
import useAppDispatch from "../hooks/useAppDispatch";
import useAuth from "../hooks/useAuth";
import useLayout from "../hooks/useLayout";
import {
    eventList,
    setDatetimeEvent,
    setStatusEvent,
    setDatetimeOrder
} from "../redux/slices/events";
import { ApplicationsList } from "../components/Applications.js";
import {
    convertDateToDisplay,
    generateSixDigitCode,
    HelpTooltip,
    safeJSONParse,
    handleFileUpload,
    textToArray
} from "../assets/functions.js";
import {
    schemaAddForm,
    schemaAddSocialMedia,
    schemaEventMode01,
    schemaEventMode02
} from "../components/Json.js";
import eventTypeImage01 from '../assets/img/illustrations/International trade-bro.png'
import eventTypeImage02 from '../assets/img/illustrations/Usability testing-bro.png'

export const eventX = ["", "Plataforma de aplicativos", "Plataforma de eventos"];
export const layoutX = ["Espaçado", "Centralizado"];
export const statusX = ["Rascunho", "Não iniciado", "Em andamento", "Parado", "Finalizado"];
export const colorsX = ["secondary", "warning", "primary", "danger", "dark"];
export const socialMediaX = ["facebook", "instagram", "youtube", "linkedin", "twitter", "website", "whatsapp"];

export const fieldsTypeX = [{
    type: 'input',
    validations: [{ label: "Texto", value: "text" }, { label: "Número", value: "number" }, { label: "E-mail", value: "email" }, { label: "Texto grande", value: "textBig" }, { label: "Celular", value: "telefone" }, { label: "CPF", value: 'cpf' }, { label: "Data", value: "date" }]
}, {
    type: 'select',
    validations: [{ label: "Texto", value: "text" }]
}, {
    type: 'checkbox',
    validations: [{ label: "Checkbox", value: "checkbox" }]
},
{
    type: 'textarea',
    validations: [{ label: "Textarea", value: "text" }]
}
];

export const tableColumns = [
    {
        Header: "Perfil",
        accessor: "imagem",
        disableSortBy: true,
        Filter: false,
        Cell: (row) => {
            return (
                <div>
                    <img
                        width="32px"
                        height="32px"
                        className="rounded-circle"
                        alt="Avatar"
                        src={
                            row.value !== "" && row.value !== null
                                ? domainConfig.imageServer + "/user/" + row.value
                                : domainConfig.imageServer + "/avatar.png"
                        }
                    />
                </div>
            );
        },
    },
    {
        Header: "Nome",
        accessor: "nome",
        Filter: false,
    },
    {
        Header: "Email",
        accessor: "email",
        Filter: false,
    },
    {
        Header: "Status",
        accessor: "statusString",
        Filter: false,
    },
    {
        Header: "",
        accessor: "icons",
        disableSortBy: true,
        Filter: false,
    },
];

export const FilterDate = () => {
    const dispatch = useAppDispatch();
    const [filtersOrder, setFilterOrder] = useState([
        { text: "Mais recentes", active: true },
        { text: "Mais antigos", active: false }
    ])
    const [filtersDate, setFiltersDate] = useState([
        { text: "Todos os eventos", active: true },
        { text: "Nos últimos 30 dias", active: false },
        { text: "Nos últimos 90 dias", active: false }
    ])

    function handleFilterOrder(filter) {
        const newFiltersOrder = filtersOrder.map((item, index) => {
            return { ...item, active: index === filter };
        });

        setFilterOrder(newFiltersOrder);
        dispatch(setDatetimeOrder(filter));
    }

    function handleFilterDate(filter) {
        const newFiltersDate = filtersDate.map((item, index) => {
            return { ...item, active: index === filter };
        });

        setFiltersDate(newFiltersDate);
        dispatch(setDatetimeEvent(filter));
    }

    return (
        <Dropdown className="d-inline me-2">
            <Dropdown.Toggle variant="light" className="bg-white shadow">
                {filtersDate.map((value, index) => {
                    if (value.active === true) {
                        return value.text;
                    } else {
                        return "";
                    }
                })}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {filtersDate.map((value, index) => {
                    return (
                        <Dropdown.Item
                            className={value.active ? "active" : ""}
                            key={"date" + index}
                            onClick={() => {
                                handleFilterDate(index);
                            }}
                        >
                            {value.text}
                        </Dropdown.Item>
                    );
                })}
                <Dropdown.Divider />
                {filtersOrder.map((value, index) => {
                    return (
                        <Dropdown.Item
                            className={value.active ? "active" : ""}
                            key={"order" + index}
                            onClick={() => {
                                handleFilterOrder(index);
                            }}
                        >
                            {value.text}
                        </Dropdown.Item>
                    );
                })}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export const FilterStatus = ({ selectEventsData }) => {
    const dispatch = useAppDispatch();

    function handleFilterStatus(status) {
        const currentStatus = selectEventsData.setStatusEvent.map((x) => {
            return { ...x };
        });
        const currentObject = currentStatus.filter((o) => {
            return o.value === status;
        });
        if (currentObject[0].isActive === true) {
            currentObject[0].isActive = false;
        } else {
            currentObject[0].isActive = true;
        }
        var array = [];
        currentStatus.forEach((element) => {
            if (element.isActive) {
                array.push(element.value);
            }
        });
        dispatch(setStatusEvent(currentStatus));
    }

    return (
        <Dropdown className="d-inline me-2">
            <Dropdown.Toggle variant="primary" className="shadow-sm">
                <FontAwesomeIcon
                    icon={faFilter}
                    className="align-middle me-1"
                    size="lg"
                />
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {selectEventsData.setStatusEvent.map((value, index) => {
                    return (
                        <Dropdown.Item
                            className={value.isActive ? "active" : ""}
                            key={index + "active"}
                            onClick={() => {
                                handleFilterStatus(value.value);
                            }}
                        >
                            {value.text}
                        </Dropdown.Item>
                    );
                })}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export function handlePreviewSuccess(filename, setFieldValue, field) {
    setFieldValue(field, filename);
}

export function updateEvent01(values, actions, dataForm, applications, addVisitantes, dataTable, setLoading, notyf, dispatch) {
    const updatedValues = JSON.parse(JSON.stringify(values));
    if (dataForm.fieldsToShow.length <= 0) {
        setLoading(false);
        actions.setSubmitting(false);
        notyf.open({
            type: "danger",
            message: `Identifique ao menos 1 dos campos dos formulário como "Campo de identificação"`,
            ripple: true,
            dismissible: true,
        });
        return; // Exit the function immediately
    }
    updatedValues.json.form.fields = dataForm.fields;
    updatedValues.json.form.fieldsToShow = dataForm.fieldsToShow;
    updatedValues.json.layout.footer.socialMedia = dataForm.socialMedia;
    console.log(updatedValues.json.form)
    setLoading(true);
    let formattedDataVisitantes = [];
    let ids = []
    let newObjectApplications = []
    console.log(applications)
    applications.forEach(element => {
        newObjectApplications.push({ aplicativo_id: element.aplicativo_id, customizacao_id: element.customizacao_id, version: element.version })
        ids.push(element.aplicativo_id)
    });
    const isValid = ids.every(id => id === 7 || id === 10);
    if (ids.length > 0 && isValid && updatedValues.json.form.status === true) {
        setLoading(false);
        actions.setSubmitting(false);
        notyf.open({
            type: "danger",
            message: "Os aplicativos selecionados não possui um modo para usuários. Desative a inscrição do evento para continuar",
            ripple: true,
            dismissible: true,
        });
        return
    }

    if (addVisitantes.length > 0) {
        for (const row of addVisitantes) {
            const rowObject = {};

            for (const key of Object.keys(row)) {
                // Generate a random key for each column
                let randomId = null;

                // Check if any field in the form has the label matching the key
                const matchedField = updatedValues.json.form.fields.find(field => field.label === key);

                if (matchedField) {
                    randomId = matchedField.inputID;
                    rowObject[randomId] = {
                        label: key,
                        value: row[key],
                    };
                } else {
                    // If no matched field is found, stop processing and show error notification
                    setLoading(false);
                    actions.setSubmitting(false);
                    notyf.open({
                        type: "danger",
                        message: "Os campos do formulário e as colunas dos participantes adicionados não coincidem",
                        ripple: true,
                        dismissible: true,
                    });
                    return; // Exit the function immediately
                }
            }

            formattedDataVisitantes.push(rowObject); // Only add the row if no error occurred
        }
    }

    axios.put(`/api/event/${dataTable.evento_id}`, {
        titulo: updatedValues.titulo,
        descricao: updatedValues.descricao,
        status: values.status == 0 ? 1 : values.status,
        json: JSON.stringify(updatedValues.json),
        data_termino: values.data_termino,
        platform: 1,
        applications: JSON.stringify(newObjectApplications),
        addVisitantes: JSON.stringify(formattedDataVisitantes)
    })
        .then(function (response) {
            if (response.status === 201) {
                notyf.open({
                    type: "success",
                    message: "Evento salvo com sucesso",
                    ripple: true,
                    dismissible: true,
                });
            } else {
                actions.setSubmitting(false)
                notyf.open({
                    type: "danger",
                    message: "Houve um erro, tente novamente",
                    ripple: true,
                    dismissible: true,
                });
            }
            dispatch(eventList());
            setLoading(false)
        })
        .catch(function (error) {
            console.log(error)
            setLoading(false)
            actions.setSubmitting(false)
            notyf.open({
                type: "danger",
                message: "Houve um erro, tente novamente",
                ripple: true,
                dismissible: true,
            });
        });
}

export function updateEvent02(values, actions, dataForm, addVisitantes, dataTable, setLoading, notyf, dispatch) {
    notyf.open({
        type: "danger",
        message: `Função indisponível`,
        ripple: true,
        dismissible: true,
    });
    return;

    // Expressão regular para validar e-mail
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const updatedValues = JSON.parse(JSON.stringify(values));
    if (dataForm.fieldsToShow.length <= 0) {
        setLoading(false);
        actions.setSubmitting(false);
        notyf.open({
            type: "danger",
            message: `Identifique ao menos 1 dos campos dos formulário como "Campo de identificação"`,
            ripple: true,
            dismissible: true,
        });
        return; // Exit the function immediately
    }
    updatedValues.json.form.fields = dataForm.fields;
    updatedValues.json.form.fieldsToShow = dataForm.fieldsToShow;
    updatedValues.json.layout.footer.socialMedia = dataForm.socialMedia;

    setLoading(true);
    let formattedDataVisitantes = [];
    let formattedDataUsuarios = [];
    console.log(updatedValues)
    let formattedEmails = updatedValues.emails !== '' ? textToArray(updatedValues.emails) : []

    if (formattedEmails.length > 0) {
        for (const email of formattedEmails) {
            if (!emailRegex.test(email)) {
                // Verifica se o e-mail tem um formato válido
                setLoading(false);
                actions.setSubmitting(false);
                notyf.open({
                    type: "danger",
                    message: `Usuário com e email inválido: ${email}`,
                    ripple: true,
                    dismissible: true,
                });
                return; // Sai da função se o email estiver em formato inválido
            }
            formattedDataUsuarios.push(email); // Adiciona a linha ao resultado
        }
    }
    console.log(formattedDataUsuarios)

    if (addVisitantes.length > 0) {

        for (const row of addVisitantes) {
            const rowObject = {};
            // Verifica se o "email" está presente e válido no row
            if (!row["email"] || row["email"].trim() === "") {
                setLoading(false);
                actions.setSubmitting(false);
                notyf.open({
                    type: "danger",
                    message: "A coluna 'email' é obrigatório.",
                    ripple: true,
                    dismissible: true,
                });
                return; // Sai da função se o email estiver ausente ou vazio
            } else if (!emailRegex.test(row["email"])) {
                // Verifica se o e-mail tem um formato válido
                setLoading(false);
                actions.setSubmitting(false);
                notyf.open({
                    type: "danger",
                    message: `Participante com email inválido: ${row["email"]}`,
                    ripple: true,
                    dismissible: true,
                });
                return; // Sai da função se o email estiver em formato inválido
            }

            // Processa todos os outros campos e os relaciona com os campos do formulário
            for (const key of Object.keys(row)) {
                // Ignora a verificação de "email" com os campos do formulário, pois é processado separadamente
                if (key === "email") {
                    rowObject["email"] = {
                        label: "email",
                        value: row[key],
                    };
                    continue; // Pula para a próxima iteração
                }

                // Encontra o campo correspondente no formulário para as outras chaves
                const matchedField = updatedValues.json.form.fields.find(field => field.label === key);

                if (matchedField) {
                    const inputID = matchedField.inputID;
                    rowObject[inputID] = {
                        label: key,
                        value: row[key],
                    };
                } else {
                    // Erro: Nenhum campo correspondente encontrado
                    setLoading(false);
                    actions.setSubmitting(false);
                    notyf.open({
                        type: "danger",
                        message: `O campo '${key}' não corresponde a nenhuma coluna do formulário.`,
                        ripple: true,
                        dismissible: true,
                    });
                    return; // Sai da função imediatamente
                }
            }

            formattedDataVisitantes.push(rowObject); // Adiciona a linha ao resultado
        }
    }

    axios.put(`/api/event/${dataTable.evento_id}`, {
        title: updatedValues.title,
        description: updatedValues.description,
        status: values.status == 0 ? 1 : values.status,
        json: JSON.stringify(updatedValues.json),
        end_date: values.end_date,
        platform: 2,
        addVisitantes: JSON.stringify(formattedDataVisitantes),
        addUsers: JSON.stringify(formattedDataUsuarios)
    })
        .then(function (response) {
            if (response.status === 201) {
                notyf.open({
                    type: "success",
                    message: "Evento salvo com sucesso",
                    ripple: true,
                    dismissible: true,
                });
            } else {
                actions.setSubmitting(false)
                notyf.open({
                    type: "danger",
                    message: "Houve um erro, tente novamente",
                    ripple: true,
                    dismissible: true,
                });
            }
            dispatch(eventList());
            setLoading(false)
        })
        .catch(function (error) {
            console.log(error)
            setLoading(false)
            actions.setSubmitting(false)
            notyf.open({
                type: "danger",
                message: "Houve um erro, tente novamente",
                ripple: true,
                dismissible: true,
            });
        });
}

export const FormType = ({
    platform,
    dataAddForm,
    setDataAddForm,
    modalForm,
    setModalForm,
    showModalForm,
    setShowModalForm
}) => {
    function addForm(values, actions) {
        const newObject = values
        // eslint-disable-next-line eqeqeq
        if (values.fieldToShow) {
            addFieldToShow(values.inputID)
        } else {
            removeFieldToShow(values.inputID)
        }
        newObject.options = newObject.options != '' ? newObject.options : []
        setDataAddForm((prevDataAddForm) => {
            const indexToUpdate = prevDataAddForm.fields.findIndex(field => field.inputID === values.inputID);
            let newDataAddForm = [...prevDataAddForm.fields];

            if (indexToUpdate !== -1) {
                newDataAddForm[indexToUpdate] = newObject;
            } else {
                newDataAddForm = [...newDataAddForm, newObject];
            }
            return {
                ...prevDataAddForm,
                fields: newDataAddForm
            }
        });
        setShowModalForm(false);
    }

    function removeFieldForm(inputID) {
        setDataAddForm((prevDataAddForm) => {
            const indexToRemove = prevDataAddForm.fields.findIndex(field => field.inputID === inputID);
            const newDataAddForm = [...prevDataAddForm.fields];
            newDataAddForm.splice(indexToRemove, 1);
            return {
                ...prevDataAddForm,
                fields: newDataAddForm
            }
        });
    }

    const renderValidations = (type) => {
        const index = fieldsTypeX.findIndex(field => field.type === type);
        return fieldsTypeX[index].validations.map((element, index) => (
            <option key={"validationfieldType" + index} value={element.value}>
                {element.label}
            </option>
        ));
    };

    const addFieldToShow = (inputID) => {
        setDataAddForm((prevDataAddForm) => {
            const indexToUpdate = prevDataAddForm.fieldsToShow.findIndex(field => field === inputID);
            let newDataAddForm = [...prevDataAddForm.fieldsToShow];

            if (indexToUpdate === -1) {
                newDataAddForm = [...newDataAddForm, inputID];
            }
            return {
                ...prevDataAddForm,
                fieldsToShow: newDataAddForm
            }
        });
    };

    const removeFieldToShow = (inputID) => {
        setDataAddForm((prevDataAddForm) => {
            const indexToRemove = prevDataAddForm.fieldsToShow.findIndex(field => field.inputID === inputID);
            const newDataAddForm = [...prevDataAddForm.fieldsToShow];
            newDataAddForm.splice(indexToRemove, 1);
            return {
                ...prevDataAddForm,
                fieldsToShow: newDataAddForm
            }
        });
    };

    return (
        <>
            <Modal
                show={showModalForm}
                size={"sm"}
                onHide={(e) => { setShowModalForm(false); setModalForm(false) }}
                centered
                backdrop
            >
                <Formik
                    validationSchema={() => schemaAddForm()}
                    enableReinitialize
                    onSubmit={addForm}
                    initialValues={{
                        inputID: modalForm.inputID !== undefined ? modalForm.inputID : generateSixDigitCode(),
                        label: modalForm.label !== undefined ? modalForm.label : '',
                        subLabel: modalForm.subLabel !== undefined ? modalForm.subLabel : '',
                        type: modalForm.type !== undefined ? modalForm.type : fieldsTypeX[0].type,
                        validation: modalForm.validation !== undefined ? modalForm.validation : fieldsTypeX[0].validations[0].value,
                        defaultValue: modalForm.defaultValue !== undefined ? modalForm.defaultValue : '',
                        placeholder: modalForm.placeholder !== undefined ? modalForm.placeholder : '',
                        col: modalForm.col !== undefined ? modalForm.col : 12,
                        primary: modalForm.primary !== undefined ? modalForm.primary : false,
                        options: modalForm.options !== undefined ? modalForm.options : [],
                        required: modalForm.required !== undefined ? modalForm.required : true,
                        allowMultipleChoice: modalForm.allowMultipleChoice !== undefined ? modalForm.allowMultipleChoice : false,
                        allowMultipleChoiceMax: modalForm.allowMultipleChoiceMax !== undefined ? modalForm.allowMultipleChoiceMax : 1,
                        allowMultipleChoiceMin: modalForm.allowMultipleChoiceMin !== undefined ? modalForm.allowMultipleChoiceMin : 1,
                        dependencies: modalForm.dependencies !== undefined && !Array.isArray(modalForm.dependencies) ? modalForm.dependencies : { inputID: '', value: '' },
                        displayField: modalForm.displayField !== undefined ? modalForm.displayField : true,
                        fieldToShow: modalForm.fieldToShow !== undefined ? modalForm.fieldToShow : false,
                    }}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        values,
                        touched,
                        isValid,
                        errors,
                        dirty,
                        isSubmitting,
                        setFieldValue,
                    }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Modal.Header closeButton>
                                <Modal.Title>{modalForm ? 'Editar Campo' : 'Adicionar campo'}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Row className="mb-3">
                                    <Form.Group
                                        className="mb-3"
                                        as={Col}
                                        sm={"12"}
                                        controlId="validationFormik01"
                                    >
                                        <Form.Label>Campo</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="label"
                                            value={values.label}
                                            onChange={handleChange}
                                            isInvalid={!!errors.label}
                                            isValid={touched.label && !errors.label}
                                        />
                                        <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.label}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group
                                        className="mb-3"
                                        as={Col}
                                        sm={"12"}
                                        controlId="validationFormik01"
                                    >
                                        <Form.Label>Subtítulo</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="subLabel"
                                            value={values.subLabel}
                                            onChange={handleChange}
                                            isInvalid={!!errors.subLabel}
                                            isValid={touched.subLabel && !errors.subLabel}
                                        />
                                        <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.subLabel}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group
                                        as={Col}
                                        className={"mb-3"}
                                        sm={"6"}
                                        controlId="validationFormik03">
                                        <Form.Label>Tipo</Form.Label>
                                        <Form.Select
                                            name="type"
                                            value={values.type}
                                            onChange={(e) => {
                                                const selectedElement = fieldsTypeX.find(field => field.type == e.target.value);
                                                if (selectedElement.type === 'select' || selectedElement.type === 'input') {
                                                    setFieldValue('validation', 'text');
                                                } else if (selectedElement.type === 'checkbox') {
                                                    setFieldValue('validation', 'checkbox');
                                                } else if (selectedElement.type === 'textarea') {
                                                    setFieldValue('validation', 'textarea');
                                                }
                                                setFieldValue('type', selectedElement.type);
                                            }}
                                            isInvalid={!!errors.type}
                                            isValid={touched.type && !errors.type}
                                        >
                                            {fieldsTypeX.map((element, index) => {
                                                const capitalizeFirstLetter = ([
                                                    firstLetter,
                                                    ...rest
                                                ]) => firstLetter.toUpperCase() + rest.join("");
                                                const capitalizedString =
                                                    capitalizeFirstLetter(element.type);

                                                return (
                                                    <option key={"fieldType" + index} value={element.type}>
                                                        {capitalizedString}
                                                    </option>
                                                );
                                            })}
                                        </Form.Select>
                                        <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.type}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    {values.type === 'input' && <Form.Group
                                        as={Col}
                                        className={"mb-3"}
                                        sm={"6"}
                                        controlId="validationFormik03">
                                        <Form.Label>Validação</Form.Label>
                                        <Form.Select
                                            name="validation"
                                            value={values.validation}
                                            onChange={handleChange}
                                            isInvalid={!!errors.validation}
                                            isValid={touched.validation && !errors.validation}>
                                            {renderValidations(values.type)}
                                        </Form.Select>
                                        <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.validation}
                                        </Form.Control.Feedback>
                                    </Form.Group>}

                                    {(values.type === 'select' || values.type === 'checkbox') && (
                                        <Form.Group
                                            className="mb-3"
                                            as={Col}
                                            sm={"12"}
                                            controlId="validationFormik01">
                                            <Form.Label>Opções
                                            </Form.Label>
                                            <OverlayTrigger
                                                overlay={<Tooltip>Separe as opções por linha.{values.type === 'checkbox' ? ' Se não houver opções o checkbox será único' : ''}</Tooltip>}
                                            >
                                                <HelpTooltip icon={faExclamationCircle} />
                                            </OverlayTrigger>
                                            <Form.Control
                                                style={{ height: "75px" }}
                                                as={'textarea'}
                                                name="options"
                                                value={values.options.join('\n')}
                                                onChange={(e) => {
                                                    const updatedOptions = e.target.value.split('\n');
                                                    setFieldValue('options', updatedOptions);
                                                }}
                                                isInvalid={!!errors.options}
                                                isValid={touched.options && !errors.options}
                                            />
                                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.options}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    )}

                                    {(values.type === 'input' || values.type === 'select') && <Form.Group
                                        className="mb-3"
                                        as={Col}
                                        sm={"12"}
                                        controlId="validationFormik01">
                                        <Form.Label>Valor padrão</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="defaultValue"
                                            value={values.defaultValue}
                                            onChange={handleChange}
                                            isInvalid={!!errors.defaultValue}
                                            isValid={touched.defaultValue && !errors.defaultValue}
                                        />
                                        <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.defaultValue}
                                        </Form.Control.Feedback>
                                    </Form.Group>}

                                    {values.type === 'input' && <Form.Group
                                        className="mb-3"
                                        as={Col}
                                        sm={"12"}
                                        controlId="validationFormik01">
                                        <Form.Label>Placeholder</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="placeholder"
                                            value={values.placeholder}
                                            onChange={handleChange}
                                            isInvalid={!!errors.placeholder}
                                            isValid={touched.placeholder && !errors.placeholder}
                                        />
                                        <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.placeholder}
                                        </Form.Control.Feedback>
                                    </Form.Group>}

                                    {values.type === 'checkbox' && values.options.length >= 2 && <Form.Group
                                        className={values.allowMultipleChoice ? "" : "mb-3"}
                                        as={Col}
                                        sm={"12"}
                                        controlId="validationFormik01">
                                        <Form.Check
                                            checked={values.allowMultipleChoice ? true : false}
                                            type="checkbox"
                                            name="allowMultipleChoice"
                                            label="Múltipla escolha"
                                            value={values.allowMultipleChoice}
                                            onChange={(e) => {
                                                setFieldValue(
                                                    "allowMultipleChoice",
                                                    values.allowMultipleChoice ? false : true
                                                );
                                            }}
                                        />
                                    </Form.Group>}
                                    {values.allowMultipleChoice === true &&
                                        <>
                                            <Form.Group
                                                className="mb-3"
                                                as={Col}
                                                sm={"6"}
                                                controlId="validationFormik01">
                                                <Form.Label>Mínimo</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    name="allowMultipleChoiceMin"
                                                    value={values.allowMultipleChoiceMin}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.allowMultipleChoiceMin}
                                                    isValid={touched.allowMultipleChoiceMin && !errors.allowMultipleChoiceMin}
                                                />
                                                <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.allowMultipleChoiceMin}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group
                                                className="mb-3"
                                                as={Col}
                                                sm={"6"}
                                                controlId="validationFormik01">
                                                <Form.Label>Máximo</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    name="allowMultipleChoiceMax"
                                                    value={values.allowMultipleChoiceMax}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.allowMultipleChoiceMax}
                                                    isValid={touched.allowMultipleChoiceMax && !errors.allowMultipleChoiceMax}
                                                />
                                                <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.allowMultipleChoiceMax}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </>}
                                    {(values.type === 'input' || values.type === 'select') && <Form.Group
                                        as={Col}
                                        className={"mb-3"}
                                        sm={"12"}
                                        controlId="validationFormik03">
                                        <Form.Label>Tamanho do campo <OverlayTrigger
                                            overlay={<Tooltip>Valor de 12 ocupa 100% do espaço, enquanto o valor de 4 ocupa 33,33%</Tooltip>}
                                        >
                                            <HelpTooltip icon={faExclamationCircle} />
                                        </OverlayTrigger></Form.Label>
                                        <Form.Select
                                            name="col"
                                            value={values.col}
                                            onChange={(e) => {
                                                setFieldValue('col', parseInt(e.target.value));
                                            }}
                                            isInvalid={!!errors.col}
                                            isValid={touched.col && !errors.col}>
                                            {[4, 6, 8, 12].map((element, index) => {
                                                return (
                                                    <option key={"colField" + index} value={element}>
                                                        {element}
                                                    </option>
                                                );
                                            })}
                                        </Form.Select>
                                        <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.col}
                                        </Form.Control.Feedback>
                                    </Form.Group>}
                                    <Form.Group
                                        as={Col}
                                        md="12"
                                        className={"mb-3"}
                                        controlId="validationFormik03"
                                    >
                                        <Form.Label>Dependências <OverlayTrigger
                                            overlay={<Tooltip>Caso você selecione uma dependência, o campo atualmente em criação apenas aparecerá para o usuário caso o valor do campo de dependência seja igual</Tooltip>}
                                        >
                                            <HelpTooltip icon={faExclamationCircle} />
                                        </OverlayTrigger></Form.Label>
                                        <Form.Select
                                            name="dependencies.inputID"
                                            onChange={handleChange}
                                            value={values.dependencies?.inputID}
                                            isInvalid={!!errors.dependencies?.inputID}
                                            isValid={touched.inputID && !errors.inputID}>
                                            <option value={''}>Sem depêndencias</option>
                                            {dataAddForm.fields.map((element, index) => {
                                                return (
                                                    <option key={"dependenceSelect" + index} value={element.inputID}>
                                                        {element.label}
                                                    </option>
                                                );
                                            })}
                                        </Form.Select>
                                        <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">{errors.dependencies?.inputID}</Form.Control.Feedback>
                                    </Form.Group>
                                    {values.dependencies && values.dependencies.inputID !== '' &&
                                        <Form.Group
                                            className="mb-3"
                                            as={Col}
                                            sm={"12"}
                                            controlId="validationFormik01">
                                            <Form.Label>Valor</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="dependencies.value"
                                                value={values.dependencies?.value}
                                                onChange={handleChange}
                                                isInvalid={!!errors.dependencies?.value}
                                                isValid={touched.dependencies?.value && !errors.dependencies?.value}
                                            />
                                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.dependencies?.value}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    }

                                    <Form.Group>
                                        {platform === 1 && <Form.Check
                                            checked={values.primary ? true : false}
                                            type="checkbox"
                                            name="primary"
                                            label={
                                                <>
                                                    Campo primário
                                                    <OverlayTrigger
                                                        overlay={<Tooltip>Verifica se o valor existe no banco de dados, impedindo valores duplicados</Tooltip>}
                                                    >
                                                        <HelpTooltip icon={faExclamationCircle} />
                                                    </OverlayTrigger>
                                                </>
                                            }
                                            onChange={(e) => {
                                                setFieldValue(
                                                    "primary",
                                                    values.primary ? false : true
                                                );
                                            }}
                                        />}
                                        <Form.Check
                                            checked={values.fieldToShow ? true : false}
                                            type="checkbox"
                                            name="fieldToShow"
                                            label={
                                                <>
                                                    Campo de identificação
                                                    <OverlayTrigger
                                                        overlay={<Tooltip>Quando necessário identificar o participante, esse campo será escolhido</Tooltip>}
                                                    >
                                                        <HelpTooltip icon={faExclamationCircle} />
                                                    </OverlayTrigger>
                                                </>
                                            }
                                            onChange={(e) => {
                                                setFieldValue(
                                                    "fieldToShow",
                                                    values.fieldToShow ? false : true
                                                );
                                            }}
                                            isInvalid={!!errors.fieldToShow}
                                            feedback={errors.fieldToShow}
                                            feedbackType="invalid"
                                        />
                                        <Form.Check
                                            checked={values.required ? true : false}
                                            type="checkbox"
                                            name="required"
                                            label="Preenchimento obrigatório"
                                            onChange={(e) => {
                                                setFieldValue(
                                                    "required",
                                                    values.required ? false : true
                                                );
                                            }}
                                        />
                                        {platform === 2 &&
                                            <Form.Check
                                                checked={values.displayField ? true : false}
                                                type="checkbox"
                                                name="displayField"
                                                label="Esconder campo no perfil do participante"
                                                onChange={(e) => {
                                                    setFieldValue(
                                                        "displayField",
                                                        values.displayField ? false : true
                                                    );
                                                }}
                                            />}
                                    </Form.Group>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                {modalForm ? <Button
                                    variant="danger"
                                    type="button"
                                    className="float-end me-2"
                                    onClick={() => {
                                        removeFieldForm(values.inputID)
                                        setShowModalForm(false)
                                    }}
                                >
                                    Excluir
                                </Button> : ''}
                                <Button
                                    variant="primary"
                                    type="submit"
                                    className="float-end"
                                    disabled={isSubmitting}
                                >
                                    Salvar
                                </Button>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </>
    );
};

export const EventType = ({
    createEvent,
    showModalEventType,
    setShowModalEventType,
}) => {
    const userState = useAuth();

    return (
        <>
            <Modal
                show={showModalEventType}
                size={"md"}
                onHide={setShowModalEventType}
                centered
                backdrop>
                <Modal.Header closeButton>
                    <Modal.Title>Novo evento</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <div className="row mx-0">
                            {userState.plataforma_aplicativos === 1 && <div
                                className="gameTypeMode col-md-12 my-2"
                                onClick={(e) => {
                                    createEvent(1);
                                    setShowModalEventType(false);
                                }}
                            >
                                <div className="gameTypeModeIcon">
                                    <div className="text-primary bg-light">
                                        <img src={eventTypeImage01} alt="event image1"></img>
                                    </div>
                                </div>
                                <div className="gameTypeModeLabel">
                                    <h5>{eventX[0]}</h5>
                                    <span className="mb-0">
                                        Esse modelo tem como base a coleta de lead e você pode adicionar aplicações para os participantes utilizarem
                                    </span>
                                </div>
                            </div>}
                            {userState.plataforma_eventos === 1 && <div
                                className="gameTypeMode col-md-12 my-2"
                                onClick={(e) => {
                                    createEvent(2);
                                    setShowModalEventType(false);
                                }}
                            >
                                <div className="gameTypeModeIcon">
                                    <div className="text-primary bg-light">
                                        <img src={eventTypeImage02} alt="event image2"></img>
                                    </div>
                                </div>
                                <div className="gameTypeModeLabel">
                                    <h5>{eventX[1]}</h5>
                                    <span className="mb-0">
                                        Aproveite os benefícios de uma rede social exclusiva para
                                        que os participantes do seu evento se conectem com mais
                                        facilidade, além de vários outros recursos interativos
                                    </span>
                                </div>
                            </div>}
                        </div>
                    </Form.Group>
                </Modal.Body>
            </Modal>
        </>
    );
};

export const SocialMediaType = ({
    dataAddForm,
    setDataAddForm,
    modalForm,
    setModalForm,
    showSocialMediaForm,
    setShowSocialMediaForm,
}) => {

    const addSocialMedia = (values, actions) => {
        const newObject = values
        setDataAddForm((prevDataAddForm) => {
            const indexToUpdate = prevDataAddForm.socialMedia.findIndex(field => field.socialMediaID === values.socialMediaID);
            let newDataAddForm = [...prevDataAddForm.socialMedia];

            if (indexToUpdate !== -1) {
                newDataAddForm[indexToUpdate] = newObject;
            } else {
                newDataAddForm = [...newDataAddForm, newObject];
            }
            return {
                ...prevDataAddForm,
                socialMedia: newDataAddForm
            }
        });
        setModalForm(false)
        setShowSocialMediaForm(false)
    };

    const removeSocialMedia = (socialMediaID) => {
        setDataAddForm((prevDataAddForm) => {
            const indexToRemove = prevDataAddForm.socialMedia.findIndex(field => field.socialMediaID === socialMediaID);
            const newDataAddForm = [...prevDataAddForm.socialMedia];
            newDataAddForm.splice(indexToRemove, 1);
            return {
                ...prevDataAddForm,
                socialMedia: newDataAddForm
            }
        });
    }

    return (
        <>
            <Modal
                show={showSocialMediaForm}
                size={"sm"}
                onHide={(e) => { setShowSocialMediaForm(false); setModalForm(false) }}
                centered
                backdrop
            >
                <Formik
                    validationSchema={schemaAddSocialMedia}
                    enableReinitialize
                    onSubmit={addSocialMedia}
                    initialValues={{
                        socialMediaID: modalForm.socialMediaID !== undefined ? modalForm.socialMediaID : generateSixDigitCode(),
                        name: modalForm.name !== undefined ? modalForm.name : socialMediaX[0],
                        url: modalForm.url !== undefined ? modalForm.url : "",
                    }}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        values,
                        touched,
                        isValid,
                        errors,
                        dirty,
                        isSubmitting,
                        setFieldValue,
                    }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Modal.Header closeButton>
                                <Modal.Title>{modalForm ? 'Editar ícone' : 'Adicionar ícone'}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Row className="mb-3">
                                    <Form.Group
                                        as={Col}
                                        className={"mb-3"}
                                        sm={"12"}
                                        controlId="validationFormik03">
                                        <Form.Label>Escolha uma rede social</Form.Label>
                                        <Form.Select
                                            name="name"
                                            value={values.name}
                                            onChange={(e) => {
                                                setFieldValue('name', e.target.value);
                                            }}
                                            isInvalid={!!errors.name}
                                            isValid={touched.name && !errors.name}>
                                            {socialMediaX.map((element, index) => {
                                                const capitalizeFirstLetter = ([
                                                    firstLetter,
                                                    ...rest
                                                ]) => firstLetter.toUpperCase() + rest.join("");
                                                const capitalizedString =
                                                    capitalizeFirstLetter(element);

                                                return (
                                                    <option key={"fieldType" + index} value={element}>
                                                        {capitalizedString}
                                                    </option>
                                                );
                                            })}
                                        </Form.Select>
                                        <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.name}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group
                                        as={Col}
                                        sm={"12"}
                                        controlId="validationFormik01"
                                    >
                                        <Form.Label>URL</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="url"
                                            value={values.url}
                                            onChange={handleChange}
                                            isInvalid={!!errors.url}
                                            isValid={touched.url && !errors.url}
                                        />
                                        <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.url}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                {modalForm ? <Button
                                    variant="danger"
                                    type="button"
                                    className="float-end me-2"
                                    onClick={() => {
                                        removeSocialMedia(values.socialMediaID)
                                        setShowSocialMediaForm(false)
                                    }}
                                >
                                    Excluir
                                </Button> : ''}
                                <Button
                                    variant="primary"
                                    type="submit"
                                    className="float-end"
                                    disabled={isSubmitting}
                                >
                                    Salvar
                                </Button>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </>
    );
};

export const AplicativosType = ({
    dataTable,
    setApplications,
    dataAplicativo,
    setDataAplicativo,
    showModalAplicativos,
    setShowModalAplicativos,
    aplicativos,
}) => {

    function addCustomization(values) {
        let newObject = {
            customizacao_id: values.customizacao_id,
            aplicativo_id: values.aplicativo_id,
            titulo: values.titulo,
            version: values.version
        };
        setApplications((prevData) => {
            newObject.nome = dataAplicativo.nome
            let newData = [...prevData, newObject];
            return newData;
        });
        setShowModalAplicativos(false);
    }

    if (!dataAplicativo) {
        return (
            <Modal
                show={showModalAplicativos}
                size={"lg"}
                onHide={setShowModalAplicativos}
                centered
                backdrop
                scrollable
            >
                <Modal.Header closeButton>Selecione um aplicativo</Modal.Header>
                <Modal.Body>
                    <ApplicationsList aplicativos={aplicativos} setDataAplicativo={setDataAplicativo} addCustomization={addCustomization} />
                </Modal.Body>
            </Modal>
        )
    } else {
        return (
            <Modal
                show={showModalAplicativos}
                size={"lg"}
                onHide={setShowModalAplicativos}
                centered
                backdrop
                scrollable
            >
                <Modal.Header closeButton>Escolha uma customização</Modal.Header>
                <Modal.Body>
                    <CustomizationsList dataAplicativo={dataAplicativo} addCustomization={addCustomization}></CustomizationsList>
                </Modal.Body>
            </Modal>
        )
    }
};

export const CustomizationsList = ({ dataAplicativo, addCustomization }) => {
    const [customizations, setCustomizations] = useState(false)
    const userState = useAuth();

    useEffect(() => {
        axios
            .get(`/api/customization?user_id=${userState.user_id}&aplicativo_id=${dataAplicativo.aplicativo_id}&rascunho=false`)
            .then(function (response) {
                var data = response.data.message
                data.forEach(function callback(value, index) {
                    value.json = safeJSONParse(value.json);
                    value.data_edicao = convertDateToDisplay(
                        value.data_edicao,
                        true
                    );
                });
                setCustomizations(data);
            })
            .catch(function (error) {
                console.log(error);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!customizations) {
        return (
            <div className="py-5">
                <Loader></Loader>
            </div>
        )
    }

    return (
        <div className="list-group eventoApplicationsList">
            {customizations.length > 0 ? (
                customizations.map((value, index) => {
                    return (
                        <div
                            key={"gameMode" + index}
                            className="list-group-item list-group-item-action mb-2"
                            aria-current="true"
                            onClick={() => addCustomization(value)}
                        >
                            <div className="d-flex w-100 justify-content-between">
                                <h5 className="mb-1">
                                    {" "}
                                    {value.titulo === "" ? (
                                        <span className="text-muted">Sem título</span>
                                    ) : (
                                        value.titulo
                                    )}
                                </h5>
                                <small>{value.data_criacao}</small>
                            </div>
                            {value.titulo === "" && (
                                <Badge bg={"light"} className="text-primary me-2">
                                    Rascunho
                                </Badge>
                            )}
                            <Badge bg={"light"} className="text-primary me-2">v.{value.version}</Badge>
                        </div>
                    );
                })
            ) : (
                <h5 className="py-5 text-center opacity-50">
                    Nenhuma customização encontrada
                </h5>
            )}
        </div>
    );
};

export const FormComponentMode01 = ({ fonts, dataTable, setShowHistory, dataAddForm, setDataAddForm, applications, setApplications, setDataAplicativo, setModalForm, setShowModalAplicativos, setShowModalForm, setShowSocialMediaForm }) => {
    const [props, setProps] = useState({
        primaryColor: dataTable.json.primaryColor,
        primaryColorText: dataTable.json.primaryColorText,
        backgroundColor: dataTable.json.background.color,
        baseColorText: dataTable.json.baseColorText,
        transparency: dataTable.json.transparency,
        boxShadow: dataTable.json.boxShadow,
        bsBorderColor: dataTable.json.bsBorderColor,
    });
    const dispatch = useAppDispatch();
    const notyf = useContext(NotyfContext);
    const { setLoading } = useLayout();
    const [addVisitantes, setAddVisitantes] = useState({ visitantes: [], error: false, fields: [] });
    const addVisitantesInput = useRef();

    function getFirstError(...errors) {
        return errors.find(error => error !== undefined && error !== null) || '';
    }

    function handlePreviewError(error, notyf) {
        notyf.open({
            type: "danger",
            message: error,
            ripple: true,
            dismissible: true,
        });
    }

    return (
        <Formik
            validationSchema={schemaEventMode01}
            enableReinitialize
            onSubmit={(values, actions) => updateEvent01(values, actions, dataAddForm, applications, addVisitantes.visitantes, dataTable, setLoading, notyf, dispatch)}
            initialValues={{
                evento_id: dataTable.evento_id,
                user_id: dataTable.user_id,
                titulo: dataTable.titulo,
                descricao: dataTable.descricao,
                json: dataTable.json,
                status: dataTable.status,
                data_criacao: dataTable.data_criacao,
                data_termino: dataTable.data_termino,
                token: dataTable.token,
                codigo_presenter: dataTable.codigo_presenter,
            }}
        >
            {({
                handleSubmit,
                handleChange,
                values,
                touched,
                isValid,
                errors,
                dirty,
                isSubmitting,
                setFieldValue,
            }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <Button
                        variant="primary"
                        className="float-end"
                        onClick={() => {
                            setShowHistory(true)
                        }}
                    >
                        <FontAwesomeIcon icon={faChartSimple} className="me-2" />Relatórios
                    </Button>
                    <Button
                        variant="primary"
                        className="float-end me-2"
                        onClick={() => {
                            dispatch(eventList());
                        }}
                    >
                        Voltar
                    </Button>
                    <Breadcrumb>
                        <Breadcrumb.Item
                            onClick={() => {
                                dispatch(eventList());
                            }}
                        >
                            Eventos
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>
                            {values.status > 0 ? "Editar evento" : "Novo evento"}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <h6 className="text-muted mb-3">
                        Configure seu evento, desenvolva sua identidade visual e adicione aplicativos.
                    </h6>
                    <Form.Group as={Col} className="textHeaderAplicativo mb-3">
                        <h5 className="card-title mb-0">Definições</h5>
                    </Form.Group>
                    <Row className="mx-0">
                        <Form.Control type="hidden" name="status" value={true} />
                        <Form.Control
                            type="hidden"
                            name="evento_id"
                            value={values.evento_id}
                        />
                        <Form.Group
                            className="mb-3"
                            as={Col}
                            sm="12"
                            controlId="validationFormik01"
                        >
                            <Form.Label>Título</Form.Label>
                            <Form.Control
                                type="text"
                                name="titulo"
                                value={values.titulo}
                                onChange={handleChange}
                                isInvalid={!!errors.titulo}
                                isValid={touched.titulo && !errors.titulo}
                            />
                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                                {errors.titulo}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                            className="mb-3"
                            as={Col}
                            sm="12"
                            controlId="validationFormik02"
                        >
                            <Form.Label>Descrição</Form.Label>
                            <Form.Control
                                name="descricao"
                                as="textarea"
                                value={values.descricao}
                                onChange={handleChange}
                                isInvalid={!!errors.descricao}
                                isValid={touched.descricao && !errors.descricao}
                            />
                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">{errors.descricao}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            as={Col}
                            sm="3"
                            controlId="validationFormik02"
                        >
                            <Form.Label>Plataforma</Form.Label>
                            <Form.Control
                                name="platform"
                                type="text"
                                disabled
                                value={eventX[dataTable.platform]}
                            />
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            as={Col}
                            sm="3"
                            controlId="validationFormik02"
                        >
                            <Form.Label>ID interno</Form.Label>
                            <Form.Control
                                name="evento_id"
                                type="text"
                                disabled
                                value={values.evento_id}
                            />
                        </Form.Group>

                        <Form.Group
                            className="mb-3"
                            as={Col}
                            sm="3"
                            controlId="validationFormik02"
                        >
                            <Form.Label>Token</Form.Label>
                            <Form.Control
                                name="token"
                                type="text"
                                disabled
                                value={values.token}
                            />
                        </Form.Group>

                        <Form.Group
                            className="mb-3"
                            as={Col}
                            sm="3"
                            controlId="validationFormik02"
                        >
                            <Form.Label>Modo apresentador</Form.Label>
                            <Form.Control
                                name="codigo_presenter"
                                type="text"
                                disabled
                                value={values.codigo_presenter}
                            />
                        </Form.Group>
                    </Row>
                    <Row className="mx-0">
                        <Form.Group
                            as={Col}
                            md="3"
                            className={"mb-3"}
                            controlId="validationFormik03"
                        >
                            <Form.Label>Status do evento</Form.Label>
                            <Form.Select
                                name="status"
                                disabled={values.status > 1 ? false : true}
                                onChange={values.status > 1 ? handleChange : undefined}
                                value={values.status}
                                isInvalid={!!errors.status}
                                isValid={touched.status && !errors.status}>
                                {statusX.map((element, index) => {
                                    if (values.status > 1) {
                                        if (index > 1) {
                                            return (
                                                <option key={"status" + index} value={index}>
                                                    {element}
                                                </option>
                                            );
                                        } else {
                                            return ""
                                        }
                                    } else {
                                        return (
                                            <option key={"status" + index} value={index}>
                                                {element}
                                            </option>
                                        );
                                    }
                                })}
                            </Form.Select>
                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">{errors.status}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                            as={Col}
                            md="3"
                            className={"mb-3"}
                            controlId="validationFormik03"
                        >
                            <Form.Label>Inscrição</Form.Label>
                            <Form.Select
                                name="status"
                                onChange={(e) => {
                                    setFieldValue(
                                        "json.form.status",
                                        e.target.value === true || e.target.value === 'true' ? true : false
                                    );
                                }}
                                value={values.json.form.status}
                                isInvalid={!!errors.json?.form?.status}
                                isValid={touched.json?.form?.status && !errors.json?.form?.status}>
                                <option value={false}>
                                    Desativar
                                </option>
                                <option value={true}>
                                    Ativar
                                </option>
                            </Form.Select>
                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">{errors.json?.form?.status}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                            className="mb-3"
                            as={Col}
                            md="3"
                            sm={6}
                            controlId="validationFormik05"
                        >
                            <Form.Label>Data de criação</Form.Label>
                            <InputMask
                                disabled
                                mask="99/99/9999 99:99"
                                value={values.data_criacao}
                                isInvalid={!!errors.data_criacao}
                                isValid={touched.data_criacao && !errors.data_criacao}
                            >
                                {(inputProps) => (
                                    <Form.Control
                                        disabled
                                        {...inputProps}
                                        type="text"
                                        name="data_criacao"
                                    />
                                )}
                            </InputMask>
                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                                {errors.data_criacao}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                            className="mb-3"
                            as={Col}
                            md="3"
                            sm={6}
                            controlId="validationFormik06"
                        >
                            <Form.Label>Data de término</Form.Label>
                            <InputMask
                                mask="99/99/9999 99:99"
                                value={values.data_termino}
                                onChange={handleChange}
                                isInvalid={!!errors.data_termino}
                                isValid={touched.data_termino && !errors.data_termino}
                            >
                                {(inputProps) => (
                                    <Form.Control
                                        {...inputProps}
                                        type="text"
                                        name="data_termino"
                                    />
                                )}
                            </InputMask>
                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                                {errors.data_termino}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3 mx-0">
                        <Form.Group className="mb-3">
                            <Form.Check
                                className="w-auto"
                                checked={values.json.removeLogoFlex ? true : false}
                                type="checkbox"
                                name="json.removeLogoFlex"
                                label="Remover logomarca da Flex Interativa"
                                onChange={(e) => {
                                    setFieldValue(
                                        "json.removeLogoFlex",
                                        values.json.removeLogoFlex ? false : true
                                    );
                                }}
                            />
                            <Form.Check
                                checked={values.json.noCache ? false : true}
                                type="checkbox"
                                name="json.noCache"
                                label='Salvar dados do usuário no navegador após registro (cache)'
                                onChange={(e) => {
                                    setFieldValue(
                                        "json.noCache",
                                        values.json.noCache ? false : true
                                    );
                                }}
                            />
                        </Form.Group>
                    </Row>

                    <Form.Group as={Col} className="textHeaderAplicativo mb-3">
                        <h5 className="card-title mb-0">Formulário de registro</h5>
                    </Form.Group>
                    <Row className="mx-0">
                        <Form.Group
                            className="mb-3"
                            as={Col}
                            sm="12"
                            controlId="validationFormik01"
                        >
                            <Form.Label>Título</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="json.form.title.value"
                                value={values.json.form.title.value}
                                onChange={(e) => {
                                    if (e.target.value === '') {
                                        setFieldValue("json.form.title.status", false);
                                    } else {
                                        setFieldValue("json.form.title.status", true);
                                    }
                                    setFieldValue("json.form.title.value", e.target.value);
                                }}
                                isInvalid={!!errors?.json?.form?.title?.value}
                                isValid={touched?.json?.form?.title?.value && !errors?.json?.form?.title?.value}
                            />
                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                                {errors.json?.form?.title?.value}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                            className="mb-3"
                            as={Col}
                            sm="12"
                            controlId="validationFormik02"
                        >
                            <Form.Label>Descrição</Form.Label>
                            <Form.Control
                                name="json.form.description.value"
                                as="textarea"
                                value={values.json.form.description.value}
                                onChange={(e) => {
                                    if (e.target.value === '') {
                                        setFieldValue("json.form.description.status", false);
                                    } else {
                                        setFieldValue("json.form.description.status", true);
                                    }
                                    setFieldValue("json.form.description.value", e.target.value);
                                }}
                                isInvalid={!!errors?.json?.form?.description?.value}
                                isValid={touched?.json?.form?.description?.value && !errors?.json?.form?.description?.value}
                            />
                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">{errors.json?.form?.description?.value}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                            as={Col}
                            sm="12"
                            className="mb-3"
                            controlId="validationFormik06">
                            <Form.Label>Texto do botão</Form.Label>
                            <Form.Control
                                name="json.form.buttonText"
                                value={values.json.form.buttonText}
                                onChange={handleChange}
                                isInvalid={!!errors?.json?.form?.buttonText}
                                isValid={touched?.json?.form?.buttonText && !errors?.json?.form?.buttonText
                                }
                            />
                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">{errors?.json?.form?.buttonText}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <FormCompositionCard dataAddForm={dataAddForm} setDataAddForm={setDataAddForm} setShowModalForm={setShowModalForm} setModalForm={setModalForm}></FormCompositionCard>
                    <Row className="mb-3 mx-0">
                        <Form.Group className="mb-3">
                            <Form.Check
                                checked={values.json.form.authorizationMessage.status ? true : false}
                                type="checkbox"
                                name="json.form.authorizationMessage.status"
                                label="Checkbox de Consentimento"
                                onChange={(e) => {
                                    setFieldValue(
                                        "json.form.authorizationMessage.status",
                                        values.json.form.authorizationMessage.status ? false : true
                                    );
                                }}
                            />
                            {values.json.form.authorizationMessage.status ? (
                                <>
                                    <Form.Group
                                        className="my-2 ps-3"
                                        as={Col}
                                        sm="6"
                                        controlId="validationFormik06"
                                    >
                                        <Form.Label>Texto</Form.Label>
                                        <Form.Control
                                            name="json.form.authorizationMessage.value"
                                            value={values.json.form.authorizationMessage.value}
                                            onChange={handleChange}
                                            isInvalid={!!errors?.json?.form?.authorizationMessage?.value}
                                            isValid={touched?.json?.form?.authorizationMessage?.value && !errors?.json?.form?.authorizationMessage?.value
                                            }
                                        />
                                        <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">{errors?.json?.form?.authorizationMessage?.value}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group
                                        className="mt-2 ps-3"
                                        as={Col}
                                        sm="6"
                                        controlId="validationFormik06"
                                    >
                                        <Form.Label>Link externo</Form.Label>
                                        <Form.Control
                                            name="json.form.authorizationLink"
                                            value={values.json.form.authorizationLink}
                                            onChange={handleChange}
                                            isInvalid={!!errors?.json?.form?.authorizationLink}
                                            isValid={touched?.json?.form?.authorizationLink && !errors?.json?.form?.authorizationLink
                                            }
                                        />
                                        <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">{errors?.json?.form?.authorizationLink}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </>
                            ) : (
                                ""
                            )}
                        </Form.Group>
                        <Form.Group as={Col} className="textHeaderAplicativo my-3">
                            <h5 className="card-title mb-0">Cadastro finalizado</h5>
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            as={Col}
                            sm="12"
                            controlId="validationFormik06"
                        >
                            <Form.Label>Título</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="json.form.disableFormTitle"
                                value={values.json.form.disableFormTitle}
                                onChange={handleChange}
                                isInvalid={!!errors?.json?.form?.disableFormTitle}
                                isValid={touched?.json?.form?.disableFormTitle && !errors?.json?.form?.disableFormTitle
                                }
                            />
                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">{errors?.json?.form?.disableFormTitle}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            as={Col}
                            sm="12"
                            controlId="validationFormik06"
                        >
                            <Form.Label>Descrição</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="json.form.disableDescription"
                                value={values.json.form.disableDescription}
                                onChange={handleChange}
                                isInvalid={!!errors?.json?.form?.disableDescription}
                                isValid={touched?.json?.form?.disableDescription && !errors?.json?.form?.disableDescription
                                }
                            />
                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">{errors?.json?.form?.disableDescription}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>

                    <Form.Group as={Col} className="textHeaderAplicativo mb-3">
                        <h5 className="card-title mb-0">Aplicativos</h5>
                    </Form.Group>
                    <Row className="mb-3 mx-0">
                        {applications && applications.map((valueX, index) => {
                            return (
                                <Col
                                    className="mb-3"
                                    key={"card" + index}
                                    lg="4"
                                    md="4"
                                    sm="6"
                                    xl="3"
                                >
                                    <Card className="mainCardApps mb-0">
                                        <div
                                            className="p-3 d-flex flex-column h-100">
                                            <h4 className="mb-0">{valueX.nome}</h4>
                                            <hr></hr>
                                            <h5>
                                                Título: <span className="opacity-75">{valueX.titulo}</span>
                                            </h5>
                                            <h5>
                                                Versão: <span className="opacity-75">{valueX.version === 'toUpdate' ? 'Atualizado' : valueX.version}</span>
                                            </h5>
                                            <div className="mt-auto text-center">
                                                {valueX.currentVersion && valueX.version != valueX.currentVersion && valueX.version !== 'toUpdate' && <Button variant="primary" className="btn-sm mt-2 me-2" onClick={(e) => updateCustomizationVersion(e, setApplications, valueX)}>Atualizar versão</Button>}
                                                <Button variant="danger" className="btn-sm mt-2" onClick={(e) => removeCustomizationFromEvent(e, setApplications, valueX.customizacao_id)}>Remover</Button>
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            );
                        })}
                        <Col
                            className="mb-3 vertically-centered"
                            lg="4"
                            md="4"
                            sm="6"
                            xl="3"
                        >
                            <div
                                className="addMainCard"
                                onClick={() => {
                                    setDataAplicativo(false);
                                    setShowModalAplicativos(true);
                                }}
                            >
                                <FontAwesomeIcon icon={faPlus} />
                            </div>
                        </Col>
                    </Row>
                    {applications.length > 1 &&
                        <Row className="mb-3 mx-0">
                            <p className="text-start fw-bold mb-2">Tela de seleção de aplicativos</p>
                            <Form.Group
                                className="mb-3"
                                as={Col}
                                sm="12"
                                controlId="validationFormik01"
                            >
                                <Form.Label>Título</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="json.applications.title.value"
                                    value={values.json.applications?.title?.value}
                                    onChange={(e) => {
                                        if (e.target.value === '') {
                                            setFieldValue("json.applications.title.status", false);
                                        } else {
                                            setFieldValue("json.applications.title.status", true);
                                        }
                                        setFieldValue("json.applications.title.value", e.target.value);
                                    }}
                                    isInvalid={!!errors?.json?.applications?.title.value}
                                    isValid={touched?.json?.applications?.title.value && !errors?.json?.applications?.title.value}
                                />
                                <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.json?.applications?.title.value}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group
                                className="mb-3"
                                as={Col}
                                sm="12"
                                controlId="validationFormik02"
                            >
                                <Form.Label>Descrição</Form.Label>
                                <Form.Control
                                    name="json.applications.description.value"
                                    as="textarea"
                                    value={values.json.applications?.description?.value}
                                    onChange={(e) => {
                                        if (e.target.value === '') {
                                            setFieldValue("json.applications.description.status", false);
                                        } else {
                                            setFieldValue("json.applications.description.status", true);
                                        }
                                        setFieldValue("json.applications.description.value", e.target.value);
                                    }}
                                    isInvalid={!!errors?.json?.applications?.description.value}
                                    isValid={touched?.json?.applications?.description.value && !errors?.json?.applications?.description.value}
                                />
                                <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">{errors.json?.applications?.description.value}</Form.Control.Feedback>
                            </Form.Group>
                        </Row>}

                    {applications.length === 0 &&
                        <>
                            <Form.Group as={Col} className="textHeaderAplicativo mb-3">
                                <h5 className="card-title mb-0">Tela pós-registro</h5>
                            </Form.Group>
                            <Row className="mx-0">
                                <Form.Group
                                    className="mb-3"
                                    as={Col}
                                    sm="12"
                                    controlId="validationFormik01"
                                >
                                    <Form.Label>Título</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="json.end.title.value"
                                        value={values.json.end.title.value}
                                        onChange={(e) => {
                                            if (e.target.value === '') {
                                                setFieldValue("json.end.title.status", false);
                                            } else {
                                                setFieldValue("json.end.title.status", true);
                                            }
                                            setFieldValue("json.end.title.value", e.target.value);
                                        }}
                                        isInvalid={!!errors?.json?.end?.title.value}
                                        isValid={touched?.json?.end?.title.value && !errors?.json?.end?.title.value}
                                    />
                                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.json?.end?.title.value}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group
                                    className="mb-3"
                                    as={Col}
                                    sm="12"
                                    controlId="validationFormik02"
                                >
                                    <Form.Label>Descrição</Form.Label>
                                    <Form.Control
                                        name="json.end.description.value"
                                        as="textarea"
                                        value={values.json.end.description.value}
                                        onChange={(e) => {
                                            if (e.target.value === '') {
                                                setFieldValue("json.end.description.status", false);
                                            } else {
                                                setFieldValue("json.end.description.status", true);
                                            }
                                            setFieldValue("json.end.description.value", e.target.value);
                                        }}
                                        isInvalid={!!errors?.json?.end?.description.value}
                                        isValid={touched?.json?.end?.description.value && !errors?.json?.end?.description.value}
                                    />
                                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">{errors.json?.end?.description.value}</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3 mx-0">
                                <Form.Group>
                                    <Form.Check
                                        checked={values.json.form.reset ? true : false}
                                        type="checkbox"
                                        name="json.form.reset"
                                        label="Habilitar botão de voltar para tela de registro"
                                        onChange={(e) => {
                                            setFieldValue(
                                                "json.form.reset",
                                                values.json.form.reset ? false : true
                                            );
                                        }}
                                    />
                                    <Form.Check
                                        className="w-auto"
                                        checked={values.json.end.showQRCode ? true : false}
                                        type="checkbox"
                                        name="json.end.showQRCode"
                                        label="Mostrar código QR do participante"
                                        onChange={(e) => {
                                            setFieldValue(
                                                "json.end.showQRCode",
                                                values.json.showQRCode ? false : true
                                            );
                                        }} />
                                    <Form.Check
                                        className="w-auto"
                                        checked={values.json.end.showUserData ? true : false}
                                        type="checkbox"
                                        name="json.end.showUserData"
                                        label="Mostrar dados registrados do participante"
                                        onChange={(e) => {
                                            setFieldValue(
                                                "json.end.showUserData",
                                                values.json.end.showUserData ? false : true
                                            );
                                        }} />
                                </Form.Group>
                            </Row>
                        </>}



                    <Form.Group as={Col} className="textHeaderAplicativo mb-3">
                        <h5 className="card-title mb-0">
                            Importar participantes ({addVisitantes.visitantes.length > 0 ? addVisitantes.visitantes.length : 0})
                        </h5>
                    </Form.Group>
                    <Row className="mx-0">
                        <Form.Group
                            as={Col}
                            md="6"
                            className="mb-3"
                            controlId="validationFormik03"
                        >
                            <Form.Label>Selecione um arquivo Excel ou CSV <OverlayTrigger
                                overlay={<Tooltip>Os nomes definidos para as colunas precisam ser os mesmos campos do formulário</Tooltip>}
                            >
                                <HelpTooltip icon={faExclamationCircle} />
                            </OverlayTrigger>
                            </Form.Label>
                            <div className="d-flex">
                                <div>
                                    <Form.Control
                                        type="file"
                                        accept=".xlsx,.xls,.csv"
                                        onChange={(e) => handleFileUpload(e, setAddVisitantes)}
                                        isInvalid={!!addVisitantes.error}
                                        isValid={!addVisitantes.error}
                                        ref={addVisitantesInput}
                                    />
                                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                        {addVisitantes.error}
                                    </Form.Control.Feedback>
                                </div>
                                <div>
                                    <Button
                                        variant="primary"
                                        className="float-end ms-2"
                                        onClick={() => {
                                            setAddVisitantes((prevState) => ({
                                                ...prevState,
                                                visitantes: [],
                                                formValue: '',
                                                formFilename: ''
                                            }));
                                            addVisitantesInput.current.value = ''
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faSync} />
                                    </Button>
                                </div>
                            </div>
                        </Form.Group>
                    </Row>
                    <Row className="mx-0">
                        <Col md={12} className="mb-3">
                            <Card className="mb-3">
                                <Card.Body className="overflow-auto">
                                    <ColumnSortingTable
                                        columns={addVisitantes.fields}
                                        data={addVisitantes.visitantes}
                                        title={dataTable.titulo}
                                        getCellProps={(cellinfo) => ({})}
                                    />
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <Form.Group as={Col} className="textHeaderAplicativo mb-3">
                        <h5 className="card-title mb-0">Layout</h5>
                    </Form.Group>
                    <Row className="mx-0">
                        <Form.Group
                            as={Col}
                            md={4}
                            controlId="validationFormik07"
                            className="mb-3"
                        >
                            <Form.Label>Estilo de fonte</Form.Label>
                            <Form.Select
                                name="json.fontFamily"
                                value={values.json.fontFamily}
                                onChange={(e) => {
                                    setFieldValue("json.fontFamily", e.target.value);
                                }}
                            >
                                {fonts.map((element, index) => {
                                    return (
                                        <option
                                            style={{ fontFamily: element.font }}
                                            key={"font" + index}
                                            value={element.font}
                                        >
                                            {element.font}
                                        </option>
                                    );
                                })}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group
                            as={Col}
                            md={4}
                            className="mb-3"
                            controlId="validationFormik01">
                            <Form.Label>Tamanho fonte (M) (rem)</Form.Label>
                            <Form.Control
                                type="number"
                                step={"0.01"}
                                name="json.h5Size"
                                value={values.json.h5Size}
                                onChange={handleChange}
                                isInvalid={!!errors.json?.h5Size}
                                isValid={touched.json?.h5Size && !errors.json?.h5Size}
                            />
                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                                {errors.json?.h5Size}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                            as={Col}
                            md={4}
                            className="mb-3"
                            controlId="validationFormik01">
                            <Form.Label>Tamanho fonte (P) (rem)</Form.Label>
                            <Form.Control
                                type="number"
                                name="json.pSize"
                                step={"0.01"}
                                value={values.json.pSize}
                                onChange={handleChange}
                                isInvalid={!!errors.json?.pSize}
                                isValid={touched.json?.pSize && !errors.json?.pSize}
                            />
                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                                {errors.json?.pSize}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3 mx-0">
                        <p className="text-start fw-bold mb-2">Layout</p>
                        <Form.Group
                            as={Col}
                            md="4"
                            className="mb-3"
                            controlId="validationFormik03"
                        >
                            <Form.Label>Tipo de layout</Form.Label>
                            <Form.Select
                                name="json.layout.type"
                                onChange={(e) => {
                                    setFieldValue(
                                        "json.layout.type", e.target.value == 1 ? 1 : 2
                                    )
                                }}
                                value={values.json.layout.type}
                                isInvalid={!!errors.json?.layout?.type}
                                isValid={touched.json?.layout?.type && !errors.json?.layout?.type}>
                                {layoutX.map((element, index) => {
                                    return (
                                        <option key={"layout" + index} value={index + 1}>
                                            {element}
                                        </option>
                                    );
                                })}
                            </Form.Select>
                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">{errors.json?.layout?.type}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                            as={Col}
                            md={4}
                            controlId="validationFormik01">
                            <Form.Label>Margem (rem)</Form.Label>
                            <Form.Control
                                type="number"
                                name="json.paddingBox"
                                step={"0.01"}
                                value={values.json.paddingBox}
                                onChange={handleChange}
                                isInvalid={!!errors.json?.paddingBox}
                                isValid={touched.json?.paddingBox && !errors.json?.paddingBox}
                            />
                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                                {errors.json?.paddingBox}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3 mx-0">
                        {values.json.layout.type === 1 &&
                            <Form.Group
                                as={Col}
                                md="4"
                                controlId="validationFormik03"
                            >
                                <Form.Label>Rodapé</Form.Label>
                                <Form.Select
                                    name="json.layout.footer.type"
                                    onChange={(e) => {
                                        if (e.target.value === 'noFooter')
                                            setFieldValue(
                                                "json.layout.footer.socialMedia", []
                                            );
                                        setFieldValue(
                                            "json.layout.footer.type", e.target.value
                                        );
                                    }}
                                    value={values.json.layout.footer.type}
                                    isInvalid={!!errors.json?.layout?.footer?.type}
                                    isValid={touched.json?.layout?.footer?.type && !errors.json?.layout?.footer?.type}>
                                    <option value={"noFooter"}>
                                        Sem rodapé
                                    </option>
                                    <option value={"image"}>
                                        Imagem
                                    </option>
                                    <option value={"icons"}>
                                        Ícones
                                    </option>
                                </Form.Select>
                                <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">{errors.json?.layout?.type}</Form.Control.Feedback>
                            </Form.Group>}
                    </Row>
                    {values.json.layout.type === 1 ? (
                        <FooterFormComponent dataAddForm={dataAddForm} setDataAddForm={setDataAddForm} setShowSocialMediaForm={setShowSocialMediaForm} setModalForm={setModalForm} touched={touched} errors={errors} values={values} setFieldValue={setFieldValue} handlePreviewSuccess={handlePreviewSuccess} handlePreviewError={handlePreviewError}></FooterFormComponent>
                    ) : ("")}
                    <Form.Group as={Col} className="textHeaderAplicativo mb-3">
                        <h5 className="card-title mb-0">Cores</h5>
                    </Form.Group>
                    <Row className="mx-0 mb-3">
                        <Col
                            sm={6}
                            className={(errors?.json?.background?.color || errors?.json?.baseColorText || errors?.json?.transparency || errors?.json?.primaryColor || errors?.json?.primaryColorText || errors?.json?.bsBorderColor || errors?.json?.boxShadow) ? ("mb-3 is-invalid") : ("mb-3")}
                        >
                            <Row className="mb-2">
                                <div className={`d-flex justify-content-start align-items-center ${errors?.json?.background?.color ? 'is-invalid' : ''}`}>
                                    <PopoverPicker
                                        color={props.backgroundColor}
                                        onChangeX={(e) => {
                                            setFieldValue("json.background.color", e);
                                            setProps(prevState => ({
                                                ...prevState,
                                                backgroundColor: e
                                            }));
                                        }}
                                        classes={`me-1 picker ${errors?.json?.background?.color ? 'is-invalid' : ''}`}
                                    />
                                    <Form.Label className="mb-0 ms-2">Plano de fundo</Form.Label>
                                </div>
                                <Form.Control.Feedback type="invalid">
                                    {getFirstError(
                                        errors?.json?.background?.color // Add more variables as needed
                                    )}
                                </Form.Control.Feedback>
                            </Row>
                            <Row className="mb-2">
                                <div className={`d-flex justify-content-start align-items-center ${errors?.json?.transparency ? 'is-invalid' : ''}`}>
                                    <PopoverPicker
                                        color={props.transparency}
                                        onChangeX={(e) => {
                                            setFieldValue("json.transparency", e);
                                            setProps(prevState => ({
                                                ...prevState,
                                                transparency: e
                                            }));
                                        }}
                                        classes={`me-1 picker ${errors?.json?.transparency ? 'is-invalid' : ''}`}
                                    />
                                    <Form.Label className="mb-0 ms-2">Container</Form.Label>
                                </div>
                                <Form.Control.Feedback type="invalid">
                                    {getFirstError(
                                        errors?.json?.transparency
                                    )}
                                </Form.Control.Feedback>
                            </Row>
                            <Row className="mb-2">
                                <div className={`d-flex justify-content-start align-items-center ${errors?.json?.boxShadow ? 'is-invalid' : ''}`}>
                                    <PopoverPicker
                                        color={props.boxShadow}
                                        onChangeX={(e) => {
                                            setFieldValue("json.boxShadow", e);
                                            setProps(prevState => ({
                                                ...prevState,
                                                boxShadow: e
                                            }));
                                        }}
                                        classes={`me-1 picker ${errors?.json?.boxShadow ? 'is-invalid' : ''}`}
                                    />
                                    <Form.Label className="mb-0 ms-2">Sombra do container</Form.Label>
                                </div>
                                <Form.Control.Feedback type="invalid">
                                    {getFirstError(
                                        errors?.json?.boxShadow
                                    )}
                                </Form.Control.Feedback>
                            </Row>

                            <Row className="mb-2">
                                <div className={`d-flex justify-content-start align-items-center ${errors?.json?.baseColorText ? 'is-invalid' : ''}`}>
                                    <PopoverPicker
                                        color={props.baseColorText}
                                        onChangeX={(e) => {
                                            setFieldValue("json.baseColorText", e);
                                            setProps(prevState => ({
                                                ...prevState,
                                                baseColorText: e
                                            }));
                                        }}
                                        classes={`me-1 picker ${errors?.json?.baseColorText ? 'is-invalid' : ''}`}
                                    />
                                    <Form.Label className="mb-0 ms-2">Texto primário</Form.Label>
                                </div>
                                <Form.Control.Feedback type="invalid">
                                    {getFirstError(
                                        errors?.json?.baseColorText
                                    )}
                                </Form.Control.Feedback>
                            </Row>

                            <Row className="mb-2">
                                <div className={`d-flex justify-content-start align-items-center ${errors?.json?.primaryColor ? 'is-invalid' : ''}`}>
                                    <PopoverPicker
                                        color={props.primaryColor}
                                        onChangeX={(e) => {
                                            setFieldValue("json.primaryColor", e);
                                            setProps(prevState => ({
                                                ...prevState,
                                                primaryColor: e
                                            }));
                                        }}
                                        classes={`me-1 picker ${errors?.json?.primaryColor ? 'is-invalid' : ''}`}
                                    />
                                    <Form.Label className="mb-0 ms-2">Elementos de destaque</Form.Label>
                                </div>
                                <Form.Control.Feedback type="invalid">
                                    {getFirstError(
                                        errors?.json?.primaryColor
                                    )}
                                </Form.Control.Feedback>
                            </Row>

                            <Row className="mb-2">
                                <div className={`d-flex justify-content-start align-items-center ${errors?.json?.primaryColorText ? 'is-invalid' : ''}`}>
                                    <PopoverPicker
                                        color={props.primaryColorText}
                                        onChangeX={(e) => {
                                            setFieldValue("json.primaryColorText", e);
                                            setProps(prevState => ({
                                                ...prevState,
                                                primaryColorText: e
                                            }));
                                        }}
                                        classes={`me-1 picker ${errors?.json?.primaryColorText ? 'is-invalid' : ''}`}
                                    />
                                    <Form.Label className="mb-0 ms-2">Texto secundário</Form.Label>
                                </div>
                                <Form.Control.Feedback type="invalid">
                                    {getFirstError(
                                        errors?.json?.primaryColorText
                                    )}
                                </Form.Control.Feedback>
                            </Row>

                            <Row className="mb-2">
                                <div className={`d-flex justify-content-start align-items-center ${errors?.json?.bsBorderColor ? 'is-invalid' : ''}`}>
                                    <PopoverPicker
                                        color={props.bsBorderColor}
                                        onChangeX={(e) => {
                                            setFieldValue("json.bsBorderColor", e);
                                            setProps(prevState => ({
                                                ...prevState,
                                                bsBorderColor: e
                                            }));
                                        }}
                                        classes={`me-1 picker ${errors?.json?.bsBorderColor ? 'is-invalid' : ''}`}
                                    />
                                    <Form.Label className="mb-0 ms-2">Bordas</Form.Label>
                                </div>
                                <Form.Control.Feedback type="invalid">
                                    {getFirstError(
                                        errors?.json?.bsBorderColor
                                    )}
                                </Form.Control.Feedback>
                            </Row>
                            <Row>
                                <Form.Group
                                    controlId="validationFormik03"
                                >
                                    <Form.Label>Background</Form.Label>
                                    <Form.Select
                                        name="json.background.type"
                                        onChange={handleChange}
                                        value={values.json.background.type}
                                        isInvalid={!!errors.json?.background?.type}
                                        isValid={touched.json?.background?.type && !errors.json?.background?.type}>
                                        <option value={"color"}>
                                            Cor
                                        </option>
                                        <option value={"image"}>
                                            Imagem
                                        </option>
                                        <option value={"video"}>
                                            Vídeo
                                        </option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">{errors.json?.background?.type}</Form.Control.Feedback>
                                </Form.Group>
                            </Row>

                            {values.json.background.type !== 'color' && <Row className="mb-3">
                                <BackgroundFormComponent touched={touched} errors={errors} values={values} setFieldValue={setFieldValue} handlePreviewSuccess={handlePreviewSuccess} handlePreviewError={handlePreviewError}></BackgroundFormComponent>
                            </Row>}
                        </Col>
                        <Col
                            className={(errors?.json?.background?.color || errors?.json?.baseColorText || errors?.json?.transparency || errors?.json?.primaryColor || errors?.json?.primaryColorText || errors?.json?.bsBorderColor || errors?.json?.boxShadow) ? ("is-invalid") : ("mb-3")}
                        >
                            <Form.Control
                                type="hidden"
                                name="json.background.color"
                                value={values.json.background.color}
                                isInvalid={!!errors?.json?.background?.color}
                                isValid={touched?.json?.background?.color && !errors?.json?.background?.color}
                            />
                            <Form.Control
                                type="hidden"
                                name="json.baseColorText"
                                value={values.json.baseColorText}
                                isInvalid={!!errors?.json?.baseColorText}
                                isValid={touched?.json?.baseColorText && !errors?.json?.baseColorText}
                            />
                            <Form.Control
                                type="hidden"
                                name="json.transparency"
                                value={values.json.transparency}
                                isInvalid={!!errors?.json?.transparency}
                                isValid={touched?.json?.transparency && !errors?.json?.transparency}
                            />
                            <Form.Control
                                type="hidden"
                                name="json.primaryColor"
                                value={values.json.primaryColor}
                                isInvalid={!!errors?.json?.primaryColor}
                                isValid={touched?.json?.primaryColor && !errors?.json?.primaryColor
                                }
                            />
                            <Form.Control
                                type="hidden"
                                name="json.primaryColorText"
                                value={values.json.primaryColorText}
                                isInvalid={!!errors?.json?.primaryColorText}
                                isValid={touched?.json?.primaryColorText && !errors?.json?.primaryColorText}
                            />
                            <Form.Control
                                type="hidden"
                                name="json.boxShadow"
                                value={values.json.boxShadow}
                                isInvalid={!!errors?.json?.boxShadow}
                                isValid={touched?.json?.boxShadow && !errors?.json?.boxShadow}
                            />
                            <Form.Control
                                type="hidden"
                                name="json.bsBorderColor"
                                value={values.json.bsBorderColor}
                                isInvalid={!!errors?.json?.bsBorderColor}
                                isValid={touched?.json?.bsBorderColor && !errors?.json?.bsBorderColor}
                            />
                            <div
                                className="colorBlock"
                                style={
                                    values.json.background.type === "image"
                                        ? {
                                            backgroundImage: `url("${domainConfig.imageServer +
                                                "/evento/" +
                                                values.evento_id +
                                                "/" +
                                                values.json.background.url
                                                }")`,
                                        }
                                        : {
                                            backgroundColor: `${props.backgroundColor}`,
                                        }
                                }
                            >
                                <div className="colorBlockNav" style={{ "backgroundColor": `${props.transparency}` }}></div>
                                {values.json.background.type === "video" &&
                                    <video
                                        className="videoBackgroundEventForm"
                                        controls={false}
                                        autoPlay={true}
                                        sound={false}
                                        src={`${domainConfig.imageServer +
                                            "/evento/" +
                                            values.evento_id +
                                            "/" +
                                            values.json.background.url
                                            }`}>
                                    </video>}
                                <span
                                    style={{
                                        color: `${props.baseColorText}`,
                                        borderRadius: "10px",
                                        backgroundColor: `${props.transparency}`,
                                        boxShadow: `0 .3rem 0.5rem ${props.boxShadow}`,
                                    }}
                                    className="text-center h6 mb-0 p-3"
                                >
                                    Texto primário
                                    <div className="p-3"
                                        style={{ borderBottom: `2px solid ${props.bsBorderColor}` }}>
                                        <div className="buttons"
                                            style={{
                                                color: `${props.primaryColorText}`,
                                                backgroundColor: `${props.primaryColor}`
                                            }}
                                        >
                                            Texto secundário</div>
                                    </div>
                                </span>
                            </div>
                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                                {(errors?.json?.background?.color) || errors?.json?.baseColorText || errors?.json?.transparency || errors?.json?.primaryColor || errors?.json?.primaryColorText || errors?.json?.bsBorderColor || errors?.json?.boxShadow}
                            </Form.Control.Feedback>
                        </Col>
                    </Row>

                    <Form.Group as={Col} className="textHeaderAplicativo mb-3">
                        <h5 className="card-title mb-0">
                            Media <span className="small">(Tamanho máximo: 10mb)</span>
                        </h5>
                    </Form.Group>
                    <Row className="mx-0">
                        <Form.Group
                            as={Col}
                            md="6">
                            <Card className="mb-0">
                                <Card.Body>
                                    <div className="text-center mb-3">
                                        <div className="d-flex justify-content-center mb-3">
                                            <Form.Label>Logomarca (M)</Form.Label>
                                        </div>
                                    </div>
                                    <Form.Control
                                        type="hidden"
                                        name="json.logo"
                                        value={values.json.logo}
                                        onChange={handleChange}
                                        isInvalid={!!errors?.json?.logo}
                                        isValid={touched?.json?.logo && !errors?.json?.logo}
                                    />
                                    <div className="imagesUpload">
                                        <img
                                            className={`h-100 img-thumbnail ${!!errors?.json?.logo ? "is-invalid" : ""}`}
                                            src={
                                                values.json.logo !== "" && values.json.logo !== null
                                                    ? domainConfig.imageServer +
                                                    "/evento/" +
                                                    values.evento_id +
                                                    "/" +
                                                    values.json.logo
                                                    : domainConfig.imageServer + "/empty-background.png"
                                            }
                                            alt="Imagem footer aplicativo"
                                        />
                                        <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                            {errors?.json?.logo}
                                        </Form.Control.Feedback>
                                    </div>
                                    <DropzoneComponent
                                        className="mt-3 dropzoneComp"
                                        type="image"
                                        handlePreviewSuccess={handlePreviewSuccess}
                                        handlePreviewError={handlePreviewError}
                                        setFieldValue={setFieldValue}
                                        folder="evento"
                                        id={values.evento_id}
                                        field="json.logo"
                                    />
                                    <Form.Group
                                        as={Col}
                                        className="mt-3"
                                        controlId="validationFormik01">
                                        <Form.Label>Largura (px)</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="json.logoWidth"
                                            value={values.json.logoWidth}
                                            onChange={handleChange}
                                            isInvalid={!!errors.json?.logoWidth}
                                            isValid={touched.json?.logoWidth && !errors.json?.logoWidth}
                                        />
                                        <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.json?.logoWidth}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Card.Body>
                            </Card>
                        </Form.Group>
                    </Row>
                    <hr className="mt-4"></hr>
                    <Form.Group as={Col}>
                        <Button
                            type="submit"
                            className="float-end"
                            disabled={isSubmitting}
                        >
                            Salvar
                        </Button>
                    </Form.Group>
                </Form >
            )}
        </Formik >
    )
}

export const FormComponentMode02 = ({ fonts, dataTable, setDataTable, setShowHistory, dataAddForm, setDataAddForm, setModalForm, setShowModalForm, setShowSocialMediaForm }) => {
    const [props, setProps] = useState({
        primaryColor: dataTable.json.primaryColor,
        primaryColorText: dataTable.json.primaryColorText,
        backgroundColor: dataTable.json.background.color,
        baseColorText: dataTable.json.baseColorText,
        transparency: dataTable.json.transparency,
        boxShadow: dataTable.json.boxShadow,
        bsBorderColor: dataTable.json.bsBorderColor,
    });
    console.log(dataTable)
    const [currentJson, setJson] = useState(dataTable.json)
    const dispatch = useAppDispatch();
    const notyf = useContext(NotyfContext);
    const { setLoading } = useLayout();
    const uepIntegrationActive = dataTable.external_event_id !== null && dataTable.external_form_id !== null ? true : false
    const [addVisitantes, setAddVisitantes] = useState({ visitantes: [], error: false, fields: [] });
    const addVisitantesInput = useRef();

    function getFirstError(...errors) {
        return errors.find(error => error !== undefined && error !== null) || '';
    }

    function handlePreviewError(error, notyf) {
        notyf.open({
            type: "danger",
            message: error,
            ripple: true,
            dismissible: true,
        });
    }

    function uepIntegration({ external_event_id, external_form_id, updatePermission = false }) {
        console.log(external_event_id)
        console.log(external_form_id)
        console.log(updatePermission)
        if (!external_event_id || external_event_id === null) {
            notyf.open({
                type: "danger",
                message: 'ID do Evento inválido',
                ripple: true,
                dismissible: true,
            });
            return
        }
        if (!external_form_id || external_form_id === null) {
            notyf.open({
                type: "danger",
                message: 'ID do formulário inválido',
                ripple: true,
                dismissible: true,
            });
            return
        }
        axios
            .post(`/api/event/uepIntegration`, {
                external_event_id: external_event_id,
                external_form_id: external_form_id,
                updatePermission: updatePermission,
                uepIntegrationActive: uepIntegrationActive,
                event_id: dataTable.event_id,
                json: JSON.stringify(currentJson)
            })
            .then(function (response) {
                if (response.status === 204) {
                    confirmAlert({
                        closeOnEscape: false,
                        closeOnClickOutside: false,
                        customUI: ({ onClose }) => {
                            return (
                                <ShowConfirm
                                    title="Confirmar ação"
                                    description="Existem diferenças no formulário atual do UEP, isso pode causar inconsistências nos dados dos usuários já cadastrados, deseja continuar?"
                                    action={uepIntegration}
                                    onClose={onClose}
                                    param={{ external_event_id, external_form_id, updatePermission: true }}
                                />
                            );
                        },
                    });
                } else {
                    if (response.data.event) {
                        setJson(prevState => ({
                            ...prevState,
                            form: {
                                ...prevState.form,
                                fieldsToShow: response.data.event.fieldsToShow,
                                fields: response.data.event.fields
                            }
                        }));
                    }
                    notyf.open({
                        type: "success",
                        message: `Integração realizada com sucesso. Foram atualizados: ${response.totalUsers}`,
                        ripple: true,
                        dismissible: true,
                    });
                }
                console.log(response)
            })
            .catch(function (error) {
                console.log(error)
                notyf.open({
                    type: "danger",
                    message: error.response?.data?.message ? error.response?.data?.message : 'Houve um problema, tente novamente',
                    ripple: true,
                    dismissible: true,
                });
            });
    }

    console.log(dataTable)

    return (
        <Formik
            validationSchema={schemaEventMode02}
            enableReinitialize
            onSubmit={(values, actions) => updateEvent02(values, actions, dataAddForm, addVisitantes.visitantes, dataTable, setLoading, notyf, dispatch)}
            initialValues={{
                event_id: dataTable.event_id,
                user_id: dataTable.user_id,
                title: dataTable.title,
                description: dataTable.description,
                json: dataTable.json,
                status: dataTable.status,
                creation_date: dataTable.data_criacao,
                end_date: dataTable.data_termino,
                token: dataTable.token,
                external_event_id: dataTable.external_event_id,
                external_form_id: dataTable.external_form_id,
                last_external_update: dataTable.last_external_update,
                emails: '',
            }}
        >
            {({
                handleSubmit,
                handleChange,
                values,
                touched,
                isValid,
                errors,
                dirty,
                isSubmitting,
                setFieldValue,
            }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <Button
                        variant="primary"
                        className="float-end"
                        onClick={() => {
                            setShowHistory(true)
                        }}
                    >
                        <FontAwesomeIcon icon={faChartSimple} className="me-2" />Relatórios
                    </Button>
                    <Button
                        variant="primary"
                        className="float-end me-2"
                        onClick={() => {
                            dispatch(eventList());
                        }}
                    >
                        Voltar
                    </Button>
                    <Breadcrumb>
                        <Breadcrumb.Item
                            onClick={() => {
                                dispatch(eventList());
                            }}
                        >
                            Eventos
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>
                            {values.status > 0 ? "Editar evento" : "Novo evento"}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <h6 className="text-muted mb-3">
                        Configure seu evento, desenvolva sua identidade visual e adicione aplicativos.
                    </h6>
                    <Form.Group as={Col} className="textHeaderAplicativo mb-3">
                        <h5 className="card-title mb-0">Definições</h5>
                    </Form.Group>

                    <Row className="mx-0">
                        <Form.Control type="hidden" name="status" value={true} />
                        <Form.Control
                            type="hidden"
                            name="evento_id"
                            value={values.event_id}
                        />
                        <Form.Group
                            className="mb-3"
                            as={Col}
                            sm="12"
                            controlId="validationFormik01"
                        >
                            <Form.Label>Título</Form.Label>
                            <Form.Control
                                type="text"
                                name="title"
                                value={values.title}
                                onChange={handleChange}
                                isInvalid={!!errors.title}
                                isValid={touched.title && !errors.title}
                            />
                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                                {errors.title}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                            className="mb-3"
                            as={Col}
                            sm="12"
                            controlId="validationFormik02"
                        >
                            <Form.Label>Descrição</Form.Label>
                            <Form.Control
                                name="description"
                                as="textarea"
                                value={values.description}
                                onChange={handleChange}
                                isInvalid={!!errors.description}
                                isValid={touched.description && !errors.description}
                            />
                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">{errors.description}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            as={Col}
                            sm="3"
                            controlId="validationFormik02"
                        >
                            <Form.Label>Plataforma</Form.Label>
                            <Form.Control
                                name="platform"
                                type="text"
                                disabled
                                value={eventX[dataTable.platform]}
                            />
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            as={Col}
                            sm="3"
                            controlId="validationFormik02"
                        >
                            <Form.Label>ID interno</Form.Label>
                            <Form.Control
                                name="evento_id"
                                type="text"
                                disabled
                                value={values.event_id}
                            />
                        </Form.Group>

                        <Form.Group
                            className="mb-3"
                            as={Col}
                            sm="3"
                            controlId="validationFormik02"
                        >
                            <Form.Label>Token</Form.Label>
                            <Form.Control
                                name="token"
                                type="text"
                                disabled
                                value={values.token}
                            />
                        </Form.Group>
                    </Row>
                    <Row className="mx-0">
                        <Form.Group
                            as={Col}
                            md="3"
                            className={"mb-3"}
                            controlId="validationFormik03"
                        >
                            <Form.Label>Status do evento</Form.Label>
                            <Form.Select
                                name="status"
                                disabled={values.status > 1 ? false : true}
                                onChange={values.status > 1 ? handleChange : undefined}
                                value={values.status}
                                isInvalid={!!errors.status}
                                isValid={touched.status && !errors.status}>
                                {statusX.map((element, index) => {
                                    if (values.status > 1) {
                                        if (index > 1) {
                                            return (
                                                <option key={"status" + index} value={index}>
                                                    {element}
                                                </option>
                                            );
                                        } else {
                                            return ""
                                        }
                                    } else {
                                        return (
                                            <option key={"status" + index} value={index}>
                                                {element}
                                            </option>
                                        );
                                    }
                                })}
                            </Form.Select>
                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">{errors.status}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                            as={Col}
                            md="3"
                            className={"mb-3"}
                            controlId="validationFormik03"
                        >
                            <Form.Label>Inscrição</Form.Label>
                            <Form.Select
                                name="status"
                                onChange={(e) => {
                                    setFieldValue(
                                        "json.form.status",
                                        e.target.value === true || e.target.value === 'true' ? true : false
                                    );
                                }}
                                value={values.json.form.status}
                                isInvalid={!!errors.json?.form?.status}
                                isValid={touched.json?.form?.status && !errors.json?.form?.status}>
                                <option value={false}>
                                    Desativar
                                </option>
                                <option value={true}>
                                    Ativar
                                </option>
                            </Form.Select>
                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">{errors.json?.form?.status}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                            className="mb-3"
                            as={Col}
                            md="3"
                            sm={6}
                            controlId="validationFormik05"
                        >
                            <Form.Label>Data de criação</Form.Label>
                            <InputMask
                                disabled
                                mask="99/99/9999 99:99"
                                value={values.creation_date}
                                isInvalid={!!errors.creation_date}
                                isValid={touched.creation_date && !errors.creation_date}
                            >
                                {(inputProps) => (
                                    <Form.Control
                                        disabled
                                        {...inputProps}
                                        type="text"
                                        name="creation_date"
                                    />
                                )}
                            </InputMask>
                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                                {errors.creation_date}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                            className="mb-3"
                            as={Col}
                            md="3"
                            sm={6}
                            controlId="validationFormik06"
                        >
                            <Form.Label>Data de término</Form.Label>
                            <InputMask
                                mask="99/99/9999 99:99"
                                value={values.end_date}
                                onChange={handleChange}
                                isInvalid={!!errors.end_date}
                                isValid={touched.end_date && !errors.end_date}
                            >
                                {(inputProps) => (
                                    <Form.Control
                                        {...inputProps}
                                        type="text"
                                        name="end_date"
                                    />
                                )}
                            </InputMask>
                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                                {errors.end_date}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3 mx-0">
                        <Form.Group>
                            <Form.Check
                                className="w-auto"
                                checked={values.json.removeLogoFlex ? true : false}
                                type="checkbox"
                                name="json.removeLogoFlex"
                                label="Remover logomarca da Flex Interativa"
                                onChange={(e) => {
                                    setFieldValue(
                                        "json.removeLogoFlex",
                                        values.json.removeLogoFlex ? false : true
                                    );
                                }}
                            />
                        </Form.Group>
                    </Row>

                    <Form.Group as={Col} className="textHeaderAplicativo mb-3">
                        <h5 className="card-title mb-0">Servidores</h5>
                    </Form.Group>
                    <Row className="mb-3 mx-0">
                        <Form.Group
                            className="mb-3"
                            as={Col}
                            sm="4"
                            controlId="validationFormik02"
                        >
                            <Form.Label>API Rest</Form.Label>
                            <Form.Control
                                // name="json.server.eventosAPI"
                                type="text"
                                disabled
                                value={values.json.server.eventosAPI}
                                // onChange={handleChange}
                                isInvalid={!!errors.json?.server?.eventosAPI}
                                isValid={touched.json?.server?.eventosAPI && !errors.json?.server?.eventosAPI}
                            />
                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                                {errors.json?.server?.eventosAPI}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            as={Col}
                            sm="4"
                            controlId="validationFormik02"
                        >
                            <Form.Label>API Web socket</Form.Label>
                            <Form.Control
                                // name="json.server.eventosSocketAPI"
                                type="text"
                                disabled
                                value={values.json.server.eventosSocketAPI}
                                // onChange={handleChange}
                                isInvalid={!!errors.json?.server?.eventosSocketAPI}
                                isValid={touched.json?.server?.eventosSocketAPI && !errors.json?.server?.eventosSocketAPI}
                            />
                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                                {errors.json?.server?.eventosSocketAPI}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>

                    <Form.Group as={Col} className="textHeaderAplicativo mb-3">
                        <h5 className="card-title mb-0">Integração com o UEP (Credenciamento)</h5>
                    </Form.Group>
                    <Row className="mb-3 mx-0">
                        <Form.Group
                            className="mb-3"
                            as={Col}
                            sm="4"
                            controlId="validationFormik02"
                        >
                            <Form.Label>ID Evento externo</Form.Label>
                            <Form.Control
                                name="external_event_id"
                                type="text"
                                value={values.external_event_id}
                                onChange={handleChange}
                                isInvalid={!!errors.external_event_id}
                                isValid={touched.external_event_id && !errors.external_event_id}
                            />
                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                                {errors.external_event_id}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            as={Col}
                            sm="4"
                            controlId="validationFormik02"
                        >
                            <Form.Label>ID Formulário externo</Form.Label>
                            <Form.Control
                                name="external_form_id"
                                type="text"
                                value={values.external_form_id}
                                onChange={handleChange}
                                isInvalid={!!errors.external_form_id}
                                isValid={touched.external_form_id && !errors.external_form_id}
                            />
                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                                {errors.external_form_id}
                            </Form.Control.Feedback>
                        </Form.Group>
                        {/* {uepIntegrationActive ?
                            <div className="mb-3">
                                <p>Última atualização: {values.last_external_update}</p>
                                <Button
                                    variant="primary"
                                    className="float-start me-2 mb-3"
                                    onClick={() => { }}
                                >
                                    Atualizar
                                </Button>
                            </div> : <div>
                                <Button
                                    variant="primary"
                                    className="float-start me-2 mb-3"
                                    onClick={() => uepIntegration({ external_event_id: values.external_event_id, external_form_id: values.external_form_id })}
                                >
                                    Integrar
                                </Button>
                            </div>} */}
                    </Row>

                    <Form.Group as={Col} className="textHeaderAplicativo mb-3">
                        <h5 className="card-title mb-0">Formulário de registro</h5>
                    </Form.Group>
                    <FormCompositionCard dataAddForm={dataAddForm} setDataAddForm={setDataAddForm} setShowModalForm={setShowModalForm} setModalForm={setModalForm}></FormCompositionCard>
                    <Row className="mb-3 mx-0">
                        <Form.Group className="mb-3">
                            <Form.Check
                                checked={values.json.form.authorizationMessage.status ? true : false}
                                type="checkbox"
                                name="json.form.authorizationMessage.status"
                                label="Checkbox de Consentimento"
                                onChange={(e) => {
                                    setFieldValue(
                                        "json.form.authorizationMessage.status",
                                        values.json.form.authorizationMessage.status ? false : true
                                    );
                                }}
                            />
                            {values.json.form.authorizationMessage.status ? (
                                <>
                                    <Form.Group
                                        className="my-2 ps-3"
                                        as={Col}
                                        sm="6"
                                        controlId="validationFormik06"
                                    >
                                        <Form.Label>Texto</Form.Label>
                                        <Form.Control
                                            name="json.form.authorizationMessage.value"
                                            value={values.json.form.authorizationMessage.value}
                                            onChange={handleChange}
                                            isInvalid={!!errors?.json?.form?.authorizationMessage?.value}
                                            isValid={touched?.json?.form?.authorizationMessage?.value && !errors?.json?.form?.authorizationMessage?.value
                                            }
                                        />
                                        <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">{errors?.json?.form?.authorizationMessage?.value}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group
                                        className="mt-2 ps-3"
                                        as={Col}
                                        sm="6"
                                        controlId="validationFormik06"
                                    >
                                        <Form.Label>Link externo</Form.Label>
                                        <Form.Control
                                            name="json.form.authorizationLink"
                                            value={values.json.form.authorizationLink}
                                            onChange={handleChange}
                                            isInvalid={!!errors?.json?.form?.authorizationLink}
                                            isValid={touched?.json?.form?.authorizationLink && !errors?.json?.form?.authorizationLink
                                            }
                                        />
                                        <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">{errors?.json?.form?.authorizationLink}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </>
                            ) : (
                                ""
                            )}
                        </Form.Group>
                    </Row>
                    <Form.Group as={Col} className="textHeaderAplicativo mb-3">
                        <h5 className="card-title mb-0">
                            Adicionar usuários ({addVisitantes.visitantes.length > 0 ? addVisitantes.visitantes.length : 0})
                        </h5>
                    </Form.Group>
                    <Row className="mx-0 mb-3">
                        <Form.Group as={Col} sm={12} className='mb-3'>
                            <Form.Label>
                                Lista de emails
                                <OverlayTrigger
                                    overlay={<Tooltip>Adicione os emails dos usuários separado por linha. Emails repetidos serão ignorados automaticamente.</Tooltip>}
                                >
                                    <HelpTooltip icon={faExclamationCircle} />
                                </OverlayTrigger>
                            </Form.Label>
                            <Form.Control
                                style={{ height: "150px" }}
                                type="emails"
                                as="textarea"
                                name={'emails'}
                                value={values.emails}
                                onChange={handleChange}
                                isInvalid={!!errors.emails}
                                isValid={touched.emails && !errors.emails}
                            />
                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                                {errors.emails}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Form.Group as={Col} className="textHeaderAplicativo mb-3">
                        <h5 className="card-title mb-0">
                            Importar participantes ({addVisitantes.visitantes.length > 0 ? addVisitantes.visitantes.length : 0})
                        </h5>
                    </Form.Group>
                    <Row className="mx-0">
                        <Form.Group
                            as={Col}
                            md="6"
                            className="mb-3"
                            controlId="validationFormik03"
                        >
                            <Form.Label>Selecione um arquivo Excel ou CSV <OverlayTrigger
                                overlay={<Tooltip>Os nomes definidos para as colunas precisam ser os mesmos campos do formulário. Coluna "email" obrigatório</Tooltip>}
                            >
                                <HelpTooltip icon={faExclamationCircle} />
                            </OverlayTrigger>
                            </Form.Label>
                            <div className="d-flex">
                                <div>
                                    <Form.Control
                                        type="file"
                                        accept=".xlsx,.xls,.csv"
                                        onChange={(e) => handleFileUpload(e, setAddVisitantes)}
                                        isInvalid={!!addVisitantes.error}
                                        isValid={!addVisitantes.error}
                                        ref={addVisitantesInput}
                                    />
                                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                        {addVisitantes.error}
                                    </Form.Control.Feedback>
                                </div>
                                <div>
                                    <Button
                                        variant="primary"
                                        className="float-end ms-2"
                                        onClick={() => {
                                            setAddVisitantes((prevState) => ({
                                                ...prevState,
                                                visitantes: [],
                                                formValue: '',
                                                formFilename: ''
                                            }));
                                            addVisitantesInput.current.value = ''
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faSync} />
                                    </Button>
                                </div>
                            </div>
                        </Form.Group>
                    </Row>
                    <Row className="mx-0">
                        <Col md={12} className="mb-3">
                            <Card className="mb-3">
                                <Card.Body className="overflow-auto">
                                    <ColumnSortingTable
                                        columns={addVisitantes.fields}
                                        data={addVisitantes.visitantes}
                                        title={dataTable.titulo}
                                        getCellProps={(cellinfo) => ({})}
                                    />
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <Form.Group as={Col} className="textHeaderAplicativo mb-3">
                        <h5 className="card-title mb-0">Layout</h5>
                    </Form.Group>
                    <Row className="mx-0">
                        <Form.Group
                            as={Col}
                            md={4}
                            controlId="validationFormik07"
                            className="mb-3"
                        >
                            <Form.Label>Estilo de fonte</Form.Label>
                            <Form.Select
                                name="json.fontFamily"
                                value={values.json.fontFamily}
                                onChange={(e) => {
                                    setFieldValue("json.fontFamily", e.target.value);
                                }}
                            >
                                {fonts.map((element, index) => {
                                    return (
                                        <option
                                            style={{ fontFamily: element.font }}
                                            key={"font" + index}
                                            value={element.font}
                                        >
                                            {element.font}
                                        </option>
                                    );
                                })}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group
                            as={Col}
                            md={4}
                            className="mb-3"
                            controlId="validationFormik01">
                            <Form.Label>Tamanho fonte (M) (rem)</Form.Label>
                            <Form.Control
                                type="number"
                                step={"0.01"}
                                name="json.h5Size"
                                value={values.json.h5Size}
                                onChange={handleChange}
                                isInvalid={!!errors.json?.h5Size}
                                isValid={touched.json?.h5Size && !errors.json?.h5Size}
                            />
                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                                {errors.json?.h5Size}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                            as={Col}
                            md={4}
                            className="mb-3"
                            controlId="validationFormik01">
                            <Form.Label>Tamanho fonte (P) (rem)</Form.Label>
                            <Form.Control
                                type="number"
                                name="json.pSize"
                                step={"0.01"}
                                value={values.json.pSize}
                                onChange={handleChange}
                                isInvalid={!!errors.json?.pSize}
                                isValid={touched.json?.pSize && !errors.json?.pSize}
                            />
                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                                {errors.json?.pSize}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3 mx-0">
                        <p className="text-start fw-bold mb-2">Layout</p>
                        <Form.Group
                            as={Col}
                            md="4"
                            className="mb-3"
                            controlId="validationFormik03"
                        >
                            <Form.Label>Tipo de layout</Form.Label>
                            <Form.Select
                                name="json.layout.type"
                                onChange={(e) => {
                                    setFieldValue(
                                        "json.layout.type", e.target.value == 1 ? 1 : 2
                                    )
                                }}
                                value={values.json.layout.type}
                                isInvalid={!!errors.json?.layout?.type}
                                isValid={touched.json?.layout?.type && !errors.json?.layout?.type}>
                                {layoutX.map((element, index) => {
                                    return (
                                        <option key={"layout" + index} value={index + 1}>
                                            {element}
                                        </option>
                                    );
                                })}
                            </Form.Select>
                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">{errors.json?.layout?.type}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                            as={Col}
                            md={4}
                            controlId="validationFormik01">
                            <Form.Label>Margem (rem)</Form.Label>
                            <Form.Control
                                type="number"
                                name="json.paddingBox"
                                step={"0.01"}
                                value={values.json.paddingBox}
                                onChange={handleChange}
                                isInvalid={!!errors.json?.paddingBox}
                                isValid={touched.json?.paddingBox && !errors.json?.paddingBox}
                            />
                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                                {errors.json?.paddingBox}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3 mx-0">
                        {values.json.layout.type === 1 &&
                            <Form.Group
                                as={Col}
                                md="4"
                                controlId="validationFormik03"
                            >
                                <Form.Label>Rodapé</Form.Label>
                                <Form.Select
                                    name="json.layout.footer.type"
                                    onChange={(e) => {
                                        if (e.target.value === 'noFooter')
                                            setFieldValue(
                                                "json.layout.footer.socialMedia", []
                                            );
                                        setFieldValue(
                                            "json.layout.footer.type", e.target.value
                                        );
                                    }}
                                    value={values.json.layout.footer.type}
                                    isInvalid={!!errors.json?.layout?.footer?.type}
                                    isValid={touched.json?.layout?.footer?.type && !errors.json?.layout?.footer?.type}>
                                    <option value={"noFooter"}>
                                        Sem rodapé
                                    </option>
                                    <option value={"image"}>
                                        Imagem
                                    </option>
                                    <option value={"icons"}>
                                        Ícones
                                    </option>
                                </Form.Select>
                                <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">{errors.json?.layout?.type}</Form.Control.Feedback>
                            </Form.Group>}
                    </Row>
                    {values.json.layout.type === 1 ? (
                        <FooterFormComponent dataAddForm={dataAddForm} setDataAddForm={setDataAddForm} setShowSocialMediaForm={setShowSocialMediaForm} setModalForm={setModalForm} touched={touched} errors={errors} values={values} setFieldValue={setFieldValue} handlePreviewSuccess={handlePreviewSuccess} handlePreviewError={handlePreviewError}></FooterFormComponent>
                    ) : ("")}
                    <Form.Group as={Col} className="textHeaderAplicativo mb-3">
                        <h5 className="card-title mb-0">Cores</h5>
                    </Form.Group>
                    <Row className="mx-0 mb-3">
                        <Col
                            sm={6}
                            className={(errors?.json?.background?.color || errors?.json?.baseColorText || errors?.json?.transparency || errors?.json?.primaryColor || errors?.json?.primaryColorText || errors?.json?.bsBorderColor || errors?.json?.boxShadow) ? ("mb-3 is-invalid") : ("mb-3")}
                        >
                            <Row className="mb-2">
                                <div className={`d-flex justify-content-start align-items-center ${errors?.json?.background?.color ? 'is-invalid' : ''}`}>
                                    <PopoverPicker
                                        color={props.backgroundColor}
                                        onChangeX={(e) => {
                                            setFieldValue("json.background.color", e);
                                            setProps(prevState => ({
                                                ...prevState,
                                                backgroundColor: e
                                            }));
                                        }}
                                        classes={`me-1 picker ${errors?.json?.background?.color ? 'is-invalid' : ''}`}
                                    />
                                    <Form.Label className="mb-0 ms-2">Plano de fundo</Form.Label>
                                </div>
                                <Form.Control.Feedback type="invalid">
                                    {getFirstError(
                                        errors?.json?.background?.color // Add more variables as needed
                                    )}
                                </Form.Control.Feedback>
                            </Row>
                            <Row className="mb-2">
                                <div className={`d-flex justify-content-start align-items-center ${errors?.json?.transparency ? 'is-invalid' : ''}`}>
                                    <PopoverPicker
                                        color={props.transparency}
                                        onChangeX={(e) => {
                                            setFieldValue("json.transparency", e);
                                            setProps(prevState => ({
                                                ...prevState,
                                                transparency: e
                                            }));
                                        }}
                                        classes={`me-1 picker ${errors?.json?.transparency ? 'is-invalid' : ''}`}
                                    />
                                    <Form.Label className="mb-0 ms-2">Container</Form.Label>
                                </div>
                                <Form.Control.Feedback type="invalid">
                                    {getFirstError(
                                        errors?.json?.transparency
                                    )}
                                </Form.Control.Feedback>
                            </Row>
                            <Row className="mb-2">
                                <div className={`d-flex justify-content-start align-items-center ${errors?.json?.boxShadow ? 'is-invalid' : ''}`}>
                                    <PopoverPicker
                                        color={props.boxShadow}
                                        onChangeX={(e) => {
                                            setFieldValue("json.boxShadow", e);
                                            setProps(prevState => ({
                                                ...prevState,
                                                boxShadow: e
                                            }));
                                        }}
                                        classes={`me-1 picker ${errors?.json?.boxShadow ? 'is-invalid' : ''}`}
                                    />
                                    <Form.Label className="mb-0 ms-2">Sombra do container</Form.Label>
                                </div>
                                <Form.Control.Feedback type="invalid">
                                    {getFirstError(
                                        errors?.json?.boxShadow
                                    )}
                                </Form.Control.Feedback>
                            </Row>

                            <Row className="mb-2">
                                <div className={`d-flex justify-content-start align-items-center ${errors?.json?.baseColorText ? 'is-invalid' : ''}`}>
                                    <PopoverPicker
                                        color={props.baseColorText}
                                        onChangeX={(e) => {
                                            setFieldValue("json.baseColorText", e);
                                            setProps(prevState => ({
                                                ...prevState,
                                                baseColorText: e
                                            }));
                                        }}
                                        classes={`me-1 picker ${errors?.json?.baseColorText ? 'is-invalid' : ''}`}
                                    />
                                    <Form.Label className="mb-0 ms-2">Texto primário</Form.Label>
                                </div>
                                <Form.Control.Feedback type="invalid">
                                    {getFirstError(
                                        errors?.json?.baseColorText
                                    )}
                                </Form.Control.Feedback>
                            </Row>

                            <Row className="mb-2">
                                <div className={`d-flex justify-content-start align-items-center ${errors?.json?.primaryColor ? 'is-invalid' : ''}`}>
                                    <PopoverPicker
                                        color={props.primaryColor}
                                        onChangeX={(e) => {
                                            setFieldValue("json.primaryColor", e);
                                            setProps(prevState => ({
                                                ...prevState,
                                                primaryColor: e
                                            }));
                                        }}
                                        classes={`me-1 picker ${errors?.json?.primaryColor ? 'is-invalid' : ''}`}
                                    />
                                    <Form.Label className="mb-0 ms-2">Elementos de destaque</Form.Label>
                                </div>
                                <Form.Control.Feedback type="invalid">
                                    {getFirstError(
                                        errors?.json?.primaryColor
                                    )}
                                </Form.Control.Feedback>
                            </Row>

                            <Row className="mb-2">
                                <div className={`d-flex justify-content-start align-items-center ${errors?.json?.primaryColorText ? 'is-invalid' : ''}`}>
                                    <PopoverPicker
                                        color={props.primaryColorText}
                                        onChangeX={(e) => {
                                            setFieldValue("json.primaryColorText", e);
                                            setProps(prevState => ({
                                                ...prevState,
                                                primaryColorText: e
                                            }));
                                        }}
                                        classes={`me-1 picker ${errors?.json?.primaryColorText ? 'is-invalid' : ''}`}
                                    />
                                    <Form.Label className="mb-0 ms-2">Texto secundário</Form.Label>
                                </div>
                                <Form.Control.Feedback type="invalid">
                                    {getFirstError(
                                        errors?.json?.primaryColorText
                                    )}
                                </Form.Control.Feedback>
                            </Row>

                            <Row className="mb-2">
                                <div className={`d-flex justify-content-start align-items-center ${errors?.json?.bsBorderColor ? 'is-invalid' : ''}`}>
                                    <PopoverPicker
                                        color={props.bsBorderColor}
                                        onChangeX={(e) => {
                                            setFieldValue("json.bsBorderColor", e);
                                            setProps(prevState => ({
                                                ...prevState,
                                                bsBorderColor: e
                                            }));
                                        }}
                                        classes={`me-1 picker ${errors?.json?.bsBorderColor ? 'is-invalid' : ''}`}
                                    />
                                    <Form.Label className="mb-0 ms-2">Bordas</Form.Label>
                                </div>
                                <Form.Control.Feedback type="invalid">
                                    {getFirstError(
                                        errors?.json?.bsBorderColor
                                    )}
                                </Form.Control.Feedback>
                            </Row>
                            <Row>
                                <Form.Group
                                    controlId="validationFormik03"
                                >
                                    <Form.Label>Background</Form.Label>
                                    <Form.Select
                                        name="json.background.type"
                                        onChange={handleChange}
                                        value={values.json.background.type}
                                        isInvalid={!!errors.json?.background?.type}
                                        isValid={touched.json?.background?.type && !errors.json?.background?.type}>
                                        <option value={"image"}>
                                            Imagem
                                        </option>
                                        <option value={"video"}>
                                            Vídeo
                                        </option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">{errors.json?.background?.type}</Form.Control.Feedback>
                                </Form.Group>
                            </Row>

                            {values.json.background.type !== 'color' && <Row className="mb-3">
                                <BackgroundFormComponent touched={touched} errors={errors} values={values} setFieldValue={setFieldValue} handlePreviewSuccess={handlePreviewSuccess} handlePreviewError={handlePreviewError}></BackgroundFormComponent>
                            </Row>}
                        </Col>
                        <Col
                            className={(errors?.json?.background?.color || errors?.json?.baseColorText || errors?.json?.transparency || errors?.json?.primaryColor || errors?.json?.primaryColorText || errors?.json?.bsBorderColor || errors?.json?.boxShadow) ? ("is-invalid") : ("mb-3")}
                        >
                            <Form.Control
                                type="hidden"
                                name="json.background.color"
                                value={values.json.background.color}
                                isInvalid={!!errors?.json?.background?.color}
                                isValid={touched?.json?.background?.color && !errors?.json?.background?.color}
                            />
                            <Form.Control
                                type="hidden"
                                name="json.baseColorText"
                                value={values.json.baseColorText}
                                isInvalid={!!errors?.json?.baseColorText}
                                isValid={touched?.json?.baseColorText && !errors?.json?.baseColorText}
                            />
                            <Form.Control
                                type="hidden"
                                name="json.transparency"
                                value={values.json.transparency}
                                isInvalid={!!errors?.json?.transparency}
                                isValid={touched?.json?.transparency && !errors?.json?.transparency}
                            />
                            <Form.Control
                                type="hidden"
                                name="json.primaryColor"
                                value={values.json.primaryColor}
                                isInvalid={!!errors?.json?.primaryColor}
                                isValid={touched?.json?.primaryColor && !errors?.json?.primaryColor
                                }
                            />
                            <Form.Control
                                type="hidden"
                                name="json.primaryColorText"
                                value={values.json.primaryColorText}
                                isInvalid={!!errors?.json?.primaryColorText}
                                isValid={touched?.json?.primaryColorText && !errors?.json?.primaryColorText}
                            />
                            <Form.Control
                                type="hidden"
                                name="json.boxShadow"
                                value={values.json.boxShadow}
                                isInvalid={!!errors?.json?.boxShadow}
                                isValid={touched?.json?.boxShadow && !errors?.json?.boxShadow}
                            />
                            <Form.Control
                                type="hidden"
                                name="json.bsBorderColor"
                                value={values.json.bsBorderColor}
                                isInvalid={!!errors?.json?.bsBorderColor}
                                isValid={touched?.json?.bsBorderColor && !errors?.json?.bsBorderColor}
                            />
                            <div
                                className="colorBlock"
                                style={
                                    values.json.background.type === "image"
                                        ? {
                                            backgroundImage: `url("${domainConfig.imageServer +
                                                "/evento/" +
                                                values.evento_id +
                                                "/" +
                                                values.json.background.url
                                                }")`,
                                        }
                                        : {
                                            backgroundColor: `${props.backgroundColor}`,
                                        }
                                }
                            >
                                <div className="colorBlockNav" style={{ "backgroundColor": `${props.transparency}` }}></div>
                                {values.json.background.type === "video" &&
                                    <video
                                        className="videoBackgroundEventForm"
                                        controls={false}
                                        autoPlay={true}
                                        sound={false}
                                        src={`${domainConfig.imageServer +
                                            "/evento/" +
                                            values.evento_id +
                                            "/" +
                                            values.json.background.url
                                            }`}>
                                    </video>}
                                <span
                                    style={{
                                        color: `${props.baseColorText}`,
                                        borderRadius: "10px",
                                        backgroundColor: `${props.transparency}`,
                                        boxShadow: `0 .3rem 0.5rem ${props.boxShadow}`,
                                    }}
                                    className="text-center h6 mb-0 p-3"
                                >
                                    Texto primário
                                    <div className="p-3"
                                        style={{ borderBottom: `2px solid ${props.bsBorderColor}` }}>
                                        <div className="buttons"
                                            style={{
                                                color: `${props.primaryColorText}`,
                                                backgroundColor: `${props.primaryColor}`
                                            }}
                                        >
                                            Texto secundário</div>
                                    </div>
                                </span>
                            </div>
                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                                {(errors?.json?.background?.color) || errors?.json?.baseColorText || errors?.json?.transparency || errors?.json?.primaryColor || errors?.json?.primaryColorText || errors?.json?.bsBorderColor || errors?.json?.boxShadow}
                            </Form.Control.Feedback>
                        </Col>
                    </Row>

                    <Form.Group as={Col} className="textHeaderAplicativo mb-3">
                        <h5 className="card-title mb-0">
                            Media <span className="small">(Tamanho máximo: 10mb)</span>
                        </h5>
                    </Form.Group>
                    <Row className="mx-0 mb-3">
                        <Form.Group
                            as={Col}
                            md="6">
                            <Card className="mb-3">
                                <Card.Body>
                                    <div className="text-center mb-3">
                                        <div className="d-flex justify-content-center mb-3">
                                            <Form.Label>Logomarca (M)</Form.Label>
                                        </div>
                                    </div>
                                    <Form.Control
                                        type="hidden"
                                        name="json.logo"
                                        value={values.json.logo}
                                        onChange={handleChange}
                                        isInvalid={!!errors?.json?.logo}
                                        isValid={touched?.json?.logo && !errors?.json?.logo}
                                    />
                                    <div className="imagesUpload">
                                        <img
                                            className={`h-100 img-thumbnail ${!!errors?.json?.logo ? "is-invalid" : ""}`}
                                            src={
                                                values.json.logo !== "" && values.json.logo !== null
                                                    ? domainConfig.imageServer +
                                                    "/evento/" +
                                                    values.evento_id +
                                                    "/" +
                                                    values.json.logo
                                                    : domainConfig.imageServer + "/empty-background.png"
                                            }
                                            alt="Imagem footer aplicativo"
                                        />
                                        <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                            {errors?.json?.logo}
                                        </Form.Control.Feedback>
                                    </div>
                                    <DropzoneComponent
                                        className="mt-3 dropzoneComp"
                                        type="image"
                                        handlePreviewSuccess={handlePreviewSuccess}
                                        handlePreviewError={handlePreviewError}
                                        setFieldValue={setFieldValue}
                                        folder="evento"
                                        id={values.evento_id}
                                        field="json.logo"
                                    />
                                    <Form.Group
                                        as={Col}
                                        className="mt-3"
                                        controlId="validationFormik01">
                                        <Form.Label>Largura (px)</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="json.logoWidth"
                                            value={values.json.logoWidth}
                                            onChange={handleChange}
                                            isInvalid={!!errors.json?.logoWidth}
                                            isValid={touched.json?.logoWidth && !errors.json?.logoWidth}
                                        />
                                        <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.json?.logoWidth}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Card.Body>
                            </Card>
                        </Form.Group>
                    </Row>
                    <Form.Group as={Col} className="textHeaderAplicativo mb-3">
                        <h5 className="card-title mb-0">
                            Configurações de página
                        </h5>
                    </Form.Group>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="12">
                            <Tab.Container variant="primary" id="left-tabs-example" defaultActiveKey="home">
                                <Nav variant="tabs" className="mb-4">
                                    <Nav.Item>
                                        <Nav.Link eventKey="home">Página inicial</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="coleta-de-lead">Coleta de lead</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="agenda" disabled>Agenda</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="expositores" disabled>Expositores</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="participantes" disabled>Participantes</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="palestrantes" disabled>Palestrantes</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="gamificacao" disabled>Gamificação</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="pontos-de-interesse" disabled>Pontos de interesse</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="galeria" disabled>Galeria</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <Tab.Content>
                                    <Tab.Pane eventKey="home">
                                        <Row className="mb-3 mx-0">
                                            <Form.Group className="mb-3">
                                                <Form.Check
                                                    checked={values.json.home.status ? true : false}
                                                    type="checkbox"
                                                    name="json.home.status"
                                                    label='Adicionar contéudo na página inicial'
                                                    onChange={(e) => {
                                                        setFieldValue(
                                                            "json.home.status",
                                                            values.json.home.status ? false : true
                                                        );
                                                    }}
                                                />
                                            </Form.Group>
                                            {values.json.home.status && <><Form.Group
                                                className="mb-3"
                                                as={Col}
                                                sm="12"
                                                controlId="validationFormik02"
                                            >
                                                <Form.Label>Título</Form.Label>
                                                <Form.Control
                                                    name="json.home.title"
                                                    type="text"
                                                    value={values.json.home.title}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.json?.home.title}
                                                    isValid={touched.json?.home?.title && !errors.json?.home?.title}
                                                />
                                                <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.json?.home?.title}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                                <Form.Group
                                                    className="mb-3"
                                                    as={Col}
                                                    sm="12"
                                                    controlId="validationFormik02"
                                                >
                                                    <Form.Label>Texto</Form.Label>
                                                    <Form.Control
                                                        name="json.home.content"
                                                        as="textarea"
                                                        value={values.json.home.content}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.json?.home?.content}
                                                        isValid={touched.json?.home?.content && !errors.json?.shome?.content}
                                                    />
                                                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.json?.home?.content}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </>}
                                        </Row>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="coleta-de-lead">
                                        <Row className="mb-3 mx-0">
                                            <Form.Group>
                                                <Form.Check
                                                    className="w-auto"
                                                    checked={values.json.leadCollection.showNotRegistered ? true : false}
                                                    type="checkbox"
                                                    name="json.leadCollection.showNotRegistered"
                                                    label="Mostrar participantes não registrados para o usuário no modo de coleta"
                                                    onChange={(e) => {
                                                        setFieldValue(
                                                            "json.leadCollection.showNotRegistered",
                                                            values.json.leadCollection.showNotRegistered ? false : true
                                                        );
                                                    }}
                                                />
                                            </Form.Group>
                                        </Row>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </Form.Group>
                    </Row>
                    <hr className="mt-4"></hr>
                    <Form.Group as={Col}>
                        <Button
                            type="submit"
                            className="float-end"
                            disabled={isSubmitting}
                        >
                            Salvar
                        </Button>
                    </Form.Group>
                </Form>
            )
            }
        </Formik >
    )
}

export const FormCompositionCard = ({ dataAddForm, setDataAddForm, setShowModalForm, setModalForm }) => {
    const [draggedIndex, setDraggedIndex] = useState(null);

    const handleDragStart = (index) => {
        setDraggedIndex(index);
    };

    const handleDragOver = (index) => {
        if (draggedIndex === index) return;

        const updatedForm = [...dataAddForm.fields];
        const [draggedItem] = updatedForm.splice(draggedIndex, 1);
        updatedForm.splice(index, 0, draggedItem);

        setDraggedIndex(index);
        setDataAddForm((prevDataAddForm) => {
            return {
                ...prevDataAddForm,
                fields: updatedForm
            }
        });
    };

    const handleDragEnd = () => {
        setDraggedIndex(null);
    };

    return (
        <Row className="mb-3 mx-0">
            <Form.Label>Composição do formulário</Form.Label>
            <Row className="mx-0">
                <Card className="mb-0 formCard col-md-12 px-0">
                    <Card.Body>
                        <Col className="text-center mb-2">
                            {dataAddForm.fields.length > 0 && <small className="text-center opacity-75">(Clique e arraste para ordenar)</small>}
                        </Col>
                        {dataAddForm.fields.length > 0 ? (
                            <Row>
                                {dataAddForm.fields.map((valueX, index) => (
                                    <Col
                                        key={'dataAddForm' + index}
                                        className="mb-3"
                                        md={3}
                                        sm={12}>
                                        <Button
                                            style={{ height: "50px" }}
                                            key={valueX.inputID}
                                            variant="secondary"
                                            className={`w-100 b-15`}
                                            draggable
                                            onDragStart={() => handleDragStart(index)}
                                            onDragOver={(e) => {
                                                e.preventDefault();
                                                handleDragOver(index);
                                            }}
                                            onClick={() => {
                                                setModalForm(valueX);
                                                setShowModalForm(true);
                                            }}
                                            onDragEnd={handleDragEnd}
                                        >
                                            {valueX.label}
                                        </Button>
                                    </Col>
                                ))
                                }
                            </Row>
                        ) : (
                            <>
                                <p className="text-center opacity-75">Nenhum campo encontrado</p>
                            </>

                        )}
                    </Card.Body>
                    <Col className="mb-3 text-center">
                        <Button
                            variant="primary"
                            className="btn-sm"
                            onClick={() => { setShowModalForm(true); setModalForm(false) }}
                        >
                            Adicionar campo
                        </Button>
                    </Col>
                </Card>
            </Row>
        </Row>
    )
}

export const FooterFormComponent = ({ dataAddForm, setDataAddForm, setShowSocialMediaForm, setModalForm, touched, errors, values, setFieldValue, handlePreviewSuccess, handlePreviewError }) => {
    const [draggedIndex, setDraggedIndex] = useState(null);

    const handleDragStart = (index) => {
        setDraggedIndex(index);
    };

    const handleDragOver = (index) => {
        if (draggedIndex === index) return;

        const updatedForm = [...dataAddForm.socialMedia];
        const [draggedItem] = updatedForm.splice(draggedIndex, 1);
        updatedForm.splice(index, 0, draggedItem);

        setDraggedIndex(index);
        setDataAddForm((prevDataAddForm) => {
            return {
                ...prevDataAddForm,
                socialMedia: updatedForm
            }
        });
    };

    const handleDragEnd = () => {
        setDraggedIndex(null);
    };

    switch (values.json.layout.footer.type) {
        case 'image':
            return (
                <Row className="mx-0 mb-3">
                    <Form.Group
                        as={Col}
                        md="6">
                        <Card className="mb-0">
                            <Card.Body>
                                <Form.Control
                                    type="hidden"
                                    name="json.layout.footer.url"
                                    value={values.json.layout.footer.url}
                                    onChange={(e) => {
                                        setFieldValue("json.layout.footer.url", e.target.value);
                                    }}
                                    isInvalid={!!errors.json?.layout?.footer?.url}
                                    isValid={touched.json?.layout?.footer?.width && !errors.json?.layout?.footer?.url}
                                />
                                <div className="imagesUpload">
                                    <img
                                        className={`h-100 img-thumbnail ${!!errors.json?.layout?.footer?.url ? "is-invalid" : ""}`}
                                        src={
                                            values.json.layout.footer.url !== "" && values.json.layout.footer.url !== null
                                                ? domainConfig.imageServer +
                                                "/evento/" +
                                                values.evento_id +
                                                "/" +
                                                values.json.layout.footer.url
                                                : domainConfig.imageServer + "/empty-background.png"
                                        }
                                        alt="Imagem footer aplicativo"
                                    />
                                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.json?.layout?.footer?.url}
                                    </Form.Control.Feedback>
                                </div>
                                <DropzoneComponent
                                    className="mt-3 dropzoneComp"
                                    type="image"
                                    handlePreviewSuccess={handlePreviewSuccess}
                                    handlePreviewError={handlePreviewError}
                                    setFieldValue={setFieldValue}
                                    folder="evento"
                                    id={values.evento_id}
                                    field="json.layout.footer.url"
                                />
                                <Form.Group
                                    as={Col}
                                    className="mt-3"
                                    controlId="validationFormik01">
                                    <Form.Label>Largura (px)</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="json.layout.footer.width"
                                        value={values.json.layout.footer.width}
                                        onChange={(e) => {
                                            setFieldValue("json.layout.footer.width", e.target.value);
                                        }}
                                        isInvalid={!!errors.json?.layout?.footer?.width}
                                        isValid={touched.json?.layout?.footer?.width && !errors.json?.layout?.footer?.width}
                                    />
                                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.json?.layout?.footer?.width}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Card.Body>
                        </Card>
                    </Form.Group>
                </Row>
            )
        case 'icons':
            return (
                <Row className="mx-0 mb-3">
                    <Row className="mx-0 mb-3">
                        <Card className="mb-0 formCard col-md-12 px-0">
                            <Card.Body>
                                <Col className="text-center mb-2">
                                    {dataAddForm.socialMedia.length > 0 && <small className="text-center opacity-75">(Clique e arraste para ordenar)</small>}
                                </Col>
                                {dataAddForm.socialMedia.length > 0 ? (
                                    <Row>
                                        {dataAddForm.socialMedia.map((valueX, index) => (
                                            <Col
                                                key={'dataAddForm' + index}
                                                className="mb-3"
                                                md={3}
                                                sm={12}>
                                                <Button
                                                    style={{ height: "50px" }}
                                                    variant="secondary"
                                                    className={`w-100 b-15`}
                                                    draggable
                                                    onDragStart={() => handleDragStart(index)}
                                                    onDragOver={(e) => {
                                                        e.preventDefault();
                                                        handleDragOver(index);
                                                    }}
                                                    onClick={() => {
                                                        setModalForm(valueX);
                                                        setShowSocialMediaForm(true);
                                                    }}
                                                    onDragEnd={handleDragEnd}
                                                >
                                                    {valueX.name}
                                                </Button>
                                            </Col>
                                        ))
                                        }
                                    </Row>
                                ) : (
                                    <>
                                        <p className="text-center opacity-75">Nenhum ícone encontrado</p>
                                    </>

                                )}
                            </Card.Body>
                            <Col className="mb-3 text-center">
                                <Button
                                    variant="primary"
                                    className="btn-sm"
                                    onClick={() => {
                                        setShowSocialMediaForm(true);
                                        setModalForm(false);
                                    }}
                                >
                                    Adicionar ícone
                                </Button>
                            </Col>
                        </Card>
                    </Row>
                </Row>
            )
        default:
            return ("")
    }
}

export const BackgroundFormComponent = ({ touched, errors, values, setFieldValue, handlePreviewSuccess, handlePreviewError }) => {
    switch (values.json.background.type) {
        case 'image':
            return (
                <Form.Group
                    as={Col} className="mt-3">
                    <Card className="mb-0">
                        <Card.Body>
                            <Form.Control
                                type="hidden"
                                name="json.background.url"
                                value={values.json.background.url}
                                onChange={(e) => {
                                    setFieldValue("json.background.url", e.target.value);
                                }}
                                isInvalid={!!errors?.json?.background?.url}
                                isValid={touched?.json?.background?.url && !errors?.json?.background?.url}
                            />
                            <div className="imagesUpload">
                                <img
                                    className={`h-100 img-thumbnail ${!!errors?.json?.background?.url ? "is-invalid" : ""}`}
                                    src={
                                        values.json.background.url !== "" && values.json.background.url !== null
                                            ? domainConfig.imageServer +
                                            "/evento/" +
                                            values.evento_id +
                                            "/" +
                                            values.json.background.url
                                            : domainConfig.imageServer + "/empty-background.png"
                                    }
                                    alt="Imagem footer aplicativo"
                                />
                                <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.json?.background?.url}
                                </Form.Control.Feedback>
                            </div>
                            <DropzoneComponent
                                className="mt-3 dropzoneComp"
                                type="image"
                                handlePreviewSuccess={handlePreviewSuccess}
                                handlePreviewError={handlePreviewError}
                                setFieldValue={setFieldValue}
                                folder="evento"
                                id={values.evento_id}
                                field="json.background.url"
                            />
                        </Card.Body>
                    </Card>
                </Form.Group>
            )
        case 'video':
            return (
                <Form.Group
                    as={Col} className="mt-3">
                    <Card className="mb-0">
                        <Card.Body>
                            <Form.Control
                                type="hidden"
                                name="json.background.url"
                                value={values.json.background.url}
                                onChange={(e) => {
                                    setFieldValue("json.background.url", e.target.value);
                                }}
                                isInvalid={!!errors?.json?.background?.url}
                                isValid={touched?.json?.background?.url && !errors?.json?.background?.url}
                            />
                            <div className="imagesUpload">
                                <video
                                    className={`h-100 img-thumbnail ${!!errors?.json?.background?.url ? "is-invalid" : ""}`}
                                    src={
                                        values.json.background.url !== "" && values.json.background.url !== null
                                            ? domainConfig.imageServer +
                                            "/evento/" +
                                            values.evento_id +
                                            "/" +
                                            values.json.background.url
                                            : domainConfig.imageServer + "/empty-background.png"
                                    }
                                    controls={true}
                                />
                                <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.json?.background?.url}
                                </Form.Control.Feedback>
                            </div>
                            <DropzoneComponent
                                className="mt-3 dropzoneComp"
                                type="video"
                                handlePreviewSuccess={handlePreviewSuccess}
                                handlePreviewError={handlePreviewError}
                                setFieldValue={setFieldValue}
                                folder="evento"
                                id={values.evento_id}
                                field="json.background.url"
                            />
                        </Card.Body>
                    </Card>
                </Form.Group>
            )
        default:
            return ("")
    }
}

export function removeCustomizationFromEvent(e, setApplications, customizacao_id) {
    e.preventDefault();
    e.stopPropagation();
    setApplications((prevData) => {
        const indexToRemove = prevData.findIndex(field => field.customizacao_id === customizacao_id);
        const newData = [...prevData];
        newData.splice(indexToRemove, 1);
        return newData
    });
}

export function updateCustomizationVersion(e, setApplications, props) {
    e.preventDefault();
    e.stopPropagation();
    setApplications((prevData) => {
        const currentIndex = prevData.findIndex(data => data.customizacao_id === props.customizacao_id);
        if (currentIndex !== -1) {
            const newData = [
                ...prevData.slice(0, currentIndex),
                { ...prevData[currentIndex], version: 'toUpdate' },
                ...prevData.slice(currentIndex + 1)
            ];
            return newData;
        }
        return prevData;
    });
}

// export function removeCustomizationFromEvent(setApplications, customizacao_id) {
//     setApplications((prevData) => {
//         const indexToRemove = prevData.findIndex(field => field.inputID === customizacao_id);
//         const newData = [...prevData];
//         newData.splice(indexToRemove, 1);
//         return newData
//     });
// }

// export function updateCustomizationVersion(setApplications, props) {
//     setApplications((prevData) => {
//         const currentIndex = prevData.findIndex(data => data.customizacao_id === props.customizacao_id);
//         if (currentIndex !== -1) {
//             const newData = [
//                 ...prevData.slice(0, currentIndex),
//                 { ...prevData[currentIndex], version: props.currentVersion },
//                 ...prevData.slice(currentIndex + 1)
//             ];
//             return newData;
//         }
//         return prevData;
//     });
// }
